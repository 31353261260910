;(function() {
/* @ngInject */
function ZmenaSroKrok1Controller(ZmenaSroKrok1Service, loading, _, $timeout, $filter, $window, BusinessSubjects,
                                 $scope, ModalFinishLater, ModalCompanyName, ModalPlaceOfBusiness, ModalPersonRemove,
                                 ModalShareTransfer, ModalExecutor, ModalFounderChange, ModalBasicCapital,
                                 ModalFounderWithCapital, ModalExecutionType, ModalExecutorOtherInfo,
                                 ModalConfirmDelete, ModalRedirect, ModalEndUser, ModalInfo, ModalStreetNumber,
                                 ModalBdayIdnumber, Helpers, ModalTransferCompany) {
  let vm = this;
  let server = ZmenaSroKrok1Service;

  vm.subjects = BusinessSubjects;

  vm.maxStep = 1;
  vm.valid = false;
  vm.cart = { };
  vm.order = {
    companyName: {},
    shareTransfer: [],
    date: {},
    domain: {
      name: "",
      isFree: false,
      active: false
    }
  };
  vm.suffixes = [" s. r. o.", ", s. r. o.", ", spol. s r. o."];
  vm.executionTypes = ["onTheirOwn", "together", "other"];
  vm.order.companyName.suffix = vm.suffixes[0];
  vm.ico = {};
  vm.cities = [];
  vm.totalPrice = 0;
  vm.changes = {
    executor: false,
    founder: false
  };
  vm.landingCollapsed = {};
  vm.showInstructionEndUser = false;
  vm.showInstructionVideo = true;
  vm.showEndUser = false;
  vm.originalNumberOfRpvsPersons = 0;

  vm.angular = angular;
  vm.personsFromOldOrders = [];

  function detectUrl() {
    let res = $window.location.pathname.split("/");
    if (res[1] === "zmeny-vo-firme")
      vm.order.isSelf = false;
    else vm.order.isSelf = true;
  }

  detectUrl();

  vm.onIcoChange = onIcoChange;
  vm.onCompanySuffixChange = onCompanySuffixChange;
  vm.onCompanyNameChange = onCompanyNameChange;
  vm.onFirmarenAddressClick = onFirmarenAddressClick;
  vm.setFirmarenPlaceOfBusiness = setFirmarenPlaceOfBusiness;
  vm.onSaveAndContinueClick = onSaveAndContinueClick;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.openSelect = openSelect;
  vm.scrollTo = scrollTo;
  vm.onCityChange = onCityChange;
  vm.onChangeCompanyNameClick = onChangeCompanyNameClick;
  vm.onChangePlaceOfBusinessClick = onChangePlaceOfBusinessClick;
  vm.clearData = clearData;
  vm.blurChangeBtn = blurChangeBtn;
  vm.newAddressNumbers = newAddressNumbers;
  vm.getPercent = getPercent;
  vm.onRemovePersonClick = onRemovePersonClick;
  vm.onAddExecutiveManagerClick = onAddExecutiveManagerClick;
  vm.getFullName = Helpers.getFullName;
  vm.onEditExecutiveManagerClick = onEditExecutiveManagerClick;
  vm.formatDate = Helpers.formatDate;
  vm.onEditFounderClick = onEditFounderClick;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.onElementBlur = Helpers.onElementBlur;
  vm.onEditShareTransfer = onEditShareTransfer;
  vm.onTransferCompany = onTransferCompany;
  vm.onEditBasicCapitalClick = onEditBasicCapitalClick;
  vm.onAddFounderWithCapitalClick = onAddFounderWithCapitalClick;
  vm.onAddFounderWithShareTransfer = onAddFounderWithShareTransfer;
  vm.onEditExecutionTypeClick = onEditExecutionTypeClick;
  vm.onAddExecutorOtherInfoClick = onAddExecutorOtherInfoClick;
  vm.confirmClearData = confirmClearData;
  vm.onAddOrEditEndUserClick = onAddOrEditEndUserClick;
  vm.showPersonAddress = showPersonAddress;
  vm.showPersonCityZipCode = showPersonCityZipCode;
  vm.checkPrice = checkPrice;
  vm.selfChanges = selfChanges;
  vm.routeToChangeCompany = routeToChangeCompany;
  vm.onlyPersonalChange = onlyPersonalChange;
  vm.companyTransfer = companyTransfer;

  vm.isCollapsed = {
    executors: true,
    companyName: true,
    place: true,
    subjects: true,
    founders: true,
    capital: true,
    other: true,
    firmarenAddress: true,
    clerks: true,
    endUser: true,
    executionType: true,
    companyTransfer: true
  };
  vm.requiredMessage = false;
  vm.nextStep = 2;
  vm.finstatSpinner = false;
  vm.disableElement = true;
  vm.placeOfBusinessKinds = ["area", "flat", "otherBuilding", "house", "garage"];
  vm.hidePrice = true;

  vm.newAddress = {};
  vm.showStreetNumberModals = true;

  window.showSpinner(10, "change_company_run_placeholder");
  server.getMaxStep().then( (ms) =>{ vm.maxStep = ms;  window.hideSpinnerWhenAllResolved(); });
  server.getFirmarenPlacesOfBusiness().then(onFirmarenPlacesOfBusinessLoad);
  server.getSelectedSubjects().then(onSelectedSubjectsLoad);
  server.getSubjects().then( (subjects) => { BusinessSubjects.init(subjects);  window.hideSpinnerWhenAllResolved(); });
  server.getOrder().then(onOrderLoad);
  server.getCart().then(onCartLoad);
  server.getPlaceOfBusiness().then(onPlaceOfBusinessLoad);
  server.getCities().then(onCitiesLoad);
  server.getCountries().then( (cs) => { vm.countries = cs; window.hideSpinnerWhenAllResolved() });
  server.getCompaniesTypes().then( (ct) => { vm.companiesTypes = ct; window.hideSpinnerWhenAllResolved() });
  server.getCurrencies().then(data => {vm.currencies = data});

  $scope.$on('set-string-content', function (event, args) {
    routeToChangeCompany();
  });

  $scope.$on('show-cart-price', function (event, args) {
    vm.hidePrice = false;
  });

  $scope.$watch('vm.subjects', function () {
    if(vm.order.ico)
      checkPrice();
  }, true);

  function onlyPersonalChange() {
    let foundersWithShareTransfer = $filter('filter')(vm.order.persons, function (person) {
      return person.founder && (person.change.add || person.change.assign || person.change.transferor)
    });
    return vm.changes.founder && foundersWithShareTransfer.length === 0;
  }

  function routeToChangeCompany() {
    let order = {
      data : angular.copy(vm.order),
      placeOfBusiness: vm.placeOfBusiness
    };

    if(order.data)
      order.data.isSelf = !vm.order.isSelf;

    server.setStringContent(JSON.stringify(order)).then(({success, data}) => {
      if (success) {
        server.setSelfBoolean(order.data.isSelf.toString()).then(() => {
          if(vm.order.isSelf)
            $window.location = "/zmeny-vo-firme/krok1";
          else $window.location = "/urob-si-sam-zmeny-vo-firme/krok1";
        });
      } else {
        // todo validation
      }
    });
  }

  function selfChanges() {
    let foundersWithShareTransfer = $filter('filter')(vm.order.persons, function (person) {
      return person.founder && (person.change.add || person.change.assign || person.change.transferor)
    });
    let addedAndRemovedExecutors = $filter('filter')(vm.order.persons, function (person) {
      return person.executor && (person.change.add || person.change.remove)
    });
    let allRemovedSubjects = $filter('filter')(vm.order.removeSubjects, function (subject) {
      return subject.remove;
    });
    return !(foundersWithShareTransfer.length !== 0 ||
        (vm.order.executionType && (vm.executionType !== vm.order.executionType || (vm.executionType === 'other' && vm.executionTypeOther !== vm.order.executionTypeOther))) ||
        vm.order.oldBasicCapital !== vm.order.basicCapital ||
        allRemovedSubjects.length > 0 ||
        addedAndRemovedExecutors.length > 0 ||
        vm.subjects.selected.length > 0 ||
        vm.order.companyName.name ||
        vm.newAddress.address ||
        vm.changes.clerk ||
        vm.changes.endUser)
  }

  $scope.$on('change_company_run_placeholder', function(event, args) {
    var content = $filter("translate")("sro.zmena.krok1.icoPlaceholder");
    vm.icoPlaceholder = "";

    var i=0;

    var timer = setInterval(function(){
      if(i<content.length)
        vm.icoPlaceholder += content[i];
      else clearInterval(timer);
      i++;
      $scope.$apply();
    }, 200);
  });

  function checkPrice() {
    var changeCompanyType = "base";
    if(vm.order.isSelf)
      changeCompanyType = "self";
    else if((vm.changes.endUser || vm.scrollToEndUser)
        && !vm.order.companyName.name
        && !vm.newAddress.address
        && !vm.changes.executor
        && !vm.changes.founder
        && vm.subjects.selected.length === 0
        && vm.order.oldBasicCapital === vm.order.basicCapital
        && !vm.changes.clerk
        && !checkRemoveSubjects()
        && !(vm.order.executionType && (vm.executionType !== vm.order.executionType || (vm.executionType === 'other' && vm.executionTypeOther !== vm.order.executionTypeOther))))
      changeCompanyType = "endUser";
    else if((vm.changes.founder || vm.scrollToFounders)
        && !vm.order.companyName.name
        && !vm.newAddress.address
        && !vm.changes.executor
        && !vm.changes.endUser
        && vm.subjects.selected.length === 0
        && vm.order.shareTransfer.length === 0
        && vm.order.oldBasicCapital === vm.order.basicCapital
        && !vm.changes.clerk
        && !checkRemoveSubjects()
        && !(vm.order.executionType && (vm.executionType !== vm.order.executionType || (vm.executionType === 'other' && vm.executionTypeOther !== vm.order.executionTypeOther))))
      changeCompanyType = "changeFounders";
    else {
      changeCompanyType = "base";
    }
    server.getBasePrice({value: changeCompanyType}).then(server.getCart().then(onCartLoad));
  }

  function getPercent(item) {
    return parseFloat(item)/parseFloat(vm.order.basicCapital)*100;
  }

  function showPersonAddress(person) {
    return person.newData && person.newData.address && (person.address.street !== person.newData.address.street || person.address.streetNo !== person.newData.address.streetNo || person.address.streetNoPrefix !== person.newData.address.streetNoPrefix);
  }

  function showPersonCityZipCode(person) {
    return person.newData && person.newData.address && (person.address.city !== person.newData.address.city || person.address.zipCode !== person.newData.address.zipCode);
  }

  function newAddressNumbers() {
    if(vm.newAddress.address) {
      if (vm.placeOfBusiness && vm.placeOfBusiness.type === "firmaren") {
        return vm.newAddress.address.streetNo2 ? vm.newAddress.address.streetNo2 + "/" + vm.newAddress.address.streetNo : vm.newAddress.address.streetNo;
      } else {
        return vm.newAddress.address.streetNoPrefix ? vm.newAddress.address.streetNoPrefix + "/" + vm.newAddress.address.streetNo : vm.newAddress.address.streetNo;
      }
    }
  }

  function blurChangeBtn() {
    let elements = $window.document.getElementsByClassName("btn_changes");
    angular.forEach(elements, function(value, _) {
      value.blur();
    });
  }

  function clearData(item) {
      if (item === "name")
        vm.order.companyName = {
          suffix: vm.suffixes[0]
        };
      if (item === "place") {
        vm.placeOfBusiness = {
          type: "own",
          kind: $filter("translate")('placeOfBusinessKind.' + vm.placeOfBusinessKinds[0])
        };
        vm.firmarenAddress = vm.firmarenPlacesOfBusiness[0];
        vm.newAddress = {};
        server.resetPlaceOfBusiness();
      }

      if (item === "executors" && vm.order.persons) {
        let index = vm.order.persons.length - 1;
        while (index >= 0) {
          if (vm.order.persons[index].executor && vm.order.persons[index].newData)
            delete vm.order.persons[index].newData;
          if (vm.order.persons[index].executor && vm.order.persons[index].change.add)
            vm.order.persons.splice(index, 1);
          else if (vm.order.persons[index].executor)
            vm.order.persons[index].change = {
              add: false,
              edit: false,
              remove: false,
              removeClerk: vm.order.persons[index].change.removeClerk,
              transferor: vm.order.persons[index].change.transferor,
              assign: vm.order.persons[index].change.assign
            };
          index -= 1;
        }
        clearDate();
        vm.changes.executor = false;
      }
      if (item === "founders" && vm.order.persons) {
        let index = vm.order.persons.length - 1;
        while (index >= 0) {
          if (vm.order.persons[index].founder && vm.order.persons[index].newData)
            delete vm.order.persons[index].newData;
          if (vm.order.persons[index].founder && vm.order.persons[index].oldDepositAmount) {
            vm.order.persons[index].depositAmount = vm.order.persons[index].oldDepositAmount;
            delete vm.order.persons[index].oldDepositAmount;
          }
          if (vm.order.persons[index].founder && vm.order.persons[index].change.add)
            vm.order.persons.splice(index, 1);
          else if (vm.order.persons[index].founder)
            vm.order.persons[index].change = {
              add: false,
              edit: false,
              remove: vm.order.persons[index].change.remove,
              removeClerk: vm.order.persons[index].change.removeClerk,
              transferor: false,
              assign: false
            };
          index -= 1;
        }
        clearDate();
        vm.order.shareTransfer = [];
        vm.changes.founder = false;
      }
      if (item === "clerks" && vm.order.persons) {
        let index = vm.order.persons.length - 1;
        while (index >= 0) {
          if (vm.order.persons[index].clerk && vm.order.persons[index].newData)
            delete vm.order.persons[index].newData;
          if (vm.order.persons[index].clerk && vm.order.persons[index].change.add)
            vm.order.persons.splice(index, 1);
          else if (vm.order.persons[index].clerk)
            vm.order.persons[index].change = {
              add: false,
              edit: false,
              remove: vm.order.persons[index].change.remove,
              removeClerk: false,
              transferor: vm.order.persons[index].change.transferor,
              assign: vm.order.persons[index].change.assign
            };
          index -= 1;
        }
        vm.changes.clerk = false;
      }
      if (item === "endUser" && vm.order.rpvsPersons) {
        let index = vm.order.rpvsPersons.length - 1;
        while (index >= 0) {
          if (vm.order.rpvsPersons[index] && vm.order.rpvsPersons[index].newData)
            delete vm.order.rpvsPersons[index].newData;
          if (vm.order.rpvsPersons[index] && (vm.order.rpvsPersons[index].change.add
            || (vm.order.rpvsPersons[index].change.remove && index >= vm.originalNumberOfRpvsPersons)))
            vm.order.rpvsPersons.splice(index, 1);
          else if (vm.order.rpvsPersons[index])
            vm.order.rpvsPersons[index].change = {
              add: false,
              edit: false,
              remove: false
            };
          index -= 1;
        }
        vm.changes.endUser = false;
      }
      if (item === "subjects")
        vm.subjects.clear();
      if (item === "capital" && vm.order.persons) {
        vm.order.basicCapital = vm.order.oldBasicCapital;
        let index = vm.order.persons.length - 1;
        while (index >= 0) {
          if (vm.order.persons[index].founder && !vm.order.persons[index].change.assign && !vm.order.persons[index].change.transferor) {
            if(vm.order.persons[index].oldDepositAmount)
              vm.order.persons[index].depositAmount = vm.order.persons[index].oldDepositAmount;
            delete vm.order.persons[index].oldDepositAmount;

            vm.order.persons[index].change = {
              add: vm.order.persons[index].change.add,
              edit: vm.order.persons[index].change.edit,
              remove: vm.order.persons[index].change.remove,
              removeClerk: vm.order.persons[index].change.removeClerk,
              transferor: false,
              assign: false
            };
          }
          index -= 1;
        }

        let isOtherChange = false;
        vm.order.persons.forEach(person => {
          if(person.founder
              && (person.change.add || person.change.assign || person.change.edit || person.change.remove || person.change.removeClerk || person.change.transferor)) {
            isOtherChange = true;
          }
        });
        if(!isOtherChange)
          vm.changes.founder = false;

        isOtherChange = false;
        vm.order.persons.forEach(person => {
          if(person.executor
              && (person.change.add || person.change.assign || person.change.edit || person.change.remove || person.change.removeClerk || person.change.transferor)) {
            isOtherChange = true;
          }
        });
        if(!isOtherChange)
          vm.changes.executor = false;
      }
      if (item === "executionType") {
        delete vm.order.executionType;
        delete vm.order.executionTypeOther;
      }

      checkPrice();
  }

  function clearOrder(firstOpen) {
      vm.ico.error = true;

      if(vm.ico.old !== vm.order.ico && !firstOpen) {
          vm.order = {
              ico: vm.order.ico,
              companyName: {},
              shareTransfer: [],
              date: {},
              isSelf: vm.order.isSelf,
              domain: {
                name: "",
                isFree: false,
                active: false
              }
          };
          vm.order.companyName.suffix = vm.suffixes[0];
      }

      if(!vm.placeOfBusiness) {
          vm.placeOfBusiness = {
              type: "own",
              kind: $filter("translate")('placeOfBusinessKind.' + vm.placeOfBusinessKinds[0])
          };
      }
  }

  function confirmClearData(item){
    ModalConfirmDelete("confirmClearData.title").add().result.then(x => {
      clearData(item);
    });
  }

  function clearDate() {
    let changeWithDates = $filter('filter')(vm.order.persons, function (person) {
      return person.change.add || person.change.remove;
    });
    if(changeWithDates.length === 0)
      vm.order.date = {};
  }

  function onAddExecutorOtherInfoClick() {
    let executors = $filter('filter')(vm.order.persons, function (person) {
      return person.executor && !person.change.remove
    });

    ModalExecutorOtherInfo(executors, vm.cities)
      .add()
      .result
      .then(result => {
        result.forEach(function (executor) {
          vm.order.persons[executor.index].dateOfBirth = executor.dateOfBirth;
          vm.order.persons[executor.index].idNumber = executor.idNumber;
        });

        checkPrice();
      });
  }

  function onEditBasicCapitalClick() {
    let allFounders = $filter('filter')(vm.order.persons, {'founder': true});

    allFounders.forEach(function (founder) {
      if(founder.change.edit && !founder.newData)
        founder.diffAmount = Math.abs(founder.depositAmount - founder.oldDepositAmount);
      if(!founder.change.transferor && !founder.change.assign && founder.change.add)
        founder.diffAmount = parseInt(founder.depositAmount);
    });

    ModalBasicCapital(vm.order.oldBasicCapital, vm.order.basicCapital, allFounders)
      .add()
      .result
      .then(result => {
        // z modalu pole spolocnikov s rozdielmi vkladov
        result.founder.forEach(function (founder) {
          vm.order.persons[founder.index].oldDepositAmount = vm.order.persons[founder.index].depositAmount;
          if(vm.order.basicCapital < result.newAmount)
            vm.order.persons[founder.index].depositAmount = parseFloat(vm.order.persons[founder.index].depositAmount) + founder.diffAmount;
          else vm.order.persons[founder.index].depositAmount = parseFloat(vm.order.persons[founder.index].depositAmount) - founder.diffAmount;
          vm.order.persons[founder.index].change.edit = true;
        });

        // nove zakladne imanie
        vm.order.basicCapital = result.newAmount;

        checkPrice();
      });
  }

  function companyTransfer() {
    vm.onTransferCompany({}, "all")
  }

  function onTransferCompany(founder, transferType) {
    let founders = $filter('filter')(vm.order.persons, {'founder': true});
    if(founders.length === 1) {
      transferType = "all";
    }
    ModalTransferCompany(server, vm.currencies, {}, vm.cities, vm.countries, transferType, vm.order.basicCapital)
        .add()
        .result
        .then(result => {
          if(result && result === "share-transfer") {
            onEditShareTransfer(founder);
          } else if(result) {

            let oldIndex = 0;
            let newIndex = 0;

            let tmpFounders = angular.copy(founders);
            let tmpResult = angular.copy(result);

            while (newIndex < result.length && oldIndex < founders.length) {
              let oldPartner = tmpFounders[oldIndex];
              let newPartner = tmpResult[newIndex];

              if(newPartner.shareTransfer.allEuro === undefined)
                newPartner.shareTransfer.allEuro = 0;

              if (parseInt(newPartner.shareTransfer.euro) <= parseInt(oldPartner.depositAmount)) {

                let shareTransfer = {
                  transferor: oldPartner.index,
                  assign: vm.order.persons.length + newIndex,
                  ownEuro: parseInt(oldPartner.depositAmount),
                  transferEuro: parseInt(newPartner.shareTransfer.euro),
                  allEuro: parseInt(newPartner.shareTransfer.allEuro) + parseInt(newPartner.shareTransfer.euro),
                  price: parseInt(newPartner.shareTransfer.price),
                  currency: newPartner.shareTransfer.currency
                };
                vm.order.shareTransfer.push(shareTransfer);

                oldPartner.depositAmount = parseInt(oldPartner.depositAmount) - parseInt(newPartner.shareTransfer.euro);
                newPartner.shareTransfer.allEuro = parseInt(newPartner.shareTransfer.allEuro) + parseInt(newPartner.shareTransfer.euro);
                newPartner.shareTransfer.euro = 0;
                newIndex++;
              } else {
                let shareTransfer = {
                  transferor: oldPartner.index,
                  assign: vm.order.persons.length + newIndex,
                  ownEuro: parseInt(oldPartner.depositAmount),
                  transferEuro: parseInt(oldPartner.depositAmount),
                  allEuro: parseInt(newPartner.shareTransfer.allEuro) + parseInt(oldPartner.depositAmount),
                  price: parseInt(newPartner.shareTransfer.price),
                  currency: newPartner.shareTransfer.currency
                };
                vm.order.shareTransfer.push(shareTransfer);

                newPartner.shareTransfer.euro = parseInt(newPartner.shareTransfer.euro) - parseInt(oldPartner.depositAmount);
                newPartner.shareTransfer.allEuro = parseInt(newPartner.shareTransfer.allEuro) + parseInt(oldPartner.depositAmount);
                oldPartner.depositAmount = 0;
                oldIndex++;
              }
            }

            founders.forEach(founder => {
              founder.oldDepositAmount = founder.depositAmount;
              founder.depositAmount = 0;
              founder.change.transferor = true;
            });

            result.forEach(person => {
              person.otherPerson.depositAmount = person.shareTransfer.euro;

              addOtherPersonFromShareTransfer(person.otherPerson);
            });

            vm.changes.founder = true;

            checkPrice();

            blurChangeBtn();

            vm.isCollapsed.founders = false;
          }
        });
  }

  function onEditShareTransfer(founder, isRemove) {

    let allAssign = $filter('filter')(vm.order.persons, {'founder': true});
    let index = allAssign.indexOf(founder);
    allAssign.splice(index, 1);

    // if(allAssign.length > 0) {
      ModalShareTransfer(server, vm.currencies, founder, allAssign, vm.order.basicCapital, vm.cities, vm.countries, isRemove)
        .add()
        .result
        .then(result => {
          if(result.assign === -1) {
            addOtherPersonFromShareTransfer(result.otherPerson);
          }

          let shareTransfer = {
            transferor: founder.index,
            assign: result.assign !== -1 ? result.assign : vm.order.persons.length - 1,
            ownEuro: parseInt(founder.depositAmount),
            transferEuro: parseInt(result.shareTransfer.euro),
            allEuro: parseInt(result.shareTransfer.newPrice),
            price: parseInt(result.shareTransfer.price),
            currency: result.shareTransfer.currency
          };
          vm.order.shareTransfer.push(shareTransfer);
          founder.oldDepositAmount = founder.depositAmount;
          founder.depositAmount = parseFloat(founder.depositAmount) - parseFloat(result.shareTransfer.euro);
          founder.change.transferor = true;

          if(result.assign !== -1) {
            vm.order.persons[result.assign].oldDepositAmount = vm.order.persons[result.assign].depositAmount;
            vm.order.persons[result.assign].depositAmount = result.shareTransfer.newPrice;
            vm.order.persons[result.assign].change.assign = true;
          }

          vm.changes.founder = true;

          checkPrice();
        });
    // } else onAddFounderWithShareTransfer(isRemove ? 'remove' : 'edit');

    blurChangeBtn();
  }

  function addOtherPersonFromShareTransfer(otherPerson) {
    otherPerson.founder = true;
    otherPerson.executor = false;
    otherPerson.clerk = false;
    otherPerson.change = {
      add: true,
      edit: false,
      remove: false,
      removeClerk: false,
      transferor: false,
      assign: true
    };
    if (vm.order.persons)
      otherPerson.index = vm.order.persons.length;
    else {
      vm.order.persons = [];
      otherPerson.index = 0;
    }
    vm.order.persons.push(otherPerson);
  }

  function isPersonalChange(person) {
    return person.newData ? !(person.fullName === person.newData.fullName
        && person.address.city === person.newData.address.city
        && person.address.street === person.newData.address.street
        && person.address.streetNo === person.newData.address.streetNo
        && person.address.zipCode === person.newData.address.zipCode) : false;
  }

  function onEditFounderClick(founder) {
    let person;
    if(founder.newData)
      person = founder.newData;
    else
      person = founder;

    let personFromOldOrder;

    vm.personsFromOldOrders.forEach(oldP => {
      if(oldP.firstName === person.name && oldP.lastName === person.surname)
        personFromOldOrder = oldP;
    });

    ModalFounderChange(vm.cities, vm.countries, vm.companiesTypes, person, vm.order.isSelf, personFromOldOrder)
      .add()
      .result
      .then(em => {
        founder.newData = em;
        founder.change.edit = true;

        vm.changes.founder = true;
        if (founder.executor && isPersonalChange(founder))
          vm.changes.executor = true;

        checkPrice();

        ModalInfo("", "sro.zmena.krok1.founders.changeHelpModal.desc", "buttons.ok")
            .add()
            .result
            .then();
      });

    blurChangeBtn();
  }

  function onEditExecutiveManagerClick(executor, isExecutor, changeType) {

    let person;
    if(executor.newData)
      person = executor.newData;
    else
      person = executor;

    ModalExecutor(vm.cities, vm.countries, vm.order.date, isExecutor, changeType, person, vm.order.isSelf)
      .add()
      .result
      .then(em => {
        executor.newData = em;
        executor.change.edit = true;

        if(isExecutor) {
          vm.changes.executor = true;
          if (executor.founder)
            vm.changes.founder = true;
        } else vm.changes.clerk = true;

        checkPrice();
      });

    blurChangeBtn();
  }

  function onAddOrEditEndUserClick(changeType, person) {

    let companySuffix = vm.companiesTypes.split(";");

    let allPersons = [];

    if(vm.order.persons) {
      vm.order.persons.forEach(person => {
        if (companySuffix.filter(n => person.fullName.includes(n)).length === 0)
          allPersons.push(person)
      });
    }

    ModalEndUser(vm.cities, vm.countries, person && person.newData ? person.newData : person, allPersons, changeType)
      .add()
      .result
      .then(result => {
          if(person) {
            if(changeType === 'remove'){
              vm.order.rpvsPersons[person.index] = result;
              vm.order.rpvsPersons[person.index].idNumber = null;
            } else {
              vm.order.rpvsPersons[person.index].newData = result;
              vm.order.rpvsPersons[person.index].change = result.change;
            }
          } else {
            let newPerson = result;
            if(vm.order.rpvsPersons) {
              newPerson.index = vm.order.rpvsPersons.length;
            } else {
              newPerson.index = 0;
              vm.order.rpvsPersons = [];
            }
            vm.order.rpvsPersons.push(newPerson);
          }

        vm.changes.endUser = true;

        checkPrice();
      });
  }

  function onAddExecutiveManagerClick(isExecutor, changeType) {
    ModalExecutor(vm.cities, vm.countries, vm.order.date, isExecutor, changeType)
      .add()
      .result
      .then(em => {
        let person = em;
        person.founder = false;
        person.executor = !(!isExecutor);
        person.clerk = !isExecutor;
        person.change = {
          add: !changeType ? true : false,
          edit: changeType && changeType === "edit" ? true : false,
          remove: changeType && changeType === "remove" && isExecutor ? true : false,
          removeClerk: changeType && changeType === "remove" && !isExecutor ? true : false,
          transferor: false,
          assign: false
        };
        if(vm.order.persons)
          person.index = vm.order.persons.length;
        else {
          vm.order.persons = [];
          person.index = 0;
        }
        vm.order.persons.push(person);
        if(isExecutor) {
          vm.changes.executor = true;
          if(em.date)
            vm.order.date = em.date;
        } else vm.changes.clerk = true;

        checkPrice();
      });

    blurChangeBtn();
  }

  // v pripade ze je jednoosobova spolocnost sa otvara tento modal pre zrusenie aj prevod podielu
  // preto je potrebny typ
  function onAddFounderWithShareTransfer(changeType) {
    let allTransferor = $filter('filter')(vm.order.persons, {'founder': true});

    ModalFounderWithCapital(server, vm.currencies, vm.cities, vm.countries, vm.order.basicCapital, vm.ico.error ? 'transfer' : undefined, changeType, allTransferor)
      .add()
      .result
      .then(em => {
        let founder = em.founder;
        founder.founder = true;
        founder.executor = false;
        founder.clerk = false;
        founder.change = {
          add: true,
          edit: false,
          remove: false,
          removeClerk: false,
          transferor: false,
          assign: true
        };
        if(vm.order.persons)
          founder.index = vm.order.persons.length;
        else {
          vm.order.persons = [];
          founder.index = 0;
        }
        vm.order.persons.push(founder);

        // v pripade ze nie su dotiahnute udaje z finstatu prevodca vyplna vsetky udaje
        if(em.transferorData) {
          let transferor = em.transferorData;
          transferor.founder = true;
          transferor.executor = false;
          transferor.clerk = false;
          transferor.change = {
            add: false,
            edit: false,
            remove: false,
            removeClerk: false,
            transferor: true,
            assign: false
          };
          transferor.fullName = vm.getFullName(transferor);
          transferor.index = vm.order.persons.length;
          transferor.depositAmount = 5000;
          vm.order.persons.push(transferor);

          em.transferor = transferor.index;

          vm.order.oldBasicCapital = vm.order.basicCapital = em.fullCapital;
        }

        vm.order.persons[em.transferor].oldDepositAmount = vm.order.persons[em.transferor].depositAmount;
        vm.order.persons[em.transferor].depositAmount = parseFloat(vm.order.persons[em.transferor].oldDepositAmount) - parseFloat(founder.depositAmount);
        vm.order.persons[em.transferor].change.transferor = true;

        let shareTransfer = {
          transferor: em.transferor,
          assign: founder.index,
          ownEuro: parseInt(vm.order.persons[em.transferor].oldDepositAmount),
          transferEuro: parseInt(founder.depositAmount),
          allEuro: parseInt(founder.depositAmount),
          price: parseInt(em.price),
          currency: em.currency
        };
        vm.order.shareTransfer.push(shareTransfer);

        checkPrice();
        vm.changes.founder = true;
      });

    blurChangeBtn();
  }

  function onAddFounderWithCapitalClick() {
    ModalFounderWithCapital(server, vm.currencies, vm.cities, vm.countries, vm.order.basicCapital, vm.ico.error ? 'capital' : undefined)
      .add()
      .result
      .then(em => {
        let founder = em.founder;
        founder.founder = true;
        founder.executor = false;
        founder.clerk = false;
        founder.change = {
          add: true,
          edit: false,
          remove: false,
          removeClerk: false,
          transferor: false,
          assign: false
        };
        if(vm.order.persons)
          founder.index = vm.order.persons.length;
        else {
          vm.order.persons = [];
          founder.index = 0;
        }
        if(em.fullCapital)
          vm.order.oldBasicCapital = vm.order.basicCapital = em.fullCapital;
        vm.order.persons.push(founder);
        vm.changes.founder = true;
        vm.order.basicCapital = parseFloat(vm.order.basicCapital) + founder.depositAmount;

        checkPrice();
      });

    blurChangeBtn();
  }

  function calculateDateOfBirth(person) {
    if (person.idNumber && person.idNumber.prefix && person.idNumber.prefix.length === 6) {
      person.dateOfBirth = Helpers.calculateDateOfBirth(person.idNumber);
    }
  }

  function onRemovePersonClick(person, isExecutor) {
    ModalPersonRemove(person, vm.order.date, isExecutor)
      .add()
      .result
      .then(e => {
        if(isExecutor) {
          if(e.date)
            vm.order.date = e.date;
          person.change.remove = true;
          vm.changes.executor = true;

          if(person.founder && !vm.order.isSelf) {
            ModalRedirect('sro.zmena.krok1.executors.samePerson.title', 'sro.zmena.krok1.executors.samePerson.text')
                .add()
                .result
                .then(result => {
                  if (result)
                    onEditShareTransfer(person, true);
                });
          }
        } else {
          person.change.removeClerk = true;
          vm.changes.clerk = true;
        }
        person.idNumber = e.idNumber;

        calculateDateOfBirth(person);

        checkPrice();
      });

    blurChangeBtn();
  }

  function onEditExecutionTypeClick() {
    ModalExecutionType(
      vm.order.executionType ? vm.order.executionType : vm.executionType,
      vm.order.executionTypeOther ? vm.order.executionTypeOther : vm.executionTypeOther)
      .add()
      .result
      .then(e => {
        vm.order.executionType = e.executionType;
        vm.order.executionTypeOther = e.executionTypeOther;

        checkPrice();
      });

    blurChangeBtn();
  }

  function onChangeCompanyNameClick() {
    ModalCompanyName(server, vm.order.companyName, vm.order.name, vm.order.domain)
      .add()
      .result
      .then(n => {
        vm.order.companyName.name = n[0];
        vm.order.companyName.suffix = n[1];

        if(!vm.order.domain) {
          vm.order.domain = {
            name: "",
            isFree: false,
            active: false
          }
        }
        vm.order.domain.name = vm.order.companyName.name;

        checkPrice();
      });

    blurChangeBtn();
  }

  function onChangePlaceOfBusinessClick() {
    ModalPlaceOfBusiness(vm.placeOfBusiness, vm.cities, vm.firmarenPlacesOfBusiness, vm.firmarenAddress, vm.order.address)
      .add()
      .result
      .then(onChangePlace);

    blurChangeBtn();
  }

  function onChangePlace(item) {
    vm.placeOfBusiness = item[0];
    vm.firmarenAddress = item[1];

    vm.newAddress.address = vm.placeOfBusiness && vm.placeOfBusiness.type === "firmaren" ? vm.firmarenAddress : vm.placeOfBusiness.address;

    checkPrice();
  }

  function onCartLoad(c) {
    if(c) {
      vm.cart.price = c.totalPriceWithTax;

      if (!vm.order.isSelf) {
        vm.cart.priceWithoutTax = c.totalPrice;
      }

      vm.cart.placeOfWork = c.placeOfBusinessPrice;
      vm.cart.size = c.subjectCount;
    }
      window.hideSpinnerWhenAllResolved();
  }

  function scrollTo(scrollLocation) {
    let scrollId = vm.scrollToEndUser ? "endUser" : (vm.scrollToFounders ? "founders" : scrollLocation);
    $('html, body').animate({
      scrollTop: $("#" + scrollId).offset().top - 170
    }, 500);
  }

  function removeNonSelfData() {
    detectUrl();
    if(vm.order.isSelf) {
      if (vm.order.persons) {
        // executors
        let index = vm.order.persons.length - 1;
        while (index >= 0) {
          if (vm.order.persons[index].executor && vm.order.persons[index].change.add)
            vm.order.persons.splice(index, 1);
          else if (vm.order.persons[index].executor)
            vm.order.persons[index].change = {
              add: false,
              edit: vm.order.persons[index].change.edit,
              remove: false,
              removeClerk: false,
              transferor: false,
              assign: false
            };
          index -= 1;
        }
        // founders
        index = vm.order.persons.length - 1;
        while (index >= 0) {
          if (vm.order.persons[index].founder && vm.order.persons[index].oldDepositAmount) {
            vm.order.persons[index].depositAmount = vm.order.persons[index].oldDepositAmount;
            delete vm.order.persons[index].oldDepositAmount;
          }
          if (vm.order.persons[index].founder && vm.order.persons[index].change.add)
            vm.order.persons.splice(index, 1);
          else if (vm.order.persons[index].founder)
            vm.order.persons[index].change = {
              add: false,
              edit: vm.order.persons[index].change.edit,
              remove: false,
              removeClerk: false,
              transferor: false,
              assign: false
            };
          index -= 1;
        }
        clearDate();
        vm.order.shareTransfer = [];
      }

      clearData("name");
      clearData("place");
      clearData("subjects");
      clearData("capital");
      clearData("executionType");
      clearData("endUser");
      clearData("clerks");
    }
  }

  function onOrderLoad(c) {
    if(c) {
      vm.order = c;
      vm.ico.old = vm.order.ico;

      vm.showEndUser = vm.order.onlyEndUser;

      if(!vm.order.date)
        vm.order.date = {};
      if(!vm.order.companyName)
        vm.order.companyName = {
          suffix: vm.suffixes[0]
        };

      onIcoChange(true);

      removeNonSelfData();
    } else {
      server.getStringContent().then((data) => {
        if (data) {
          vm.order = JSON.parse(data).data;
          vm.isCollapsed = {
            executors: false,
            companyName: false,
            place: false,
            subjects: false,
            founders: false,
            executionType: false
          };

          vm.disableElement = false;

          vm.placeOfBusiness = JSON.parse(data).placeOfBusiness;
          if (vm.placeOfBusiness && vm.placeOfBusiness.type === "firmaren") {
            vm.placeOfBusiness = { type: JSON.parse(data).placeOfBusiness.type };
            vm.firmarenAddress = JSON.parse(data).placeOfBusiness;
          }
          vm.newAddress.address = vm.placeOfBusiness && vm.placeOfBusiness.type === "firmaren" ? vm.firmarenAddress : (vm.placeOfBusiness ? vm.placeOfBusiness.address : undefined);
          vm.ico.old = vm.order.ico;
          onIcoChange(true);
          removeNonSelfData();
        } else {
          server.getLandingData().then((data) => {
            if(data) {
              vm.landingCollapsed = data.isCollapsed;
              if(vm.landingCollapsed && !vm.landingCollapsed.companyTransfer) {
                vm.showInstructionVideo = false;
              }
              if(vm.landingCollapsed && !vm.landingCollapsed.endUser && !vm.order.isSelf) {
                vm.scrollToEndUser = true;
                vm.showInstructionEndUser = true;
                vm.showEndUser = true;
              } else if(vm.landingCollapsed && !vm.landingCollapsed.founders) {
                vm.scrollToFounders = true;
              }
              vm.ico.old = vm.order.ico = data.ico;
              if(vm.order.ico)
                onIcoChange(true);
            }
            removeNonSelfData();
          });
        }
      });
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onCityChange($item) {
    vm.placeOfBusiness.address.zipCode = $item && $item._3;
  }

  function onCitiesLoad(c) {
    vm.cities = c;

    window.hideSpinnerWhenAllResolved();
  }

  function onIcoChange(firstOpen) {
    vm.finstatSpinner = true;
    vm.ico.error = false;
    vm.ico.success = false;
    server.getFinstatFullInfo({name: vm.order.ico}).then( data => {
      if(data) {
        vm.order.name = data.name;

        if(!vm.order.domain) {
          vm.order.domain = {
            name: "",
            isFree: false,
            active: false
          }
        }

        vm.companiesTypes.split(";").forEach(cType => {
          if (data.name.endsWith(cType))
            vm.order.domain.name = data.name.replace(cType, "");
        });

        if(!vm.order.domain.name)
          vm.order.domain.name = data.name;

        vm.order.address = data.address;
        vm.order.zapisRegister = data.zapisRegister;
        vm.order.icDph = data.icdph;
        vm.order.dic = data.dic;
        vm.ico.success = true;
        if(!vm.order.basicCapital || vm.ico.old !== vm.order.ico)
          vm.order.basicCapital = parseFloat(data.basicCapital);
        else vm.order.basicCapital = parseFloat(vm.order.basicCapital);
        vm.order.oldBasicCapital = parseFloat(data.basicCapital);
        if(!vm.order.removeSubjects || vm.ico.old !== vm.order.ico)
          vm.order.removeSubjects = data.subjects;
        scrollTo("successIco");

        if(!vm.order.persons || vm.ico.old !== vm.order.ico) {
          vm.order.persons = [];
          angular.forEach(data.persons, function (person, index) {
            person.index = index;
            person.executor = false;
            person.founder = false;
            person.clerk = false;
            person.change = {
              add: false,
              edit: false,
              remove: false,
              removeClerk: false,
              transferor: false,
              assign: false
            };
            angular.forEach(person.functions, function (f) {
              if (f.description === "Konateľ") {
                person.executor = true;
                person.from = f.from;
              }
              if (f.description === "Spoločník") {
                person.founder = true;
              }
              if (f.description === "Prokúra")
                person.clerk = true;
            });
            delete person.functions;
            vm.order.persons.push(person);
          });
        } else if(vm.order.persons) {
          angular.forEach(vm.order.persons, function (person) {
            if(person.founder && (person.change.add || person.change.edit || person.change.remove || person.change.assign || person.change.transferor))
              vm.changes.founder = true;
            if(person.executor && (person.change.add || (person.change.edit && isPersonalChange(person)) || person.change.remove))
              vm.changes.executor = true;
            if(person.clerk && (person.change.add || person.change.edit || person.change.removeClerk))
              vm.changes.clerk = true;
          });
        }

        if(!vm.order.rpvsPersons || vm.ico.old !== vm.order.ico) {
          vm.order.rpvsPersons = [];
          var index = 0;
          angular.forEach(data.rpvsPersons, function (person) {
            person.change = {
              add: false,
              edit: false,
              remove: false
            };
            angular.forEach(person.functions, function (f) {
              if (f.type === "Konečný užívateľ výhod podľa RPVS") {
                delete person.functions;
                person.index = index;
                index += 1;
                vm.order.rpvsPersons.push(person);
              }
            });
            vm.originalNumberOfRpvsPersons = vm.order.rpvsPersons.length;
          });
        } else if(vm.order.rpvsPersons) {
          angular.forEach(vm.order.rpvsPersons, function (person) {
            if(person.change.add || person.change.edit || person.change.remove)
              vm.changes.endUser = true;
          });
        }

        if(!angular.equals(vm.order.date, {}))
          vm.order.date = new Date(vm.order.date);

        if(data.executionType === $filter("translate")('sro.zalozenie.krok2.executionType.check.' + vm.executionTypes[0]))
          vm.executionType = vm.executionTypes[0];
        else if(data.executionType === $filter("translate")('sro.zalozenie.krok2.executionType.check.' + vm.executionTypes[1]))
          vm.executionType = vm.executionTypes[1];
        else {
          vm.executionType = vm.executionTypes[2];
          vm.executionTypeOther = data.executionType;
        }

        if(!angular.equals(vm.landingCollapsed, {})) {
            vm.isCollapsed = vm.landingCollapsed;
            vm.landingCollapsed = {};
        }
        else {
          if(vm.order.isSelf) {
            vm.isCollapsed.executors = false;
            vm.isCollapsed.founders = false;
          } else {
            vm.isCollapsed.executors = false;
            vm.isCollapsed.companyName = false;
            vm.isCollapsed.place = false;
            vm.isCollapsed.subjects = false;
            vm.isCollapsed.founders = false;
            vm.isCollapsed.executionType = false;
            vm.isCollapsed.companyTransfer = false;
          }
        }

        if (vm.scrollToFounders) {
          vm.onElementBlur("srch_bar");
          let founders = vm.order.persons.filter(p => p.founder);
          onEditFounderClick(founders[0]);
        }

      } else {
        clearOrder(firstOpen);
      }
      vm.finstatSpinner = false;
      vm.ico.old = vm.order.ico;

      checkPrice();
      vm.disableElement = !(vm.order.ico.length === 8 && (vm.ico.error || vm.ico.success));
    }).catch(function() {
        vm.disableElement = true;
        clearOrder(firstOpen);
    });

    server.getAllPersons({name: vm.order.ico}).then( data => {
      if(data) {
        vm.personsFromOldOrders = data;
      }
    });

    if(!firstOpen) {
      clearData("name");
      if(vm.placeOfBusiness.type !== "firmaren")
        clearData("place");
      // clearData("persons");
      clearData("executors");
      clearData("founders");
      clearData("subjects");
      clearData("capital");
      clearData("executionType");
      clearData("endUser");
      clearData("clerks");
    }
  }

  function onPlaceOfBusinessLoad(pb) {
    if (angular.isDefined(pb) && !vm.order.isSelf) {
      if (pb.type === "own") {
        vm.placeOfBusiness = pb;
      }
      else if (pb.type === "firmaren") {
        vm.placeOfBusiness = { type: pb.type };
        vm.firmarenAddress = pb;
      }
      vm.newAddress.address = vm.placeOfBusiness && vm.placeOfBusiness.type === "firmaren" ? vm.firmarenAddress : vm.placeOfBusiness.address;
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onCompanyNameChange() {
    saveCompanyName();
  }

  function onCompanySuffixChange() {
    saveCompanyName();
  }

  function onFirmarenPlacesOfBusinessLoad(ps) {
    vm.firmarenPlacesOfBusiness = ps;
    vm.firmarenAddress = vm.firmarenPlacesOfBusiness[0];

    window.hideSpinnerWhenAllResolved();
  }

  let saveCompanyName = _.debounce(_saveCompanyName, 1000);
  function _saveCompanyName() {
    vm.companyNameState = {loading: true};
    if(vm.order.companyName.name && vm.order.companyName.suffix) {
      let name = vm.order.companyName.name;

      let result = server.setCompanyName(vm.order.companyName);

      if (name !== "") {
        if(name.length > 1) {
          vm.companyNameState = {loading: true};

          loading(result, 1000)
            .then(({data, error}) => {
              if (data) {
                vm.companyNameState = {success: true};
                if(!vm.order.domain) {
                  vm.order.domain = {
                    name: "",
                    isFree: false,
                    active: false
                  }
                }
                vm.order.domain.name = vm.order.companyName.name;
              }
              else {
                if(error === "notAvailable")
                  vm.companyNameState = {notAvailable: true};
                else vm.companyNameState = {error};
              }
            })
            .catch((e) => {
              console.log(`todo: ${e}`);
              vm.companyNameState = {error};
            });
        } else vm.companyNameState = {warn: true};
      }
      else {
        vm.companyNameState = {};
      }
    }
  }

  function onFirmarenAddressClick() {
    vm.isCollapsed.firmarenAddress = !vm.isCollapsed.firmarenAddress;
  }

  function setFirmarenPlaceOfBusiness(place) {
    vm.firmarenAddress = place;
    vm.isCollapsed.firmarenAddress = true;
  }

  BusinessSubjects.onChange($scope, ($event, subjects) => onSelectionChange(subjects));

  function onSelectedSubjectsLoad(ss) {
    if (angular.isDefined(ss)) {
      vm.isCollapsed.subjects = false;
      BusinessSubjects.select(ss.subjects);
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onSelectionChange(subjects) {
    // TODO vymazat ked nebude vyzadovat zivno urad
    // let subject = _.last(subjects);
    // if(subject && subject.responsiblePerson && vm.order.persons && subject.responsiblePerson.type !== 'other')
    //   vm.order.persons[subject.responsiblePerson.type].otherInfo = subject.responsiblePerson.person.otherInfo;
    server.setSelectedSubjects({ subjects })
      .then(onCartLoad);
  }

  function checkRemoveSubjects() {
    var isChange = false;
    if (vm.order.removeSubjects) {
      vm.order.removeSubjects.forEach(subject => {
        if (subject.remove) {
          isChange = true;
        }
      });
    }
    return isChange;
  }

  function checkStreetNumber() {
    let addresses = [vm.placeOfBusiness && vm.placeOfBusiness.address];

    if(vm.order.persons) {
      vm.order.persons.forEach(person => {
        if (person.change.add || person.change.edit) {
          if (person.newData) {
            addresses.push(person.newData.address);
          } else {
            person.address;
          }
        }
      });
    }

    if(vm.order.rpvsPersons) {
      vm.order.rpvsPersons.forEach(person => {
        if (person.change.add || person.change.edit) {
          if (person.newData) {
            addresses.push(person.newData.address);
          } else {
            person.address;
          }
        }
      });
    }

    var result = false;

    addresses.forEach(address => {
      if (address && address.streetNo && (!address.streetNoPrefix || address.streetNoPrefix === ""))
        result = true;
    });

    return result;
  }

  function addBDayAndIdNumber(personIds) {
    let persons = $filter('filter')(vm.order.persons, function (person) {
      return personIds.indexOf(person.index) !== -1;
    });

    ModalBdayIdnumber(persons, vm.personsFromOldOrders)
        .add()
        .result
        .then(result => {
          result.forEach(function (person) {
            vm.order.persons[person.index].dateOfBirth = person.dateOfBirth;
            vm.order.persons[person.index].idNumber = person.idNumber;
          });
        });
  }

  function checkBDayIdNumForShareTransfer() {
    let companySuffix = vm.companiesTypes.split(";");
    let personIdsWithoutBDayAndIdNum = [];
    if (vm.order.shareTransfer.length > 0) {
      vm.order.shareTransfer.forEach(t => {
        if (personIdsWithoutBDayAndIdNum.indexOf(t.transferor) === -1 &&
            (!vm.order.persons[t.transferor].idNumber || !vm.order.persons[t.transferor].dateOfBirth) &&
            !vm.order.persons[t.transferor].newData) {
          if (companySuffix.filter(n => vm.order.persons[t.transferor].fullName.includes(n)).length === 0) {
            personIdsWithoutBDayAndIdNum.push(t.transferor);
          }
        }
        if (personIdsWithoutBDayAndIdNum.indexOf(t.assign) === -1 &&
            (!vm.order.persons[t.assign].idNumber || !vm.order.persons[t.assign].dateOfBirth) &&
            !vm.order.persons[t.assign].newData) {
          if (companySuffix.filter(n => vm.order.persons[t.assign].fullName.includes(n)).length === 0) {
            personIdsWithoutBDayAndIdNum.push(t.assign);
          }
        }
      });
    }
    return personIdsWithoutBDayAndIdNum;
  }

  function onSaveAndContinueClick(steps) {
    let executorsWithoutOtherInfo = $filter('filter')(vm.order.persons, function (person) {
      return person.executor && !person.change.remove && !person.idNumber && !person.dateOfBirth
    });

    if (vm.order.other && vm.order.other.comment)
      vm.order.other.comment = $filter("removeEmojiFilter")(vm.order.other.comment);

    if ($scope.step1.$invalid) {
      vm.requiredMessage = true;
      vm.submitted = true;
    } else {
      if (vm.showStreetNumberModals && checkStreetNumber()) {
        ModalStreetNumber()
            .add()
            .result
            .then();

        vm.showStreetNumberModals = false;
      } else {
        let personIdsST = checkBDayIdNumForShareTransfer();
        if (personIdsST.length > 0) {
          addBDayAndIdNumber(personIdsST);
        } else {
          if (vm.subjects.selected.length > 0 && executorsWithoutOtherInfo.length > 0) {
            onAddExecutorOtherInfoClick();
          } else {
            if (vm.changes.endUser
                && !vm.order.companyName.name
                && !vm.newAddress.address
                && !vm.changes.executor
                && !vm.changes.founder
                && vm.subjects.selected.length === 0
                && vm.order.oldBasicCapital === vm.order.basicCapital
                && !vm.changes.clerk
                && !checkRemoveSubjects()
                && !(vm.order.executionType && (vm.executionType !== vm.order.executionType || (vm.executionType === 'other' && vm.executionTypeOther !== vm.order.executionTypeOther))))
              vm.order.onlyEndUser = true;
            else vm.order.onlyEndUser = false;

            if(vm.ico.error) {
              vm.newAddress.address = vm.placeOfBusiness && vm.placeOfBusiness.type === "firmaren" ? vm.firmarenAddress : vm.placeOfBusiness.address;
            }

            // ak nie je nic vyplnene nepusti na dalsi krok
            if (!vm.changes.endUser
                && !vm.order.companyName.name
                && !vm.newAddress.address
                && !vm.changes.executor
                && !vm.changes.founder
                && vm.subjects.selected.length === 0
                && vm.order.oldBasicCapital === vm.order.basicCapital
                && !vm.changes.clerk
                && (!vm.order.other || vm.order.other.length === 0)
                && !checkRemoveSubjects()
                && !(vm.order.executionType && (vm.executionType !== vm.order.executionType || (vm.executionType === 'other' && vm.executionTypeOther !== vm.order.executionTypeOther)))) {
              ModalInfo("modalInfo.titleNoChange", "modalInfo.infoNoChange", "buttons.ok")
                  .add()
                  .result
                  .then();
            } else {
              server.saveAndContinue(vm.order).then(({success, data}) => {
                if (success) {
                  let placeOfBusiness = vm.placeOfBusiness && vm.placeOfBusiness.type === "firmaren" ? vm.firmarenAddress : vm.placeOfBusiness;
                  if (placeOfBusiness && (placeOfBusiness.street || placeOfBusiness.address)) {
                    server.setPlaceOfBusiness(placeOfBusiness)
                        .then(() => {
                          if (steps) {
                            vm.valid = true;
                          } else {
                            $window.location = "krok2";
                          }
                        })
                        .catch((e) => console.log(`todo: ${e}`));
                  } else {
                    if (steps) {
                      vm.valid = true;
                    } else {
                      $window.location = "krok2";
                    }
                  }
                }
              });
            }
          }
        }
      }
    }
  }

  function onSaveForLaterClick() {
    let order = {
      data : vm.order,
      placeOfBusiness: vm.placeOfBusiness
    };

    ModalFinishLater(server, JSON.stringify(order))
      .add(server, JSON.stringify(order))
      .result
      .then();
  }

  function openSelect() {
    var dropdown = document.getElementById("selectCompanySuffix");
    var event = new MouseEvent('mousedown');
    dropdown.dispatchEvent(event);
  }
}

angular.module("firmaren")
  .controller("ZmenaSroKrok1Controller", ZmenaSroKrok1Controller);
}());
