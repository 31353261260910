;(function() {
let translations = {
  sk: {
    nav: {
      sro: {
        dropdown: "S.R.O.",
        zalozenie: "Založenie S.R.O.",
        jednoosobova: "Jedno-osobová SRO",
        zmeny: "Zmeny v S.R.O.",
        zmenyRc: "Zápis RČ do OR",
        readymade: "Readymade spoločnosti",
        dokumenty: "Dokumenty na založenie S.R.O.",
        zrusenie: "Zrušenie a likvidácia S.R.O.",
        burza: "Predať firmu",
        usszmeny: "Urob si sám zmeny v S.R.O."
      },
      zivnosti: {
        dropdown: "Živnostníci",
        zalozenie: "Založenie živnosti",
        zalozenieEN: "Setting up of a trade",
        student: "Študentská živnosť",
        rozsirenie: "Rozšírenie živnosti",
        zrusenie: "Zrušenie živnosti",
        pozastavenie: "Pozastavenie živnosti",
        obnovenie: "Obnovenie živnosti",
        zmeny: "Zmeny v živnosti"
      },
      obcianske: {
        dropdown: "Občianske združenia",
        obcianske: "Založenie občianskeho združenia",
        ussobcianske: "Urob si sám: Založenie občianskeho združenia",
        readymadeobcianske: "Hotové občianske združenia"
      },
      uctovnici: "Účtovníci",
      sluzby: {
        dropdown: "Ďalšie služby",
        burza: "Burza firiem",
        sidlo: "Virtuálne sídlo",
        konverzia: "Konverzia dokumentov",
        generator: "Generátor názvov"
      },
      kuv: "Konečný užívateľ výhod",
      video: "Videonávody",
      uss: {
        dropdown: "Urob si sám",
        oz: "USS - Občianske združenie",
        bydlisko: "USS - Zmena bydliska (SRO)",
        rodcislo: "Zápis rodného čísla do OR"
      },
      cennik: "Cenník",
      onas: {
        dropdown: "O nás",
        podmienky: "Obchodné podmienky",
        referencie: "Referencie",
        napisalionas: "Napísali o nás v médiách",
        akofunguje: "Ako to funguje",
        faq: "Časté otázky",
        vzory: "Vzory",
        ochranaudajov: "Podmienky ochrany osobných údajov"
      },
      blog: "Blog",
      kontakt: "Kontakt",
      new: "NOVÉ"
    },

    // Pages

    index: {
      title: "Firmáreň ­ zakladanie firiem a živností online"
    },
    sidlo: {
      zmena: {
        title: "Zmena sídla"
      }
    },
    obchodneMeno: {
      zmena: {
        title: "Zmena obchodného mena"
      }
    },
    konatelUdaje: {
      zmena: {
        title: "Zmena údajov konateľa"
      }
    },
    spolocnikUdaje: {
      zmena: {
        title: "Zmena údajov spoločníka"
      }
    },
    predmetPodnikania: {
      zmena: {
        title: "Zmena predmetu podnikania"
      }
    },
    prevodZmenaKonatela: {
      zmena: {
        title: "Prevod a zmena konateľa"
      }
    },
    konatel: {
      zmena: {
        title: "Zmena konateľa"
      }
    },

    sro: {
      nameDay: {
        title: "<strong>Jednoosobová SRO iba za <span class=\"modal-header-red\">1€</span></strong><br />dnes pre: <strong>{{name}}</strong>",
        subtitle: "Meninová akcia je darček pre každého zakladateľa, ktorý má dnes meniny.",
        desc: "Hradí sa iba štátny poplatok za registráciu spoločnosti vo výške 150 €, ktorý ide na účet obchodného registra.",
        descStep1: "Cena sa zníži po zadaní mena, v 2. kroku objednávky.<br />Hradí sa iba štátny poplatok za registráciu spoločnosti vo výške 150 €, ktorý ide na účet obchodného registra."
      },
      actualInfoTitle: "Aktuálna informácia",
      actualInfoText: "V týchto dňoch evidujeme značné meškanie zápisov do obchodného registra v Bratislavskom kraji (Okresný súd Bratislava I). Meškanie môže prekročiť aj štyri týždne a z našej strany to, žiaľ, nedokážeme ovplyvniť. Pri firmách so sídlom mimo Bratislavského kraja tento problém neevidujeme.<br /><br />Považujeme za správne informovať vás o tejto situácii ešte pred zaplatením objednávky.",

      zalozenie: {
        title: "Založenie S.R.O. online | Firmáreň",

        krok1: {
          label: "Obchodné meno a sídlo",

          header: "Zvoľte si obchodné meno vašej novej firmy (SRO)",
          subheader: "Zadajte údaje o vašej novej firme a všetko ostatné vybavíme za vás",
          subheaderDuplicate: "Údaje v objednávke sú predvyplnené podľa pôvodnej objednávky, ktorú ste zduplikovali. Prejdite jednotlivými krokmi, zmeňte to, čo potrebujete a následne objednávku odošlite.<br /><strong>Začnite zadaním nového obchodného mena firmy do poľa nižšie, ktoré sme nechali prázdne.</strong>",

          placeholder: "Napíšte obchodné meno novej spoločnosti *",
          emailTooltip: "Na tento email vám zašleme všetko potrebné pre založenie firmy a prostredníctvom tohto emailu budeme s vami môcť komunikovať online, ak to bude potrebné.",

          placeOfBusiness: {
            header: "Sídlo spoločnosti",
            email: "Váš email",

            type: {
              own: "Vlastné alebo prenajaté",
              firmaren: "Virtuálne sídlo"
            },

            address: {
              street: "Ulica *",
              streetTooltip: "Zadajte názov ulice alebo verejného priestranstva (námestie). Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce.",
              streetNo: "Súpisné číslo / orientačné číslo *",
              streetNoTooltip: "Ide o číslo, ktoré sa na budovách uvádza zvyčajne červenou farbou. V adrese uvedenej v doklade totožnosti sa uvádza za lomítkom. Ak sa obec nedelí na ulice, orientačné číslo neexistuje - V takomto prípade uveďte aj do tohto poľa súpisné číslo.",
              city: "Obec *",
              cityPlaceholder: "Napr. Bratislava",
              zipCode: "PSČ *"
            },

            help: "Na vytvorenie dokumentu <strong>\"Súhlas vlastníka nehnuteľnosti\"</strong> " +
            "so zápisom nehnuteľnosti do obchodného registra ako sídla vašej spoločnosti budeme potrebovať nasledovné údaje:",

            kind: "Druh priestoru *",
            ownerTooltip: "Zadajte názov vlastníka nehnuteľnosti v ktorej bude mať Vaša firma sídlo (ak je vlastníkom spoločnosť) alebo titul, meno a priezvisko vlastníka nehnuteľnosti (ak je vlastníkom fyzická osoba). Ak je spoluvlastníkov viac, uveďte iba jedného z nich. Ostatných portál vyhľadá automaticky sám.",
            owner: "Vlastník priestoru *"
          }
        },

        krok2: {
          label: "Údaje o spoločnosti",

          header: "Vyplňte údaje o podnikateľovi",
          subheader: "Vyplňte základné imanie a pridajte zakladateľov (spoločníkov) a konateľov",

          amountTooltip: "Od 1.1.2016 finančné prostriedky do výšky 5000 eur nemusia byť vkladané ná účet v banke. Minimálna výška základného imania pri s.r.o. je podľa zákona 5000,- Eur. Môžete zadať aj viac.",
          capitalTooltip: "Od 1.1.2016 finančné prostriedky do výšky 5000 eur nemusia byť vkladané ná účet v banke. V prípade jediného zakladateľa musí byť základné imanie splatené v plnom rozsahu, teda 5000 eur (100%). Ak je zakladateľov viac, rozsah splatenia základného imania postačí vo výške 2500 eur.",
          founderTooltip: "Vložte zakladateľa (spoločníka), ktorým bude fyzická osoba alebo právnická osoba (napr. iná spoločnosť).",

          capital: {
            header: "Základné imanie",
            amount: "Výška",
            extent: "Rozsah splatenia"
          },

          founders: {
            header: "Zakladatelia (Spoločníci)",
            headerSingle: "Zakladateľ (Spoločník)",
            name: "Meno a priezvisko",
            capital: "Vklad",
            share: "Podiel",
            paid: "Splatené",
            add: "Vložiť ďalšieho zakladateľa",
            addFirst: "Vložiť nového zakladateľa",
            cityPlaceholder: "Napr. Bratislava",
            nationalityPlaceholder: "Napr. Slovenská"
          },

          executiveManagers: {
            header: "Konatelia",
            headerSingle: "Konateľ",
            add: "Pridať konateľa",
            info: "Zvolili ste iného konateľa ako zakladatela. <br/>Môžete si ho pridať pomocou tlačidla <strong>\"Pridať konateľa\".</strong>",
            tools: {
              tooltip: {
                edit: "Táto osoba je konateľ a súčasne aj spoločník. Túto osobu môžete upraviť pri úprave spoločníka.",
                remove: "Táto osoba je konateľ a súčasne aj spoločník. Odobrať ho môžete pri úprave spoločníka odškrtnutím checkboxu o tom, že tento spoločník je zároveň aj konateľ."
              }
            }
          },

          executionType: {
            header: "Spôsob konania konateľov",
            tooltip: "Konateľ je štatutárny orgán spoločnosti a koná a podpisuje v jej mene navonok samostatne alebo spoločne s iným konateľom. Zvoľte spôsob konania.",
            onTheirOwn: "V mene spoločnosti koná a podpisuje každý konateľ <strong>samostatne</strong>",
            together: "V mene spoločnosti konajú všetci konatelia <strong>spoločne</strong>",
            other: "Iné",
            check: {
              onTheirOwn: "V mene spoločnosti koná a podpisuje každý konateľ samostatne.",
              together: "V mene spoločnosti konajú všetci konatelia spoločne."
            }
          },

          endUser: {
            header: "Konečným užívateľom výhod sú",
            founders: "Spoločníci/zakladatelia",
            other: "Iné osoby",
            otherPlaceholder: "Uveďte mená a priezviská, adresu bydliska, dátum narodenia, rodné číslo, číslo pasu alebo občianskeho preukazu."
          }
        },

        krok3: {
          label: "Predmet podnikania",

          header: "Vyberte si predmet podnikania",
          subheader: "Na tomto mieste vám pomôžeme s výberom najvhodnejších predmetov podnikania.<br>" +
          "Ako prvú zadajte hlavnú činnosť podnikania"
        },

        krok4: {
          label: "Záverečné údaje",

          header: "Záverečné údaje pred dokončením",
          subheader: "",

          register: {
            label: "Súčasne s registráciou podnikateľa automaticky prebehne aj registrácia pre daň z príjmu a zaslanie DIČ (daňového identifikačného čísla). Poznámka: Súčasťou registrácie nie je registrácia pre DPH (daň z pridanej hodnoty).",

            ask: "Žiadam",
            dontAsk: "Nežiadam, zaregistrujem sám / sama"
          },

          prokura: {
            label: "Prokúra a Dozorná rada"
            // TODO
          },

          registerDate: {
            label: "Dátum registrácie",
            asap: "Čo najskôr",
            specifically: "Ku konkrétnemu dátumu",
            toDay: "Spoločnosť žiadam zaregistrovať ku dňu"
          },

          contactInfo: {
            title: "Profil objednávateľa",
            subtitle: "Sledovanie stavu objednávky a jej detailu bude objednávateľ môcť vo Firmárni v sekcii Moja Firma. Zadajte, prosím, email, ktorý bude slúžiť ako prihlasovacie meno objednávateľa a vytvorte si k nemu heslo.",
            email: "E-mail (slúži ako prihlasovacie meno) *",
            password: "Heslo pre profil vo Firmárni *",
            phoneNumber: "Telefónne číslo *"
          },

          additionalInfo: {
            title: "Iné",
            reference: "Ako ste sa o nás dozvedeli? / Kto nás odporučil?",
            referencePlaceholder: "Kamarát, cez internet ...",
            voucher: "Zľavový kupón",
            comment: "Poznámka"
          }

        },

        krok5: {
          label: "Dokončenie objednávky",

          header: "Skontrolujte si správnosť zadaných údajov",
          subheader: "V prípade, ak ste zistili nesprávnosť alebo neúplnosť, chybu opravte alebo doplňte chýbajúci údaj a kliknite dole na tlačidlo DOKONČIŤ",

          infoOneDoc: {
            title: "<strong>Inovujeme a šetríme vaše peniaze</strong>",
            desc: "<strong>Eseročka na jeden podpis:</strong> Po zadaní a úhrade tejto objednávky vám zašleme <strong>dokument</strong>, ktorý bude obsahovať viacero dokumentov súčasne. <strong>Stačí jeden podpis</strong> u notára alebo elektronicky a vybavené.<br /><br /> \n" +
                "(Ak ste si nezvolili virtuálne sídlo, k tomuto dokumentu dostanete ešte jednu listinu navyše - pre vlastníka nehnuteľnosti.). Okrem tohto dokumentu dostanete aj splnomocnenie na zabezpečenie zmien v obchodnom registri. Tento dokument stačí podpísať obyčajne (bez notára).<br /> \n" +
                "Vo Firmárni neustále pracujeme na vylepšeniach, ktoré vám ušetria peniaze, čas a spoločné životné prostredie."
          },

          infoNotaryOrder: {
            title: "Cena za založenie SRO je <strong>1 €</strong> a môžete ju uhradiť teraz.",
            desc: "Notársky poplatok za registráciu firmy do obchodného registra je <strong>204,06 €</strong> a výzvu na jeho úhradu vám zašleme emailom obratom po uhradení sumy 1 €."
          },

          companyInfo: {
            label: "Údaje o spoločnosti",

            company: {
              label: "Obchodné meno",

              name: "Názov spoločnosti",
              registerDate: "Registrácia ku dňu",
              noDate: "Nezáleží"
            },

            place: {
              label: "Sídlo",

              type: "Typ sídla",
              own: "Vlastné",
              firmaren: "Poskytnuté portálom",
              street: "Ulica",
              streetNo: "Orientačné číslo",
              streetNo2: "Súpisné číslo",
              city: "Obec",
              zipCode: "PSČ",
              district: "Okres"
            }
          },

          companyLead: {
            label: "Údaje o vedení spoločnosti",

            amount: "Základné imanie",
            extent: "Rozsah splatenia",

            founders: {
              label: "Spoločníci",
              labelSingle: "Spoločník",

              header: "<strong>Spoločník</strong>",
              number: "- číslo",
              titleBefore: "Titul pred menom",
              name: "Meno a priezvisko",
              titleAfter: "Titul za menom",
              dateOfBirth: "Dátum narodenia",
              idNumber: "Rodné číslo",
              address: {
                street: "Ulica/Nám",
                streetNo: "Súpis./orient. číslo",
                city: "Obec",
                zipCode: "PSČ",
                country: "Štát"
              },

              amount: "Výška vkladu",
              share: "Podiel v spoločnosti",
              extent: "Rozsah splatenia vkladu",
              otherInfo: {
                header: "Údaje pre výpis z registra trestov",
                placeOfBirth: "Miesto narodenia",
                nationality: "Štátna príslušnosť",
                birthSurname: "Rodné priezvisko",
                formerSurname: "Predchádzajúce priezvisko",
                father: {
                  name: "Meno otca",
                  surname: "Priezvisko otca"
                },
                mother: {
                  name: "Meno matky",
                  surname: "Priezvisko matky",
                  birthSurname: "Rodné priezvisko matky"
                }
              }
            },

            executiveManagment: {
              label: "Konatelia",
              labelSingle: "Konateľ",

              header: "<strong>Konateľ</strong>",
              number: " - číslo",

              titleBefore: "Titul pred menom",
              name: "Meno a priezvisko",
              titleAfter: "Titul za menom",
              address: {
                street: "Ulica/Nám",
                streetNo: "Súpis./orient. číslo",
                city: "Obec",
                zipCode: "PSČ",
                country: "Štát"
              }
            }
          },

          businessSubjects: {
            title: "Predmety podnikania",
            totalPrice: "Cena spolu",
            place: "Sídlo"
          },

          otherInfo: {
            label: "Záverečné údaje",

            register: {
              info: "Faktúra bude vystavená na zakladanú spoločnosť",
              founder: "Faktúra bude vystavená na spoločníka - ",
              invoice: {
                name: "OBCHODNÉ MENO",
                ico: "IČO",
                dic: "DIČ",
                icdph: "IČ DPH",
                address: {
                  street: "Ulica/Nám",
                  streetNo: "Súpis./orient. číslo",
                  city: "Obec",
                  zipCode: "PSČ",
                  country: "Štát"
                }
              }
            },
          }
        }
      },

      zmena: {
        title: "Zmena S.R.O. online | Firmáreň",
        modal: {
          text: "Táto osoba je aj spoločník. Ak si ju prajete z tejto pozície odstrániť, je potrebné prejsť na formulár Zmeny v SRO. Ak chcete dokončiť tento formulár, kliknite na Zostať na formulári.",
          btn: "Chcem zmeny v SRO"
        },

        krok1: {
          label: "Zmeny vo firme",

          header: "Zmeny vo firme (s.r.o.)",
          headerSelf: "UROB SI SÁM: ",
          subheader: "Ako prvé zadajte IČO vašej firmy",
          selfSubheader: "Potom si vyberte zmenu, ktorú potrebujete v SRO urobiť. Systém Vám automaticky vygeneruje dokumenty a podrobný návod na zápis zmeny do obchodného registra.",
          ico: "IČO *",
          icoPlaceholder: "Napíšte IČO *",
          icoTitle: "Zadajte IČO vašej firmy",
          icoTooltip: "Napíšte IČO vašej firmy aby mohol formulár za vás automaticky vyplniť niektoré polia. IČO sa skladá z ôsmych čísiel",
          name: "Názov spoločnosti *",
          nameTitle: "Obchodné meno",

          title: "Čo si prajete zmeniť v spoločnosti?",

          changeBtn: "ZMENIŤ",
          cancelBtn: "ZRUŠIŤ ZMENY",

          selfTitle: "Urob si sám len za {{price}}€",
          selfText: "Zmenu adresy konateľa alebo spoločníka si môžete urobiť aj sami. Namiesto 140 € tak zaplatíte iba {{price}}€.",
          selfLink: "Prejsť na Urob si sám",

          selfChangeTitle: "Potrebujete spraviť aj iné zmeny?",
          selfChangeText: "Ak potrebujete vykonať zmeny, ktoré ste v tomto formulári<br />nenašli, prejdite na formulár služby ",
          selfChangeLink: "Zmeny v SRO",
          selfChangeNextText: " (už od 140€).",

          executors: {
            title: "Konatelia",

            btn: {
              change: "ZMENIŤ ÚDAJE",
              remove: "Zrušiť konateľa",
              add: "+ PRIDAŤ KONATEĽA",
              title: {
                change: "Zmeniť údaje konateľa",
                remove: "Odvolať konateľa",
                add: "Pridať konateľa",
              }
            },

            modal: {
              header: {
                add: "PRIDAŤ KONATEĽA",
                change: "ZMENIŤ OSOBNÉ ÚDAJE KONATEĽA"
              },
              btn: {
                change: "ULOŽIŤ"
              },
              removePerson: "VYMAZAŤ OSOBNÉ ÚDAJE",
              removeAddress: "VYMAZAŤ ADRESU",
              remove: "ODVOLAŤ KONATEĽA",
              date: "ODVOLAŤ OD",
              dateAdd: "VYMENOVAŤ OD"
            },

            samePerson: {
              title: "Táto osoba je aj spoločník.",
              text: "Prajete si ju odstrániť aj z pozície spoločníka?"
            }
          },

          clerks: {
            title: "Prokurista",

            btn: {
              change: "ZMENIŤ ÚDAJE",
              remove: "ODVOLAŤ",
              add: "+ PRIDAŤ PROKURISTU"
            },

            modal: {
              header: {
                add: "PRIDAŤ PROKURISTU",
                change: "ZMENIŤ OSOBNÉ ÚDAJE PROKURISTU"
              },
              btn: {
                change: "ULOŽIŤ",
                add: "Pridať prokuristu",
              },
              removePerson: "VYMAZAŤ OSOBNÉ ÚDAJE",
              removeAddress: "VYMAZAŤ ADRESU",
              remove: "ODVOLAŤ PROKURISTU",
              date: "ODVOLAŤ OD",
              dateAdd: "VYMENOVAŤ OD"
            }
          },

          endUser: {
            title: "Konečný užívateľ výhod",

            btn: {
              change: "ZMENIŤ ÚDAJE",
              add: "+ PRIDAŤ KUV",
              delete: "Vymazať KUV",
              remove: "Odvolať"
            },

            modal: {
              header: {
                add: "PRIDAŤ KONEČNÉHO UŽÍVATEĽA VÝHOD",
                change: "ZMENIŤ OSOBNÉ ÚDAJE KONEČNÉHO UŽÍVATEĽA VÝHOD",
                remove: "VYMAZAŤ KONEČNÉHO UŽÍVATEĽA VÝHOD"
              },
              btn: {
                change: "ULOŽIŤ",
                add: "Pridať",
                remove: "Vymazať"
              }
            }
          },

          transferCompany: {
            title: "Prevod firmy",
            btn: "Prevod celej firmy",
            modal: {
              title: "Prevod firmy",
              type: {
                all: "Prevod celej firmy",
                custom: "Prevod časti firmy"
              },
              typeAll: {
                one: "Prevod firmy na jednu osobu",
                more: "Prevod firmy na viac osôb"
              },
              founderExecutor: {
                label: "Bude nový spoločník súčasne aj konateľom?",
                yes: "Áno",
                no: "Nie"
              },
              newExecutor: {
                label: "Želáte si pridať ďalšieho konateľa, alebo nahradiť existujúcich?",
                new: "ďalšieho konateľa",
                change: "nahradiť existujúcich"
              }
            }
          },

          shareTransfer: {
            modal: {
              title: "Prevod podielu",
              assign: "Vyberte zo zoznamu nadobúdateľa",
              otherPerson: "Iná osoba",
              subject: "Predmet prevodu (Koľko, akú časť podielu prevádzame):",
              type: {
                all: "Všetko",
                custom: "Časť obchodného podielu"
              },
              euro: "Prevádza sa časť podielu (v eur)",
              percent: "Podiel v %",
              addAssign: "+ Pridať nadobudateľa",
              btn: "Previesť",
              price: "Cena za prevod obch. podielu *",
              currency: "Mena *",
              priceTooltip: "Predvolená cena 1 euro nemusí byť vo vašom prípade vhodná. Môžete si ju zmeniť podľa svojich potrieb. Cena môže byť zhodná s výškou obchodného podielu, zodpovedajúca trhovej hodnote obch. podielu, ale aj vo výške nula eur. Poraďte sa so svojim účtovníkom alebo daňovým poradcom (napr. na stránke uctovnici.sk)."
            }
          },

          companyName: {
            title: "Obchodné meno",
            subtitle: "Tento nástroj na overenie zhody obchodného mena je len orientačný. Obchodné meno nemôže byť totožné s už existujúcim. Názov si overte cez www.orsr.sk alebo www.rpo.sk. Čiarky, pomlčky, medzery, veľké/malé písmená a podobne nie sú dostatočným odlišovacím znakom.",
            btn: {
              title: {
                change: "Zmeniť obchodné meno firmy"
              }
            },
            newName: "Nové obchodné meno",
            newNameInputPlaceholder: "Napíšte nové obchodné meno",
            newNamePlaceholder: "Pôvodné obchodné meno: "
          },

          placeOfBusiness: {
            title: "Sídlo",
            btn: {
              title: {
                change: "Zmeniť sídlo firmy"
              }
            },
            type: {
              own: "Vlastné alebo prenajaté",
              firmaren: "Virtuálne sídlo"
            },

            address: {
              street: "Nová adresa sídla *",
              streetTooltip: "Zadajte názov ulice alebo verejného priestranstva (námestie) presne podľa občianskeho preukazu. Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce.",
              streetNoTooltip: "Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz).",
              streetPlaceholder: "Pôvodná adresa: ",
              streetNo: "Súpisné číslo / orientačné číslo *",
              streetNoPlaceholder: "Pôvodné číslo: ",
              city: "Obec *",
              cityPlaceholderInput: "Napr. Bratislava",
              cityPlaceholder: "Pôvodná obec: ",
              zipCode: "PSČ *",
              zipCodePlaceholder: "Pôvodné PSČ: ",
              radioTooltip: "Sídlo spoločnosti môžete mať vo vlastnej nehnuteľnosti alebo v nehnuteľnosti, ktorá síce nepatrí vám, ale jej vlastník vám udelil súhlas. Dokument súhlas vlastníka nehnuteľnosti vám vypracuje Firmáreň. Nájomná zmluva nie je povinná."
            },

            owner: "Meno a priezvisko vlastníka (ak je vlastníkom firma, tak jej obchodné meno) *"
          },

          subjects: {
            btn: {
              add: "+ PRIDAŤ PREDMETY PODNIKANIA",
              restore: "SPÄŤ"
            },

            title: "Predmet podnikania",

            newSubjects: "Aké nové predmety podnikania chcete pridať",

            oldSubjects: "Sem napíšte tie predmety podnikania, ktoré máte zapísane v obchodnom registri a chcete ich odtiaľ odstrániť.",

            comment: "Sem napíšte",
            commentTooltip: "Napíšte prosím:<br>1. Údaje týkajúce sa všetkých konateľov alebo, ak máte aj viazané/remeselné živnosti, aj údaje týkajúce sa zodpovednej osoby, ktorá bude tieto živnosti garantovať (ak sa osoba konateľa a zodpovedného zástupcu odlišuje):<ul><li>meno a priezvisko ako aj rodné priezvisko (prípadne predchádzajúce priezvisko, ak sa líši od rodného priezviska),</li><li>adresa trvalého bydliska,</li><li>dátum a miesto narodenia,</li><li>rodné číslo.</li></ul>2. Údaje potrebné pre vyžiadanie registra trestov živnostenským úradom:<br><ul><li>meno a priezvisko obidvoch rodičov,</li><li>rodné priezvisko matky.</li></ul>"
          },

          founders: {
            title: "Spoločníci ",

            changeHelpModal: {
              desc: "Zmenili ste <strong>osobné údaje spoločníka, ktorý spoločníkom naďalej zostáva</strong>, avšak s novými osobnými údajmi, ktoré ste zadali.<br /><br />Zámenu aktuálneho spoločníka za novú osobu urobíte cez tlačidlo Previesť/zrušiť podiel."
            },

            btn: {
              change: "ZMENIŤ ÚDAJE",
              remove: "ZRUŠIŤ SPOLOČNÍKA",
              transfer: "PREVIESŤ/ZRUŠIŤ PODIEL",
              transferCompany: "Prevod firmy",
              add: "+ PRIDAŤ SPOLOČNÍKA S PREVODOM",
              addWithCapital: "PRIDAŤ SPOLOČNÍKA CEZ NAVÝŠENIE ZÁKLADNÉHO IMANIA",
              title: {
                change: "Zmeniť osobné údaje spoločníka",
                remove: "Previesť celý podiel na inú osobu",
                transfer: "Previesť podiel alebo jeho časť na inú osobu",
                add: "Pridať spoločníka s prevodom",
                addWithCapital: "Pridať spoločníka cez navýšenie základného imania"
              }
            },
            modal: {
              fullName: "MENO / NÁZOV SPOLOČNOSTI *",
              ico: "IČO *",
              type: {
                label: "Spoločníkom spoločnosti je:",
                fo: "Fyzická osoba (človek)",
                po: "Právnická osoba (firma)"
              },
              header: {
                change: "ZMENIŤ OSOBNÉ ÚDAJE SPOLOČNÍKA"
              },
              btn: {
                change: "ULOŽIŤ"
              },
              addWithCapital: {
                header: {
                  addCapital: "PRIDAŤ SPOLOČNÍKA (BEZ PREVODU OBCHODNÉHO PODIELU)",
                  add: "PRIDAŤ SPOLOČNÍKA",
                  remove: "ZRUŠIŤ SPOLOČNÍKA",
                  subheaderRemove: "(Prevodom jeho podielu na inú osobu)",
                  edit: "PREVIESŤ OBCHODNÝ PODIEL ALEBO JEHO ČASŤ"
                },
                type: "Novým spoločníkom spoločnosti bude:"
              }
            }
          },

          capital: {
            title: "Základné imanie",
            radioMore: "Zvýšiť",
            radioLess: "Znížiť",
            labelMore: "Hodnota o ktorú chcete zvýšiť",
            labelLess: "Hodnota o ktorú chcete znížiť",
            btn: {
              title: {
                change: "Zmeniť základné imanie"
              }
            },
            founder: "Vyberte zo zoznamu SPOLOČNÍKA",
            comment: "Sem napíšte",
            commentPlaceholder: "Zmeny základného imania a vkladov spoločníkov, ak ste tak ešte neurobili v predchádzajúcom bode.",
            commentTooltip: "Napríklad zvýšenie alebo zníženie základného imania, zmena rozsahu splatenia základného imania alebo vkladu spoločníka, prevzatie vkladov pri zvýšení základného imania a podobne."
          },

          other: {
            title: "Iné zmeny",

            comment: "Sem napíšte",
            commentPlaceholder: "Sem napíšte napríklad: zmena konečného užívateľa výhod, likvidácia a podobne."
          },

          executorOtherInfo: {
            title: "Pridali ste nové predmety podnikania - živnosti",
            subtitle: "Aby si mohol živnostenský úrad vyžiadať za vás výpis z registra trestov online, bude potrebovať nasledujúce údaje o všetkých konateľoch:"
          },

          bdayIdnumber: {
            title: "Prevod podielu",
            subtitle: "Pre prevod obch. podielu vyžaduje obchodný register aj dátum narodenia a rodné číslo <strong>prevodcu</strong><br />(Tento údaj nie je verejný, a preto sme ho vo formulári pre vás nevedeli vopred vyplniť)."
          }
        },

        krok2: {
          label: "Fakturačné údaje",

          header: "Fakturačné údaje",
          subheader: "Na koho vystaví Firmáreň.sk faktúru za služby portálu?",

          zmluva: {
            firmaren: "Firmu som si zakladal cez Firmáreň",
            label: "Spoločenskú zmluvu/zakladateľskú listinu nemám k dispozícii a nežiadam jej uloženie do zbierky listín",
            tooltip: "Pre vykonanie zmeny v obchodnom registri nie je úplné znenie spoločenskej zmluvy / zakladateľskej listiny potrebné. Ak sa však takéto úplné znenie vyhotoví, zákon vyžaduje jeho uloženie do zbierky listín, ktorú spravuje obchodný register. Tento úkon môžeme urobiť za vás teraz, alebo ho môžete urobiť dodatočne aj sami."
          },

          file: {
            title: "Aktuálne znenie spoločenskej zmluvy/zakladateľskej listiny",
            subtitle: "Pre správnosť vyhotovenia zmeny je potrebné vychádzať z aktuálneho (posledného platného) znenia Spoločenskej zmluvy resp. Zakladateľskej listiny.",

            importFile: "Nahrať súbor",
            importFileTooltip: "V prípade, ak nám takýto dokument priložíte vo formáte .rtf alebo .doc alebo .docx, vyhotovíme za vás aj úplne znenie spoločenskej zmluvy resp. zakladateľskej listiny, ktoré je potrebné uložiť do zbierky listín na obchodnom registri pri každej zmene v spoločnosti."
          },

          additionalInfo: {
            title: "Iné",
            reference: "Ako ste sa o nás dozvedeli? / Kto nás odporučil?",
            voucher: "ZĽAVOVÝ KUPÓN",
            comment: "Poznámka",
            commentPlaceholder: "Sem napíšte poznámku"
          },

          contactInfo: {
            title: "Profil objednávateľa",
            subtitle: "Sledovanie stavu objednávky a jej detailu bude objednávateľ môcť vo Firmárni v sekcii Moja Firma. Zadajte, prosím, email, ktorý bude slúžiť ako prihlasovacie meno objednávateľa a vytvorte si k nemu heslo.",
            email: "E-mail (slúži ako prihlasovacie meno) *",
            password: "Heslo pre profil vo Firmárni *",
            phoneNumber: "Telefónne číslo *"
          }
        },

        krok3: {
          label: "Dokončenie objednávky",

          header: "Záverečné údaje pred dokončením",
          subheader: "V prípade, ak ste zistili nesprávnosť alebo neúplnosť, chybu opravte alebo doplňte chýbajúci údaj a kliknite dole na tlačidlo POKRAČOVAŤ.",

          subtitle1: "Požadované zmeny v spoločnosti",
          subtitle2: "Fakturačné údaje",
          subtitle3: "Poznámka",

          newExecutors: "Konatelia",
          newEndUsers: "Koneční užívatelia výhod",
          newClerks: "Prokuristi",
          newCapital: "Základné imanie",
          newCompanyName: "Obchodné meno",
          newSubjects: "Predmety podnikania",
          newSubjectsAdd: "Pridanie",
          newSubjectsRemove: "Zrušenie",
          newSubjectsOther: "Zmeny v predmetoch podnikania",
          newFounders: "Spoločníci",
          shareTransfer: {
            title: "Prevod podielu",
            transferor: "Prevodca",
            transfer: "Prevádza",
            assign: "Nadobúdateľ"
          },
          newOther: "Iné",
          newPlace: "Nové sídlo",

          paymentInfo: {
            sameCompany: "FAKTÚRA BUDE VYSTAVENÁ NA SPOLOČNOSŤ ",
            ico: "IČO",
            companyName: "Obchodné meno",
            dic: "DIČ",
            icDph: "IČ DPH",

            address: {
              street: "Ulica",
              streetNo: "Číslo",
              city: "Obec",
              zipCode: "PSČ",
              country: "Štát",
              kind: "Vlastník"
            }
          }
        }
      },

      readymade: {
        krok1: {
          label: "Ready made",
          header: "Readymade spoločnosti",
          info: "Tu sú detaily našej hotovej firmy, ktorú idete kupovať. <strong>Vyberte, čo zmeníte a čo si ponecháte</strong> (napríklad obchodné meno, predmety podnikania a pod.). <strong>Zmena konateľa, spoločníka a konečného užívateľa je povinná.</strong>"
        },

        krok2: {
          label: "Fakturačné údaje"
        },

        krok3: {
          label: "Dokončenie objednávky"
        }
      }
    },

    rozsirenieZivnosti: {
      modal: {
        btn: "Chcem zmeny v živnosti",
        text: "Vaše aktuálne obchodné meno neobsahuje dodatok za menom a priezviskom. Ak chcete popri pridaní živnosti aj zmeniť obchodné meno (pridať dodatok), zadajte najprv objednávku na zmeny v živnosti."
      },
      krok1: {
        guide: {
          title: "Návod na rozšírenie živnosti"
        }
      },
      krok2: {
        subheader: "(Pre rozšírenie živnosti je potrebné zadať rovnaké údaje ako pri založení živnosti. Pridávame ďalšie živnosti, pričom IČO, DIČ a ostatné údaje zostanú nezmenené.)",

        registerDate: {
          description: "Živnosť chcem rozšíriť od:",
        }
      },
      krok3: {
        businessSubjects: {
          title: "Pridané predmety podnikania"
        }
      }
    },

    zalozenieZivnosti: {
      nameDay: {
        title: "<strong>Založenie živnosti iba za <span class=\"modal-header-red\">1€</span></strong><br />dnes pre: <strong>{{name}}</strong>",
        subtitle: "Meninová akcia je darček pre každého, kto si dnes, v deň svojich menín, zakladá živnosť.",
        desc: "Správny poplatok za viazanú alebo remeselnú živnosť vo výške 7,50 € nie je súčasťou zľavy.",
        descStep1: "Cena sa zníži po zadaní mena, v 2. kroku objednávky.<br />Správny poplatok za viazanú alebo remeselnú živnosť vo výške 7,50 € nie je súčasťou zľavy."
      },
      landing: {
        title: "POHODLNÉ ZALOŽENIE ŽIVNOSTI za 1 EURO",
        subtitle: "Nestrácajte čas ani peniaze a založte si živnosť online! Založenie živnosti online bez nutnosti tráviť dlhé hodiny na úradoch a minimálne o polovicu lacnejšie ako založenie živnosti klasickým spôsobom. Nepotrebujete ani OP s elektronickým podpisom (ak ho však máte, môžete ho využiť).",
        btnCreate: "Chcem si založiť živnosť",
        btnCreateInfo: "Zaberie to len 10 minút",
        btnMoreInfo: "Viac informácií",
        howWork: {
          title: "Ako to funguje",
          createTitle: "Vytvorenie objednávky",
          createInfo: "Kliknite na zelené tlačítko nižšie “Chcem si založiť živnosť.” Dostanete sa k formuláru, kde si vyberiete, v čom chcete podnikať a vyplníte ostatné potrebné informácie. Vytvorenie objednávky založenia živnosti trvá len niekoľko minút.",
          sendTitle: "Podpis",
          sendInfo: "Po vytvorení objednávky na založenie živnosti vám obratom zašleme email s vyplnenou žiadosťou. Stačí ju len podpísať, naskenovať a zaslať nám ju späť. A ak máte občiansky preukaz s čipom, nemusíte ani tlačiť a skenovať.",
          payTitle: "Platba",
          payInfo: "Kolky už viac nepotrebujete - vyberte si spôsob online platby. Po nej vašu žiadosť o otvorenie živnosti vybavíme, pričom podnikať budete môcť už na druhý pracovný deň. Živnostenský list obdržíte približne do troch pracovných dní emailom v elektronickej forme.",
          registerTitle: "Registrácia živnosti",
          registerInfo: "S registráciou na živnostenskom úrade automaticky zabezpečíme aj registráciu na daňovom úrade (kartička DIČ) a vo vašej zdravotnej poisťovni. Bez poplatku navyše.",
        },
        howMuch: {
          title: "Koľko zaplatím za založenie živnosti cez Firmáreň?",
          price: "iba 19 €",
          priceTb: "iba 1 €",
          textTb: "(vďaka Tatra banke)",
          price6free: "Cena pri 6 voľných živnostiach",
          price10free: "Cena pri 10 voľných živnostiach",
          moreFree: "(Môžete si ich vybrať aj viac)",
          specialTrade: "Viazaná / remeselná činnosť",
          office: "NA ÚRADE",
          firmaren: "CEZ FIRMÁREŇ",
          save: "KOĽKO UŠETRÍTE",
          unlimited: "Neobmedzený počet v cene",
          underInfo: "<b>Cena 19€ je konečná</b> a zahŕňa <b>neobmedzený počet voľných živností</b> (nie len 10), registráciu živnosti na daňovom úrade (DIČ) a zdravotnej poisťovni a živnostenský list v papierovej<br/>aj elektronickej podobe."
        },
        advantages: {
          title: "Výhody založenia živnosti online",
          timeTitle: "Šetríte čas",
          timeInfo: "Vybavenie živnosti online trvá len niekoľko minút, nemusíte si brať dovolenku a tráviť hodiny behaním po úradoch. Na druhý pracovný deň môžete podnikať a živnostenský list dostanete elektronicky približne do troch dní.",
          moneyTitle: "Šetríte peniaze",
          moneyInfo: "Založením živnosti online ušetríte minimálne 50% nákladov na registráciu na živnostenskom úrade. V cene je aj neobmedzený počet voľných živností, neplatíte tak už nič navyše.",
          nervesTitle: "Šetríte si nervy",
          nervesInfo: "Pre klasické založenie živnosti musíte navštíviť živnostenský úrad v mieste vášho bydliska a len v úradných hodinách. Online živnosť vybavíte kdekoľvek a kedykoľvek.",
          homeTitle: "Z pohodlia domova",
          homeInfo: "Vďaka registrácií živnosti online nemusíte navštíviť žiaden úrad. Všetky potrebné dokumenty vám budú zaslané elektronicky.",
          payTitle: "Jednuduchá platba",
          payInfo: "Už viac nepotrebujete platiť kolkami. Zaplatiť môžete jednoducho platobnou kartou alebo pomocou internet bankingu z pohodlia domova.",
          moreTitle: "Ďalšie výhody",
          moreInfo: "Po založení živnosti naše služby nekončia: Firmáreň vám ponúka jedinečnú možnosť upravovať svoju živnosť elektronicky, bez papiera, a to dokonca aj bez elektronického podpisu. Rozšírenie, prerušenie alebo iné zmeny v živnosti po pár kliknutiach a za zvýhodnené ceny. Budú sa vám páčiť aj ďalšie bonusy.",
        },
        satisCustomers: "Spokojní zákazníci",
        btnMoreCustomers: "Pozri ďalšie",
        statsBefore: "Už",
        statsAfter: "podnikateľov si otvorilo živnosť cez Firmáreň",
        waste: "NESTRÁCAJTE ČAS ANI PENIAZE, ZALOŽTE SI ŽIVNOSŤ ONLINE!",
        unbinding: "Nezáväzná objednávka"
      },
      title: "Založenie živnosti online za 1 eur | Firmáreň.sk",

      krok1: {
        label: "Predmet podnikania",

        header: "V čom chcete podnikať?",
        subheader: "Ako prvú zadajte hlavnú činnosť podnikania.",

        placeholder: "Sem vpíšte predmet, činnosť, kľúčové slovo (napr. Obchodná čínnosť)",
        btnHelp: "Ako si vybrať živnosť?",
        or: "Alebo",
        btnList: "Vyberte zo zoznamu",
        continue: "Pokračovať",
        save: "Uložiť a pokračovať neskôr",

        suggestedGroups: {
          title: "Skupiny predmetov podnikania pre niektoré povolania"
        },

        guide: {
          title: "Návod na založenie živnosti",
          order: {
            title: "Objednajte",
            text: "Vyberte si živnosti, v ktorých chcete podnikať a zadajte túto objednávku."
          },
          sign: {
            title: "Podpíšte",
            text: "Obratom vám systém vygeneruje vyplnenú žiadosť, ktorú stačí podpísať, naskenovať a zaslať nám emailom."
          },
          send: {
            title: "Pošlite",
            text: "Na druhý pracovný deň od aktivácie vašej objednávky môžete podnikať." // todo: missing rest of the text
          }
        },
        popup: {
          firstSubject: "Pridané ako hlavný predmet podnikania",
          anotherSubjects: "Pridané",
          removeSubject: "Odstránené z vybratých predmetov podnikania"
        },
        title: {
          add: "Pridať k výbratým predmetom podnikania kliknutím",
          remove: "Odstrániť predmet podnikania kliknutím"
        }
      },

      krok2: {
        label: "Podnikateľské údaje",

        header: "Vyplňte údaje o podnikateľovi",
        subheader: "* označené polia sú povinné",

        changeLanguage: {
          title: "Zahraničná osoba",
          text: "<strong>Nakoľko ide o zahraničnú osobu,</strong> budete presmerovaný/á na formulár pre zahraničnú osobu v anglickom jazyku. Vysvetlenie: Zahraničná osoba je podľa zákona osoba s trvalým bydliskom mimo územia SR.<br /><br /><strong>You are a foreign person,</strong> therefore other form will be used, in english language. Note: According to law, a foreign person is a person with permanent residence outside Slovak Republic.<div class='line_border'></div><strong>Upozornenie:</strong> pre cudzinca s trvalým bydliskom mimo územia SR je <strong>cena 60 eur (namiesto 19 eur).</strong><br />Prečo sa cena mení: Vybavenie živnosti pre cudzincov s trvalým bydliskom mimo územia SR je náročnejšie na úkony a čas.<br /><br /><strong>Please note:</strong> The price for residents outside Slovakia is <strong>€60 instead of €19.</strong><br />Why the price changed: There is more to do in process of trade opening for non residents."
        },

        personalInfo: {
          title: "Osobné údaje",
          gender: "Pohlavie *",
          titleBefore: "Titul pred menom",
          name: "Meno *",
          surname: "Priezvisko *",
          titleAfter: "Titul za menom",
          address: {
            street: "Adresa trvalého bydliska *",
            streetTooltip: "Zadajte názov ulice alebo verejného priestranstva (námestie) presne podľa občianskeho preukazu. Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce.",
            streetNo: "Súpisné číslo / orientačné číslo *",
            streetNoPrefixTooltip: "Pole súpisné číslo je nepovinné",
            streetNoTooltip: "Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz).",
            city: "Obec *",
            cityPlaceholder: "Napr. Bratislava",
            zipCode: "PSČ *",
            country: "Štát *"
          },
          phoneNumber: "Telefónne číslo *",
          phoneNumberPlaceholder: "9xx xxx xxx",
          phoneNumberTooltip: "Pre účel kontaktovania klienta v prípade nejasností alebo opravy prípadných chýb.",
          dateOfBirth: "Dátum narodenia *",
          dayPlaceholder: "deň",
          monthPlaceholder: "mesiac",
          yearPlaceholder: "rok",
          idNumber: "Rodné číslo",
          foreigner: {
            passportNumber: "Číslo pasu alebo ID *",
            nationality: "Štátna príslušnosť *",
            street: "Adresa prechodného pobytu v SR",
            streetNo: "Súpisné číslo / orientačné číslo",
            zipCode: "PSČ",
            city: "Obec",
            mandate: "Splnomocnenec na doručovanie",
            fo: "Fyzická osoba s trvalým pobytom v SR",
            foTooltip: "Zahraničná osoba musí živnostenskému úradu oznámiť osobu s trvalým bydliskom v SR, ktorá bude v jej mene preberať úradné zásielky v prípade, ak ich nebude možné doručiť priamo zahraničnej osobe. - Uveďte osobu, ktorej dôverujete.",
            po: "Právnická osoba so sídlom v SR",
            poTooltip: "Zahraničná osoba musí živnostenskému úradu oznámiť slovenskú osobu, ktorá bude v jej mene preberať úradné zásielky v prípade, ak ich nebude možné doručiť priamo zahraničnej osobe. Takýmto splnomocnencom môže byť aj právnická osoba so sídlom v SR (napríklad spoločnosť s ručením obmedzeným, občianske združenie, a podobne). - Uveďte osobu, ktorej dôverujete.",
            firmaren: "Firmáreň (+ 40 eur)",
            firmarenTooltip: "Zahraničná osoba musí živnostenskému úradu oznámiť slovenskú osobu, ktorá bude v jej mene preberať úradné zásielky v prípade, ak ich nebude možné doručiť priamo zahraničnej osobe. Takýmto splnomocnencom môže byť aj Firmáreň. Táto služba je spoplatnená sumou 40 eur.",
            ico: "IČO *",
            companyName: "OBCHODNÉ MENO *"
          }
        },

        registerDate: {
          description: "Živnosť chcem založiť od:",
        },

        contractorInfo: {
          title: "Údaje o podnikateľovi",
          name: "Obchodné meno",
          ico: "IČO",
          insuranceCompany: "Zdravotná poisťovňa *",
          insuranceCompanyPlaceholder: "Vyberte poisťovňu",
          insuranceCompanyTooltip: "Vyberte si zdravotnú poisťovňu v SR, kde ste zaregistrovaný/á. Povinnosť oznámiť vznik podnikania povinnosti vybavíme za vás. Aj zahraničná fyzická osoba (mimo EÚ a OECD) je povinná mať zdravotnú poisťovňu v SR.",
          place: "Miesto podnikania",
          placeSame: "Totožné s trvalým bydliskom",
          placeFirmaren: "Virtuálne sídlo",
          placeFirmarenBold: "(od 7€/mesačne)",
          placeOther: "Na inej adrese",
          placeOtherAddress: "ADRESA *",
          placeOtherInfo: "Ak sa miesto podnikania odlišuje od trvalého bydliska, je potrebné preukázať oprávnenie užívať nehnuteľnosť buď nájomnou zmluvou alebo súhlasom vlastníka nehnuteľnosti (tento dokument vám môžeme vypracovať a zaslať emailom).",
          originalSendTo: "Informácia: Od 1. januára 2020 sa vydané osvedčenia/potvrdenia nezasielajú poštou, ale iba elektronicky.",
          originalHome: "Trvalé bydlisko podnikateľa",
          originalWorkPlace: "Miesto podnikania",
          originalOther: "Iné",
          originalOtherPlaceholder: "Napríklad živnostenský úrad alebo kancelária Firmárne",
          originalOtherTooltip: "Sem uveďte, ak si chcete dokument vyzdvihnúť napr. na živnostenskom úrade alebo v kancelárii Firmárne.",
          nameTooltip: "Obchodné meno živnostníka môže, okrem mena a priezviska, obsahovať aj dodatok. Napríklad: Jozef Mrkvička – Zelovoc.",
          icoTooltip: "Vyplňte ak si rozširujete živnosť, alebo ak ste už niekedy mali živnosť s prideleným IČOm.",
          tooltip: "Virtuálne sídlo podľa vášho výberu poskytuje partner portálu firmáreň.sk.",
          trvalyPobytTooltip: "Ak je miesto podnikania zhodné s trvalým bydliskom podnikateľa, nevyžaduje sa žiadne iné potvrdenie. Táto možnosť neplatí pre zahraničnú osobu (mimo SR), pretože miesto podnikania musí byť len na území SR.",
          inaAdresaTooltip: "Miesto podnikania môže byť na akejkoľvek adrese v SR, avšak pre jeho preukázanie je potrebné predložiť písomný súhlas vlastníka nehnuteľnosti s úradne osvedčeným podpisom (dokument klientovi zašle Firmáreň) alebo nájomnú zmluvu na podnikateľské účely.",
          businessAddress: {
            streetTooltip: "Zadajte názov ulice alebo verejného priestranstva (námestie). Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce."
          }
        },

        otherInfo: {
          title: "Údaje pre výpis z registra trestov",
          subtitle: "Na základe nasledovných údajov si živnostenský úrad sám vyžiada výpis z registra trestov občana SR priamo z Generálnej prokuratúry SR. Týmto výpisom sa preukazuje bezúhonnosť živnostníka podľa živnostenského zákona.",
          placeOfBirth: "Miesto narodenia *",
          nationality: "Štátna príslušnosť *",
          birthSurname: "Rodné priezvisko *",
          formerSurname: "Predchádzajúce priezvisko",
          father: {
            name: "Meno otca *",
            surname: "Priezvisko otca *"
          },
          mother: {
            name: "Meno matky *",
            surname: "Priezvisko matky *",
            birthSurname: "Rodné priezvisko matky *"
          },
          nationalityPlaceholder: "Napr. Slovenská",
          addressStreet: "Adresa"
        },

        contactInfo: {
          title: "Profil objednávateľa",
          subtitle: "Sledovanie stavu objednávky a jej detailu bude objednávateľ môcť vo Firmárni v sekcii Moja Firma. Zadajte, prosím, email, ktorý bude slúžiť ako prihlasovacie meno objednávateľa a vytvorte si k nemu heslo.",
          email: "E-mail (slúži ako prihlasovacie meno) *",
          password: "Heslo pre profil vo Firmárni *",
          phoneNumber: "Telefónne číslo *"
        },

        additionalInfo: {
          title: "Iné (zľava)",
          reference: "Ako ste sa o nás dozvedeli? / Kto nás odporučil?",
          voucher: "Zľavový kupón",
          comment: "Poznámka",
          commentPlaceholder: "Napr. konkrétny dátum vzniku živnostenského oprávnenia."
        }

      },

      krok3: {
        label: "Dokončenie objednávky",

        header: "Skontrolujte si správnosť zadaných údajov",
        subheader: "V prípade, ak ste zistili nesprávnosť alebo neúplnosť, chybu opravte alebo doplňte chýbajúci údaj a kliknite dole na tlačidlo DOKONČIŤ",

        personalInfo: {
          title: "Osobné údaje",

          email: "E-mail",
          name: "Meno a priezvisko",
          dateOfBirth: "Dátum narodenia",
          idNumber: "Rodné číslo",
          address: "Adresa trvalého bydliska",
          city: "Obec a PSČ",
          country: "Štát",
          phoneNumber: "Telefónne číslo"
        },

        contractorInfo: {
          title: "Živnosť",

          name: "Obchodné meno",
          ico: "IČO",
          place: "Miesto podnikania",
          insuranceCompany: "Zdravotná poisťovňa",
          originalSendTo: "Originál osvedčenia o živnostnenskom oprávnení žiadam zaslať na adresu"
        },

        otherInfo: {
          title: "Údaje pre výpis z registra trestov",

          gender: "Pohlavie",
          placeOfBirth: "Miesto narodenia",
          nationality: "Štátna príslušnosť",
          formerSurname: "Predchádzajúce priezvisko",
          birthSurname: "Rodné priezvisko",
          fatherName: "Meno a priez. otca",
          motherName: "Meno a priez. matky",
          motherBirthSurname: "Rodné priezvisko matky"
        },

        businessSubjects: {
          title: "Predmety podnikania",
          totalPrice: "Cena spolu"
        },

        additionalInfo: {
          title: "Iné",
          voucher: "Zľavový kupón",
          comment: "Poznámka"
        }
      },

      medzikrok: {
        header: "Možno budete potrebovať",
        description: "Vybrali sme pre vás najvýhodnejšie ponuky služieb na trhu,<br> ktoré ako podnikateľ môžete potrebovať."
      }
    },

    zmenaZivnosti: {
      title: "Zmena živnosti online | Firmáreň",
      modal: {
        text: "Pridanie predmetu podnikania nie je možné vykonať súčasne s inými zmenami v živnosti. Pre zobrazenie formuláru na rozšírenie živnosti klinite na tlačidlo Chcem si rozšíriť živnosť nižšie. Ak chcete dokončiť tento formulár na zmeny v živnosti, kliknite na Zostať na formulári.",
        btn: "Chcem si rozšíriť živnosť"
      },
      krok1: {
        label: "Podnikateľské údaje a zmeny v živnosti",

        header: "Ako prvé zadajte IČO vašej živnosti",
        subheader: "IČO sa skladá z ôsmich čísiel",

        icoPlaceholder: "Napíšte IČO vašej živnosti *",
        icoTooltip: "Napíšte IČO vašej firmy aby mohol formulár za vás automaticky vyplniť niektoré polia. IČO sa skladá z ôsmych čísiel.",
        errorIco: "Zadané IČO neexistuje",

        personalInfo: {
          actualInfo: "Aktuálne údaje o živnostníkovi",
          titleBefore: "Titul pred menom",
          name: "Meno *",
          surname: "Priezvisko *",
          titleAfter: "Titul za menom",
          address: {
            street: "Adresa trvalého bydliska *",
            streetTooltip: "Zadajte názov ulice alebo verejného priestranstva (námestie) presne podľa občianskeho preukazu. Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce.",
            streetNo: "Súpisné číslo / orientačné číslo *",
            streetNoTooltip: "Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz).",
            city: "Obec *",
            cityPlaceholder: "Napr. Bratislava",
            zipCode: "PSČ *",
            country: "Štát *"
          },
          phoneNumber: "Telefónne číslo *",
          phoneNumberPlaceholder: "9xx xxx xxx",
          phoneNumberTooltip: "Pre účel kontaktovania klienta v prípade nejasností alebo opravy prípadných chýb.",
          dateOfBirth: "Dátum narodenia *",
          dayPlaceholder: "deň",
          monthPlaceholder: "mesiac",
          yearPlaceholder: "rok",
          idNumber: "Rodné číslo *",
          insuranceCompany: "Zdravotná poisťovňa *",
          insuranceCompanyTooltip: "Vyberte zo zoznamu",
          dic: "DIČ",
          icDph: "IČ DPH"
        },

        sendTo: {
          title: "Potvrdenie o vykonaní zmien žiadam doručiť na adresu",
          home: "Trvalé bydlisko podnikateľa",
          workPlace: "Miesto podnikania",
          other: "Iná adresa",
          street: "Adresa trvalého bydliska *",
          streetTooltip: "Zadajte názov ulice alebo verejného priestranstva (námestie) presne podľa občianskeho preukazu. Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce.",
          streetNo: "Súpisné číslo / orientačné číslo *",
          streetNoTooltip: "Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz).",
          city: "Obec *",
          cityPlaceholder: "Napr. Bratislava",
          zipCode: "PSČ *",
          country: "Štát *"
        },

        registerDate: {
          title: "Dátum",
          description: "Živnosť chcem zmeniť od:",
          descriptionPause: "Živnosť chcem pozastaviť od:",
          descriptionDestroy: "Od tohto dňa už nechcem byť podnikateľom:",
          descriptionRenew: "Živnosť chcem obnoviť od:",
          since: "Od",
          until: "Do",
          asap: "Čo najskôr",
          specifically: "Ku konkrétnemu dátumu",
          sinceUntil: "Od Do"
        },

        contactInfo: {
          title: "Profil objednávateľa",
          subtitle: "Sledovanie stavu objednávky a jej detailu bude objednávateľ môcť vo Firmárni v sekcii Moja Firma. Zadajte, prosím, email, ktorý bude slúžiť ako prihlasovacie meno objednávateľa a vytvorte si k nemu heslo.",
          email: "E-mail (slúži ako prihlasovacie meno) *",
          emailPlaceholder: "@",
          password: "Heslo pre profil vo Firmárni *",
          phoneNumber: "Telefónne číslo *"
        },

        additionalInfo: {
          title: "Iné",
          reference: "Ako ste sa o nás dozvedeli? / Kto nás odporučil?",
          comment: "Poznámka",
          commentPlaceholder: "Napríklad dátum, od ktorého chcete zmenu a podobne."
        },

        changes: {
          title: "Čo si prajete zmeniť v živnosti?",
          renewTitle: "Od kedy si prajete živnosť obnoviť?"
        },

        newName: {
          label: "Nové meno, titul, obchodné meno",

          titleBefore: "Nový titul pred",
          name: "Nové meno *",
          namePlaceholder: "Pôvodné meno: ",
          surname: "Nové priezvisko *",
          surnamePlaceholder: "Pôvodné priezvisko: ",
          titleAfter: "Nový titul za",

          companyName: "Nové obchodné meno",
          companyNamePlaceholder: "Pôvodné obchodné meno a dodatok: ",

          importFileLabel: "Priložte súbor, z ktorého vyplýva táto zmena",
          importFileSublabel: "(napríklad scan sobášneho listu, diplomu alebo iného dokumentu)",

          importFile: "Nahrať súbor",
          importFileTooltip: "Priložte súbor. Ak máte viac súborov ako jeden, zašlite nám ich emailom.",
          importFilePlaceholder: "Nie je vybratý žiadny súbor"
        },

        address: {
          label: "Adresa",
          subLabel: "(bydliska, podnikania, prevádzkarne)"
        },

        newAddress: {
          label: "Nová adresa trvalého bydliska",

          street: "Adresa *",
          streetTooltip: "Zadajte názov ulice alebo verejného priestranstva (námestie) presne podľa občianskeho preukazu. Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce.",
          streetPlaceholder: "Pôvodná adresa: ",
          streetNo: "Súpisné číslo / orientačné číslo *",
          streetNoTooltip: "Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz).",
          streetNoPlaceholder: "Pôvodné číslo: ",
          city: "Obec *",
          cityPlaceholder: "Pôvodná obec: ",
          zipCode: "PSČ *",
          zipCodePlaceholder: "Pôvodné PSČ: ",

          importFileLabel: "Priložte súbor, z ktorého vyplýva táto zmena",
          importFileSublabel: "(napríklad scan sobášneho listu, diplomu alebo iného dokumentu)",

          importFile: "Nahrať súbor",
          importFilePlaceholder: "Nie je vybratý žiadny súbor"
        },

        newAddressPlaceOfBussiness: {
          label: "Nová adresa miesta podnikania (sídla)",

          street: "Adresa *",
          streetTooltip: "Zadajte názov ulice alebo verejného priestranstva (námestie) presne podľa občianskeho preukazu. Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce.",
          streetPlaceholder: "Pôvodná adresa: ",
          streetNo: "Súpisné číslo / orientačné číslo *",
          streetNoTooltip: "Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz).",
          streetNoPlaceholder: "Pôvodné číslo: ",
          city: "Obec *",
          cityPlaceholder: "Pôvodná obec: ",
          zipCode: "PSČ *",
          zipCodePlaceholder: "Pôvodné PSČ: ",

          whichOwner: "Kto je vlastníkom nehnuteľnosti kde bude nové miesto podnikania (sídlo)?",

          importFileLabel: "Priložte súbor, z ktorého vyplýva táto zmena",
          importFileSublabel: "(napríklad scan sobášneho listu, diplomu alebo iného dokumentu)",

          importFile: "Nahrať súbor",
          importFilePlaceholder: "Nie je vybratý žiadny súbor"
        },

        newAddressPlace: {
          label: "Nová adresa prevádzkarne",

          street: "Adresa *",
          streetTooltip: "Zadajte názov ulice alebo verejného priestranstva (námestie) presne podľa občianskeho preukazu. Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce.",
          streetPlaceholder: "Pôvodná adresa: ",
          streetNo: "Súpisné číslo / orientačné číslo *",
          streetNoTooltip: "Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz).",
          streetNoPlaceholder: "Pôvodné číslo: ",
          city: "Obec *",
          cityPlaceholder: "Pôvodná obec: ",
          zipCode: "PSČ *",
          zipCodePlaceholder: "Pôvodné PSČ: ",

          whichSubject: "Ktorý predmet podnikania sa bude v tejto prevádzkarni vykonávať?",

          importFileLabel: "Priložte súbor, z ktorého vyplýva táto zmena",
          importFileSublabel: "(napríklad scan sobášneho listu, diplomu alebo iného dokumentu)",

          importFile: "Nahrať súbor",
          importFilePlaceholder: "Nie je vybratý žiadny súbor"
        },

        destroyOrPause: {
          label: "Zrušenie alebo pozastavenie živnosti",

          request: "Žiadam o",
          change: "Zmeny v živnosti",
          destroy: "Zrušenie živnosti",
          pause: "Pozastavenie živnosti",
          renew: "Obnovenie živnosti",

          allSubjects: "Vo všetkých predmetoch podnikania",
          someSubjects: "Iba u nasledovných predmetov podnikania",
          someSubjectsPlaceholder: "Sem napíšte zoznam predmetov podnikania"
        },
        changeSubjects: "Pridanie predmetu podnikania (Rozšírenie živnosti)",
        other: {
          label: "Iná zmena",
          subLabel: "(Napríklad predĺženie pozastavenej živnosti)",

          change: "Iná zmena",
          changePlaceholder: "Napríklad predĺženie pozastavenej živnosti"
        }

      },

      krok2: {
        label: "Dokončenie objednávky",

        header: "Skontrolujte si správnosť zadaných údajov",
        subheader: "V prípade, ak ste zistili nesprávnosť alebo neúplnosť, chybu opravte alebo doplňte chýbajúci údaj a kliknite dole na tlačidlo DOKONČIŤ.",

        newName: {
          label: "Nové meno, priezvisko alebo titul",
          titleBefore: "Titul pred menom",
          name: "Meno a priezvisko",
          titleAfter: "Titul za menom",

          companyName: "Dodatok za menom"
        },

        newAddress: {
          label: "Nová adresa trvalého bydliska",
          street: "Ulica",
          city: "Obec a PSČ"
        },
        newAddressPlaceOfBussiness: {
          label: "Nová adresa miesta podnikania",
          street: "Ulica",
          city: "Obec a PSČ",
          whichOwner: "Vlastník nehnuteľnosti"
        },
        newAddressPlace: {
          label: "Nová adresa prevádzkarne",
          street: "Ulica",
          city: "Obec a PSČ",
          whichSubject: "predmety podnikania"
        },

        destroyOrPause: {
          label: "Zrušenie alebo pozastavenie živnosti",

          request: "Žiadam o",
          subjects: "V predmetoch"
        },

        other: {
          label: "Iná zmena",
          change: "Iná zmena"
        },
      }

    },

    zalozenieOZ: {
      title: "Založenie občianskeho združenia online | Firmáreň",

      actualInfoTitle: "Aktuálna informácia",
      actualInfoText: "Každoročne, v mesiacoch november až február zvykne Ministerstvo vnútra SR s registráciami občianskych združení významne meškať. Zdržanie môže v niektorých prípadoch prekročiť aj mesiac a z našej strany to, žiaľ, nedokážeme ovplyvniť.<br /><br />Považujeme za správne informovať vás o tejto situácii ešte pred zaplatením objednávky.",

      krok1: {
        label: "Názov, sídlo, ciele a orgány",

        headerPrefix: "Urob si sám: ",
        header: "Zvoľte si názov občianského združenia",
        subheader: "Zadajte údaje o občianskom združení a všetko ostatné vybavíme do dvoch týždňov za vás.",
        subheaderSelf: "Dokážete to aj bez pomoci. Zadajte údaje o novom občianskom združení a počkajte na set dokumentov a návodov ako na to.",

        namePlaceholder: "Napíšte názov nového občianskeho združenia *",
        nameTooltip: "Názov nesmie byť zhodný s už existujúcim názvom právnickej osoby. Nemusí však obsahovať slová 'občianske združenie', pretože môže ísť o zväz, klub, asociáciu, atď. a môžete ho formulovať napríklad takto: Občianske združenie Letci v oblakoch, Vinársky spolok, Zväz bojovníkov UNIKA, KLD klub, Kominári, o. z., a pod.",

        placeOfBusiness: {
          title: "Sídlo",

          type: {
            own: "Vlastné alebo prenajaté",
            firmaren: "Virtuálne sídlo"
          },

          address: {
            street: "ADRESA *",
            streetTooltip: "Zadajte názov ulice alebo verejného priestranstva (námestia). Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce.",
            streetNo: "Súpisné číslo / orientačné číslo *",
            streetNoTooltip: "Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz).",
            city: "Obec *",
            cityPlaceholder: "Napr. Bratislava",
            zipCode: "PSČ *"
          }
        },

        goals: {
          title: "Ciele",
          placeholder: "Sem vpíšte cieľ alebo kľúčové slovo (napr. šport)",
          general: "Všeobecné ciele",
          generalPlaceholder: "Ciele občianskeho združenia",
          generalTooltip: "Napíšte činnosti, ktorým sa bude občianske združenie venovať. Napríklad:  výchova, vzdelávanie, regionálny rozvoj, ochrana životného prostredia, charita, šport a podobne. Pokojne cieľ uveďte čo najširšie a svojimi slovami. Ak to bude potrebné, cieľ upravíme a predložíme vám ho na odsúhlasenie.",

          info: "Okrem toho budete mať v stanovách vášho združenia fixne uvedený tento text:",
          link: "Pozrieť text",
          tooltip: "Za účelom naplnenia vyššie uvedených cieľov môže občianske združenie najmä:<br /><br /><ul>" +
          "<li>organizovať a zabezpečovať semináre a školenia, výchovno-vzdelávacie, športové, spoločenské, kultúrne aktivity a podujatia, a to na lokálnej, národnej i medzinárodnej úrovni, vrátane zabezpečovania účasti svojich členov na podobných podujatiach,</li>" +
          "<li>realizovať publikačné a konzultačné aktivity,</li>" +
          "<li>presadzovať legislatívne zmeny na regionálnej a celoštátnej úrovni ako aj</li>" +
          "<li>uskutočňovať iné podporné aktivity.</li></ul>",

          popup: {
            isSelected: "Tento cieľ už bol pridaný.",
            add: "Pridané",
            remove: "Odstránené z vybratých cieľov"
          },
          titleBtn: {
            add: "Pridať k výbratým cieľom kliknutím",
            remove: "Odstrániť cieľ kliknutím"
          },
          specific: "Špecifický cieľ - EIA"
        },

        otherInfo: {
          title: "Orgány",

          status: {
            title: "Štatutárny orgán *",
            chairman: "Predseda",
            headmaster: "Riaditeľ",
            president: "Prezident",
            other: "Iné",
            tooltip: "Štatutárny orgán zastupuje združenie navonok, podpisuje zmluvy a podobne."
          },

          duration: "Volebné obdobie (roky) *",

          head: {
            title: "Najvyšší orgán *",
            meeting: "Členská schôdza",
            council: "Rada členov",
            other: "Iné",
            otherPlaceholder: "Napíšte názov najvyššieho orgánu",
            tooltip: "Najvyšší orgán zvyčajne tvoria členovia združenia a patria mu najvyššie oprávnenia, ako napríklad voliť a odvolávať štatutárneho zástupcu, schvaľovať hospodárenie združenia a podobne."
          },

          control: {
            title: "Kontrolný orgán (NEPOVINNÉ)",
            revision: "Revízna kontrola",
            other: "Iné",
            otherPlaceholder: "Napíšte názov kontrolného orgánu",
            placeholder: "Z ponuky",
            durationPlaceholder: "Roky"
          },
          controlDuration: "Volebné obdobie (roky)",
          controlTooltip: "Nepovinné."

        },

        orderInfo: {
          title: "Objednávateľ",
          email: "E-mail *"
        },

        contactInfo: {
          title: "Profil objednávateľa",
          subtitle: "Sledovanie stavu objednávky a jej detailu bude objednávateľ môcť vo Firmárni v sekcii Moja Firma. Zadajte, prosím, email, ktorý bude slúžiť ako prihlasovacie meno objednávateľa a vytvorte si k nemu heslo.",
          email: "E-mail (slúži ako prihlasovacie meno) *",
          password: "Heslo pre profil vo Firmárni *",
          phoneNumber: "Telefónne číslo *"
        },

        additionalInfo: {
          title: "Iné",
          voucher: "Zľavový kupón",
          reference: "Ako ste sa o nás dozvedeli? / Kto nás odporučil?",
          referencePlaceholder: "Kamarát, cez internet ...",
          comment: "Poznámka",
          commentPlaceholder: "Sem napíšte poznámku"
        },
        submitHelp: "Nasleduje: Zadanie údajov o zakladajúcich členoch"
      },

      krok2: {
        label: "Zakladajúci členovia",

        header: "Vyplňte troch zakladateľov",
        headerSelf: " (členov prípravného výboru)",
        subheader: "Zakladajúci členovia musia byť vždy traja. Ako prvého uveďte toho, kto bude v mene združenia kontaktnou osobou pre registráciu združenia.",

        title: "Zakladajúci člen",

        titleBefore: "Titul pred menom",
        name: "Meno *",
        surname: "Priezvisko *",
        titleAfter: "Titul za menom",
        address: {
          street: "Adresa trvalého bydliska *",
          streetTooltip: "Zadajte názov ulice alebo verejného priestranstva (námestia). Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce.",
          streetNo: "Súpisné číslo / orientačné číslo *",
          streetNoTooltip: "Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz).",
          city: "Obec *",
          cityPlaceholder: "Napr. Bratislava",
          zipCode: "PSČ *",
          country: "Štát *"
        },
        phoneNumber: "Telefónne číslo *",
        phoneNumberPlaceholder: "9xx xxx xxx",
        phoneNumberTooltip: "Pre účel kontaktovania klienta v prípade nejasností alebo opravy prípadných chýb.",
        dateOfBirth: "Dátum narodenia *",
        dayPlaceholder: "deň",
        monthPlaceholder: "mesiac",
        yearPlaceholder: "rok",
        idNumber: "Rodné číslo *",

        paymentInfo: {
          label: "Fakturačné údaje",

          invoiceTo: {
            label: "Faktúra za služby portálu bude vystavená na",
            tooltip: "Na faktúre môže byť uvedené aj samotné nové občianske združenie - takáto faktúra je riadnym daňovým dokladom novo-vznikajúceho združenia.",

            other: "Iná osoba",

            ico: "IČO",
            companyName: "Meno a priezvisko / Obchodné meno *",
            dic: "DIČ",
            icDph: "IČ DPH",

            address: {
              street: "Ulica *",
              streetNo: "Číslo *",
              city: "Obec *",
              cityPlaceholder: "Napr. Bratislava",
              zipCode: "PSČ *",
              country: "Štát *"
            }
          }
        },
        submitHelp: "Nasleduje: kontrola zadaných údajov"
      },

      krok3: {
        label: "Dokončenie objednávky",

        header: "Záverečné údaje pred dokončením",
        subheader: "V prípade, ak ste zistili nesprávnosť alebo neúplnosť, chybu opravte alebo doplňte chýbajúci údaj a kliknite dole na tlačidlo DOKONČIŤ.",

        name: {
          title: "Názov a sídlo",
          companyName: {
            title: "Názov",
            label: "Názov spoločnosti"
          },
          place: {
            title: "Sídlo",
            street: "Ulica",
            streetNo: "Súp./orientačné číslo",
            city: "Obec",
            zipCode: "PSČ"
          }
        },

        goals: {
          title: "Ciele",

          specific: "Špecifický cieľ - EIA",
          generic: "Všeobecný cieľ"
        },

        otherInfo: {
          title: "Orgány",

          status: "Štatutárny orgán",
          head: "Najvyšší orgán",
          control: "Kontrolný orgán"
        },

        additionalInfo: {
          title: "Iné",
          reference: "Ako ste sa o nás dozvedeli? / Kto nás odporučil?",
          comment: "Poznámka"
        },

        members: {
          title: "Zakladatelia",
          label: "Zakladajúci člen",

          name: "Meno a priezvisko",
          dateOfBirth: "Dátum narodenia",
          idNumber: "Rodné číslo",
          street: "Ulica",
          streetNo: "Súp./orientačné číslo",
          city: "Obec",
          zipCode: "PSČ"
        },
        submitHelpPrev: "Všetko je pripravené na vygenerovanie a zaslanie sady dokumentov a návodov na založenie združenia",
        submitHelp: "Nasleduje: Výber spôsobu platby"
      }
    },
    konverzia: {
      krok1: {
        label: "Údaje pre konverziu dokumentov",

        header: "Výpisy z registrov",
        subheader: "Objednávka výpisu zo živnostenského, obchodného alebo iného registra v elektronickej alebo listinnej podobe",

        ico: "Ičo *",
        personalInfo: {
          actualInfo: "Živnostník",
          actualCompanyInfo: "Spoločnosť",
          companyName: "Obchodné meno *",
          titleBefore: "Titul pred menom",
          name: "Meno *",
          surname: "Priezvisko *",
          titleAfter: "Titul za menom"
        },
        service: {
          label: "Objednávam si výpis týkajúci sa",
          trade: "živnostníka",
          company: "spoločnosti",
          other: "iného subjektu (napríklad občianskeho združenia)"
        },
        type: {
          label: "Žiadam o výpis",
          paper: "V listinnej podobe (papier) - {{price}} € bez DPH",
          electronic: "V elektronickej podobe (el. súbor) - {{price}} € bez DPH",
          public: "Verejný (bez rodného čísla)",
          private: "Neverejný (obsahujúci rodné číslo)"
        },
        sendTo: {
          label: "Dokument žiadam",
          radio: {
            post: "zaslať poštou na adresu",
            firmaren: "pripraviť na osobné prevzatie v kancelárii Firmárne",

          },
          email: "zaslať emailom na adresu *",
          street: "Adresa miesta podnikania"
        },
      },
      krok2: {
        label: "Dokončenie objednávky",
        typeDoc: "Typ dokumentu",
        type: {
          paper: "V listinnej podobe (papier)",
          electronic: "V elektronickej podobe (el. súbor)"
        },
        sendTo: {
          type: "Doručenie",
          postEmail: "Emailom",
          email: "Email"
        },
        personalInfo: {
          company: "Obchodné meno"
        }
      }
    },
    payments: {
      header: "Ďakujeme",
      orderReceivedStart: "Vaša objednávka číslo",
      orderReceivedEnd: "bola prijatá.",
      thanks: "Ďakujeme za prejavenú dôveru.",
      thanksFree: "Ďakujeme vám za objednávku a dôveru!<br /><br />Váš profil účtovníka zverejníme na stránke uctovnici.sk bezplatne na 60 dní. Môžete si tak vyskúšať, či vám bude prinášať nové kontakty na zákazníkov.<br /><br />Momentálne objednávku spracúvame a zverejníme ju ihneď po jej úspešnom overení - zvyčajne do dvoch pracovných dní.",
      movement: "Bezhotovostný prevod alebo vklad",
      help: "Garancia vrátenia peňazí",
      ozDocs: "Už na tom pracujeme. Dokumenty na stiahnutie tu nájdete hneď, ako ich spracujeme.",
      card: "Platba kartou ",

      guide: {
        payment: {
          header: "Platba",
          text: "<strong>Zrealizujte platbu jedným zo spôsobov uvedených vpravo.</strong>",
          item1: "Po úhrade vám systém vygeneruje riadnu faktúru, ktorú môžete použiť ako riadny daňový a účtovný doklad.",
          item2: "Pri zakladaní novej firmy (živnosti alebo s.r.o.) je možné vykonať úhradu aj zo súkromného účtu zakladateľa - výdavok sa uznáva za riadny výdavok novej firmy.",
          item3Oz: "Pri zakladaní nového občianskeho združenia je možné vykonať úhradu aj zo súkromého účtu zakladateľa - výdavok sa uznáva za riadny výdavok novej firmy.",
          itemSelf: "Po úhrade vám systém zašle email s riadnou faktúrou, ktorú môžete použiť ako daňový a účtovný doklad."
        },
        nextSteps: {
          header: "Ďalšie kroky",
          text: "<strong>Systém vám automaticky vygeneruje a zašle dokumenty na podpis a detajlné návody ako zmenu poslať na obchodný register online, s použitím vášho eID a čítačky.</strong><br />Na vašej objednávke sme práve začali pracovať."
        },
        signing: {
          header: "Podpis a zaslanie",
          headerBurza: "Zverejnenie inzerátu",
          text: "Aby sme mohli vybaviť vašu objednávku, <strong>zostáva už len posledné</strong>:<br />" +
          "Na váš <strong>email sme zaslali odkaz na dokument/y</strong>, ktorý/é vás prosíme<ul><li>vytlačiť (predtým si dokument/y môžete stiahnuť)</li><li>podpísať perom</li><li>naskenovať alebo odfotiť mobilom a <strong>zaslať nám takto podpísaný dokument/y odpoveďou späť</strong> na ktorýkoľvek z našich emailov.</li></ul>Dokument/y <strong>môžete podpísať aj elektronicky</strong>, s použitím vášho občianskeho preukazu (eID). Návod na elektronický podpis nájdete tiež v zaslanom emaily.<br /><br />Na vašej objednávke sme práve začali pracovať.",
          textSro: "Na objednávke sme už začali pracovať. Po jej úhrade Vám emailom zašleme všetky potrebné listiny na založenie Vašej firmy spolu s návodom na ich podpísanie.",
          textOz: "Na vašej objednávke sme práve začali pracovať. Do dvoch pracovných dní od spárovania objednávky s úhradou vám emailom zašleme vypracované vzorové dokumenty na založenie vášho združenia. Dokumenty si môžete vytlačiť, podpísať a doručiť nám poštou alebo osobne do kancelárie.",
          textBurza: "Inzerát sa objaví na portáli ihneď po spárovaní objednávky s úhradou.",
          textChangeCompany: "Aby sme mohli vybaviť vašu objednávku, zostáva už len posledné: <strong>Podpíšte a zašlite nám listiny, ktoré vám vypracujeme a odošleme na vašu emailovú adresu do dvoch pracovných dní odo dňa prijatia úhrady za objednváku.</strong> Na vašej objednávke sme práve začali pracovať."
        },
        thanks: {
          header: "Firmáreň",
          text: "<strong>O&nbsp;ostatné sa&nbsp;postará firmáreň.sk.</strong><br>Ďakujeme vám za&nbsp;dôveru.",
          textSelf: "<strong>V prípade akýchkoľvek otázok nás neváhajte kontaktovať.</strong><br />Ďakujeme vám za dôveru.<br />Vaša Firmáreň"
        }
      },
      banner: { //0, 1, 2, 3, 6, 7
        0: "Vaša firma je na ceste. Poobzerajte sa zatiaľ po <a href='https://moja.superfaktura.sk/kupon/firmaren/#ad8bed9189303d9a'>dobrom fakturačnom programe</a> aby ste mohli ihneď <b>začať zarábať</b> a vystavovať faktúry. Pre klientov Firmárne sme dohodli až 90 dní bezplatného využívania super fakturačného programu. Superfaktura.sk - získajte ju zadarmo!",
        1: "Vystavujete faktúry? Vyskúšajte <a href='https://moja.superfaktura.sk/kupon/firmaren/#ad8bed9189303d9a'>jednoduchý fakturačný program</a>. Pre klientov Firmárne sme dohodli až 90 dní bezplatného využívania super fakturačného programu. Superfaktura.sk - získajte ju zadarmo!",
        2: "Vaša živnosť je na ceste.<br /><br />Poobzerajte sa zatiaľ po <a href='https://moja.superfaktura.sk/kupon/firmaren/#ad8bed9189303d9a'>dobrom fakturačnom programe</a> aby ste mohli ihneď <b>začať zarábať</b> a vystavovať faktúry.<br /><br />Pre klientov Firmárne sme dohodli až <b>90 dní bezplatného využívania</b> super fakturačného programu.<br /><br /><a href='https://moja.superfaktura.sk/kupon/firmaren/#ad8bed9189303d9a'>Superfaktura.sk - získajte ju zadarmo!</a>",
        3: "Vystavujete faktúry? Vyskúšajte <a href='https://moja.superfaktura.sk/kupon/firmaren/#ad8bed9189303d9a'>jednoduchý fakturačný program</a>. Pre klientov Firmárne sme dohodli až 90 dní bezplatného využívania super fakturačného programu. Superfaktura.sk - získajte ju zadarmo!",
        6: "Vystavujete faktúry? Vyskúšajte <a href='https://moja.superfaktura.sk/kupon/firmaren/#ad8bed9189303d9a'>jednoduchý fakturačný program</a>. Pre klientov Firmárne sme dohodli až 90 dní bezplatného využívania super fakturačného programu. Superfaktura.sk - získajte ju zadarmo!",
        7: "Vystavujete faktúry? Vyskúšajte <a href='https://moja.superfaktura.sk/kupon/firmaren/#ad8bed9189303d9a'>jednoduchý fakturačný program</a>. Pre klientov Firmárne sme dohodli až 90 dní bezplatného využívania super fakturačného programu. Superfaktura.sk - získajte ju zadarmo!",
        zalozenie: "Začínate podnikať a zarábať. Na to potrebujete vystavovať faktúry. Pre klientov Firmárne sme dohodli až 90 dní bezplatného využívania super fakturačného programu. Superfaktura.sk - získajte ju zadarmo!",
        zmena: "Vystavujete faktúry? Vyskúšajte jednoduchý fakturačný program. Pre klientov Firmárne sme dohodli až 90 dní bezplatného využívania super fakturačného programu. Superfaktura.sk - získajte ju zadarmo!"
      },
      documents: {
        zivnost: {
          zaloz: "Tu si stiahnite vyplnenú žiadosť (prípadne ďalšie dokumenty) na založenie živnosti",
          rozsir: "Tu si stiahnite vyplnenú žiadosť (prípadne ďalšie dokumenty) na rozšírenie živnosti"
        },
        general: "Tu si stiahnite dokumenty"
      }
    },

    // Shared

    btn: {
      back: "Späť",
      continue: "Pokračovať na krok",
      edit: "Upraviť",
      add: "Pridať",
      remove: "Odobrať",
      save: "Uložiť",
      cancel: "Zrušiť",
      saveForLater: "Uložiť a dokončiť neskôr",
      finish: "Dokončiť",
      downloadProforma: "Stiahnuť predfaktúru",
      download: "Stiahnuť",
      downloadInvoice: "Stiahnuť faktúru",
      downloadDocs: "Stiahnuť dokumenty",
      downloadDocsZip: "Stiahnuť všetky (ZIP)",
      pay: "Zaplatiť",
      check: "Overiť",
      addFile: "+ Pridať súbor",
      importFile: "Vybrať súbor",
      send: "Odoslať",
      resetPw: "Resetovať heslo",
      saveScope: "Uložiť predmet podnikania",
      send: "Odoslať"
    },

    editLink: "Zmeniť/Opraviť",

    cart: {
      priceWithoutTax: "Cena bez DPH",
      dph: "Vrátane DPH",
      fee: "(vrátane súdneho poplatku 50 eur)",
      sroSudny: "(vrátane súdneho poplatku 220 €)",
      spravnyPoplatok: "(vrátane správneho poplatku {{fee}} eur)",
      price: "CENA SPOLU",
      finalPrice: "Cena spolu",
      placeOfWork: "Miesto podnikania",
      perMonth: "/mes.",
      perMonthWhole: "/mesiac",
      selected: "Vybratých živností",
      item: {
        price: "Cena",
        notary: "Notársky poplatok za registráciu do ORSR"
      },
      withTax: "(s DPH)"
    },

    validation: {
      required: "Povinný údaj",
      pattern: "Nesprávny formát",
      minlength: "Príliš krátke",
      maxlength: "Príliš dlhé",
      email: "Nesprávny formát",
      password: "Heslá sa nerovnajú",
      integer: "Nesprávny formát čísla",
      dateofbirth: "Nesprávny dátum narodenia",
      passwordPattern: "Heslo musi obsahovať min. 8 znakov",
      mask: "Nesprávny formát",
      emailUnique: "Email už je obsadený",
      emailInvalid: "Meno alebo heslo nie je správne",
      couponUsed: "Kupón už bol použitý",
      couponExpired: "Platnosť kupónu vypršala",
      couponInvalid: "Kupón je neplatný",
      couponTemp: "Akutálne je cena za naše služby zľavnená na 1 €,<br />preto nie je možné uplatniť tento zľavový kupón.",
      min: "Minimálna hodnota: ",
      max: "Maximálna hodnota: ",
      capitalAmount: "Základné imanie sa nezhoduje<br>so súčtom vkladov spoločníkov",
      capitalExtent: "Rozsah splateného imania pri<br>jednom spoločníkovi musí byť 100%.",
      capitalExtentFounders: "Rozsah splateného imania pri dvoch<br>a viacerých spoločníkov musí byť vyšší ako 2500€.",
      capitalExtentDiff: "Rozsah splatenia základného imania sa nezhoduje so súčtom rozsahu splatenia vkladov spoločníkov.",
      phoneNumberPattern: "Zadávajte v medzinárodnom formáte +421XXXXXXXXX.",
      requiredMessage: "Nemáte vyplnené všetky povinné polia",
      sumOfShareTransferMessage: "Prevádzate celú firmu ktorej imanie je {{imanie}} eur. Súčet podielov nových spoločníkov je {{sucet}} eur. Musíte vložiť ďalšieho spoločníka, alebo upraviť podiely tak, aby bol súčet rovný základnému imaniu.",
      requiredCheckbox: "Toto pole je povinné",
      radio: "Jeden z týchto údajov je povinný",
      goal: "Vyplňte aspoň jeden cieľ",
      change: "Táto zmena je povinná",
      typeaheadEditable: "Vyberte jednu z možnosti",
      keywords: {
        max: "Maximálny počet zadaných<br />kľúčových slov je 20",
        maxChar: "Maximálny počet znakov je 256"
      }
    },

    paymentInfo: {
      title: "Fakturačné údaje",
      label: "Faktúra za služby portálu bude vystavená na",
      ico: "IČO",
      companyName: "Obchodné meno / meno a priezvisko",
      name: "Meno a priezvisko / Obchodné meno",
      dic: "DIČ",
      icDph: "IČ DPH",
      country: "Štát",
      same: "Faktúra bude vystavená živnostníkovi",
      sameCompany: "Faktúra bude vystavená spoločnosti",
      other: "Iná osoba",
      founder: "Spoločník - ",
      address: {
        street: "Ulica/Nám",
        streetAndNumber: "Ulica / Nám. a číslo",
        streetNo: "Súpis./orient. číslo",
        city: "Obec",
        zipCode: "PSČ",
        country: "Štát"
      }
    },

    help: {
      email: "help@firmaren.sk",
      phone: "02 55 56 77 73"
    },

    conditions: {
      start: "Súhlasím so",
      link: "všeobecnými podmienkami poskytovania služby",
      agreement: "Súhlasím s podmienkami spracovania osobných údajov *"
    },
    contactme: {
      label: "Žiadam o telefonické kontaktovanie po odoslaní objednávky.",
      tooltip: "Ak si neželáte, aby sme vás po zadaní objednávky telefonicky kontaktovali, zrušte predvolené zakliknutie tejto možnosti. V záujme správneho a rýchleho vybavenia vašej objednávky vám však aj napriek tomu môžeme telefonovať v tých prípadoch, kedy si to bude vyžadovať neštandardná situácia (napr. potreba dodatočného získania alebo overenia niektorých informácií v objednávke a podobne)."
    },

    representation: "Žiadam o bezplatné zastúpenie pred príslušným registračným úradom advokátskou kanceláriou LEGALIA, adv. kanc., s. r. o.",
    representationTooltip: "Čo to znamená? Ak necháte túto voľbu zakliknutú, Firmáreň pre vás zabezpečí všetko potrebné, vrátane bezplatného podania vašej žiadosti/návrhu na príslušný úrad, ktoré je však potrebné urobiť prostredníctvom advokátskej kancelárie. Ak túto možnosť nenecháte zakliknutú, znamená to pre nás, že ste sa rozhodli podať si žiadosť/návrh na príslušný úrad sami resp. prostredníctvom inej oprávnenej osoby, a tak si proces dokončíte vo vlastnej réžii.",

    sendInvoice: "Faktúru žiadam zaslať emailom.",

    orderFinishInfo: "Objednávka nie je aktívna, kým nedôjde k jej úhrade.",

    goals: {
      tableHeader: {
        goal: "Cieľ",
        category: "Kategória",
        subcategory: "Podkategória",
        add: "Pridať",
        remove: "Odobrať"
      },
      modal: {
        title: "Zoznam cieľov podľa kategórií",
      }
    },

    businessSubjects: {
      title: "Zoznam živnostenských predmetov",
      subtitle: "Oficiálny zoznam ministerstva vnútra",

      required: "Musíte si zvoliť aspoň jednu živnosť",
      mainSubject: "Hlavná činnosť podnikania",

      categories: {
        graphic: "Grafika a dizajn",
        blog: "Blog, Vlog a copywriting",
        handmade: "Hand made a dekorácie",
        bar: "Kaviareň a bar",
        restaurant: "Reštaurácia",
        bakery: "Pekárska a cukrárska výroba",
        traffic: "Doprava",
        administration: "Administratíva",
        architecture: "Stavebníctvo",
        science: "Veda, výskum a projekty",
        agriculture: "Poľnohospodárstvo",
        clothing: "Odevy",
        accounting: "Účtovníctvo",
        health: "Zdravý životný štýl",
        language: "Jazyky - preklady a vyučovanie",
        finance: "Finančníctvo",
        advertisement: "Reklama, marketing a prieskumy",
        software: "Software a hardware",
        course: "Školenia a kurzy",
        courier: "Kuriér a donášková služba",
        eshop: "Eshop",
        business: "Obchodná činnosť"
      },

      tableHeader: {
        name: "Názov predmetu",
        type: "Druh",
        price: "Cena",
        info: "Viac info",
        add: "Pridať",
        remove: "Odobrať"
      },

      type: {
        0: "Voľná",
        1: "Remeselná",
        2: "Viazaná",
        3: "Iná"
      },

      summary: {
        header: "Vybrané predmety podnikania",
        totalPrice: "Cena spolu"
      },

      groups: {
        mostUsed: {
          title: "Najčastejšie zadávané",
          subtitle: "obchodná činnosť"
        },
        eshop: {
          title: "E-shop",
          subtitle: "živnosti vhodné pre e-shop"
        },
        free: "Voľné živnosti",
        craft: "Remeselné živnosti",
        bound: "Viazané živnosti"
      }
    },

    order: {
      title: "Nezáväzná objednávka firmy",
      name: "Meno a priezvisko *",
      email: "E-mail (slúži ako prihlasovacie meno) *",
      emailContact: "E-mail *",
      phoneNumber: "Telefónne číslo *",
      phoneNumberTooltip: "Pre účel kontaktovania klienta v prípade nejasností alebo opravy prípadných chýb.",
      phoneNumberPlaceholder: "9xx xxx xxx",
      comment: "Poznámka",
      commentPlaceholder: "Sem napíšte správu",
      button: "Odoslať",
      titleDestroy: "Nezáväzná objednávka",
      success: "Vaša správa bola úspešne odoslaná"
    },

    founder: {
      header: "<strong>Nový zakladateľ</strong> (spoločník)",
      btnAdd: "Hotovo",

      type: {
        label: "Typ zakladateľa *",
        person: "Fyzická osoba",
        company: "Právnicka osoba"
      },

      gender: "Pohlavie *",
      titleBefore: "Titul pred menom",
      name: "Meno *",
      surname: "Priezvisko *",
      titleAfter: "Titul za menom",

      address: {
        street: "Ulica/Námestie *",
        streetTooltip: "Zadajte názov ulice alebo verejného priestranstva (námestie). Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce.",
        streetNo: "Súpisné číslo / orientačné číslo *",
        streetNoTooltip: "Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz).",
        city: "Obec *",
        cityPlaceholder: "Napr. Bratislava",
        zipCode: "PSČ *",
        country: "Štát *"
      },

      docType: "Typ dokladu totožnosti *",
      docId: "Číslo dokladu totožnosti *",
      docIdTooltip: "Údaj o type a čísle dokladu totožnosti slúži pre účely oznámenia konečného úžívateľa výhod obchodnému registru.",

      dateOfBirth: "Dátum narodenia *",
      dayPlaceholder: "deň",
      monthPlaceholder: "mesiac",
      yearPlaceholder: "rok",
      idNumber: "Rodné číslo *",

      capital: "Výška vkladu *",
      fullCapital: "Aktuálna výška základného imania spoločnosti *",
      capitalTooltip: "Minimálna výška vkladu každého spoločníka jednotlivo nemie byť nižšia ako 750 eur. V prípade jediného zakladateľa (spoločníka) sa výška vkladu musí rovnať výške základného imania (min. 5000 eur).",
      share: "Podiel v spoločnosti *",
      shareTooltip: "V prípade jediného spoločníka musí byť základné imanie splatené vo výške 100%.",
      paid: "Rozsah splatenia vkladu *",
      paidTooltip: "V prípade dvoch a viac spoločníkov, musí byť splatený vklad každého spoločníka minimálne vo výške 30%. Celková hodnota splatených vkladov musí byť aspoň 2500 eur.",

      isExecutiveManager: "Tento zakladateľ (spoločník) bude aj konateľom",
      isExecutiveManagerPlaceholder: "Zrušte zakliknutie, ak tento zakladateľ (spoločník) nebude konateľom.",
      isDepositAdministrator: "Tento zakladateľ (spoločník) bude aj správcom vkladu",
      isDepositAdministratorPlaceholder: "Správca vkladu musí byť vždy ustanovený. Môže to byť iba jeden zo spoločníkov.",

      otherInfo: {
        header: "Údaje pre výpis z registra trestov",
        help: "Aby mohol za vás živnostenský úrad online vyžiadať výpis z registra trestov konateľa, vyžaduje nasledovné údaje:",
        placeOfBirth: "Miesto narodenia *",
        nationality: "Štátna príslušnosť *",
        birthSurname: "Rodné priezvisko *",
        formerSurname: "Predchádzajúce priezvisko",
        father: {
          name: "Meno otca *",
          surname: "Priezvisko otca *"
        },
        mother: {
          name: "Meno matky *",
          surname: "Priezvisko matky *",
          birthSurname: "Rodné priezvisko matky *"
        },
        cityPlaceholder: "Napr. Bratislava",
        nationalityPlaceholder: "Napr. Slovenská"
      },

      ico: "IČO",
      companyName: "Obchodné meno *",
      nationality: "Štátna príslušnosť *",

      representativePerson: "Zastúpenie osobou (konateľ)"
    },

    executiveManager: {
      header: "Nový konateľ",
      btnAdd: "Pridať konateľa",

      titleBefore: "Titul pred menom",
      name: "Meno *",
      surname: "Priezvisko *",
      titleAfter: "Titul za menom",

      address: {
        street: "Ulica/Námestie *",
        streetTooltip: "Zadajte názov ulice alebo verejného priestranstva (námestie). Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce.",
        streetNo: "Súpisné číslo / orientačné číslo *",
        streetNoTooltip: "Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz).",
        city: "Obec *",
        cityPlaceholder: "Napr. Bratislava",
        zipCode: "PSČ *",
        country: "Štát *"
      },

      dateOfBirth: "Dátum narodenia *",
      dayPlaceholder: "deň",
      monthPlaceholder: "mesiac",
      yearPlaceholder: "rok",
      idNumber: "Rodné číslo *",

      otherInfo: {
        header: "Údaje pre výpis z registra trestov",
        help: "Aby mohol za vás živnostenský úrad online vyžiadať výpis z registra trestov konateľa, vyžaduje nasledovné údaje:",
        placeOfBirth: "Miesto narodenia *",
        nationality: "Štátna príslušnosť *",
        birthSurname: "Rodné priezvisko *",
        formerSurname: "Predchádzajúce priezvisko",
        father: {
          name: "Meno otca *",
          surname: "Priezvisko otca *"
        },
        mother: {
          name: "Meno matky *",
          surname: "Priezvisko matky *",
          birthSurname: "Rodné priezvisko matky *"
        }
      }
    },

    virtualneSidlo: {
      title: "Virtuálne sídlo | Firmáreň",

      prehlad: {
        header: "Virtuálne sídla",
        subheader: "Potrebujete pre svoju firmu registračné sídlo na dobrej adrese? Vyberte si z ponuky virtuálnych sídiel po celom Slovensku.",
        notFound: "Prepáčte, pre vybranú lokalitu sme nenašli žiadneho poskytovateľa<br>virtuálnych sídiel. Skúste zmeniť kritériá vyhľadávania.",
        btn: {
          add: "Pridať nové sídlo",
          more: "Zobraziť 20 ďalších sídiel"
        },
        element: {
          minLength: "Min. dĺžka kontraktu",
          office: "Daňový úrad",
          register: "Obchodný register",
          placeType: "Typ priestoru",
          btn: {
            detail: "Otvoriť detail"
          }
        },
        filter: {
          title: "Filter sídiel",
          location: "Lokalita",
          services: {
            label: "Služby",
            coworking: "Coworking",
            office: "Kancelária",
            englishSupport: "Anglický jazyk",
            withoutCommitment: "Bez viazanosti",
            receiving: "Prijímanie doporučených zásielok",
            photography: "Fotografia zásielky",
            notification: "Email/SMS notifikácia o prijatej zásielke",
            onlineSystem: "On-line systém na evidenciu zásielok",
            scanning: "Skenovanie zásielok",
            forwarding: "Preposielanie zásielok",
            virtual: "Virtuálne sídlo",
            personalCollection: "Osobný odber zásielok"
          },
          btn: {
            reset: "Resetovať",
            showOnMap: "Zobraziť výsledky na mape",
          }
        }
      },

      krok1: {
        label: "Pridať nové sídlo",
        header: "Pridať nové sídlo",
        subheader: "Firmáreň Vám dáva možnosť ponúknuť registračné sídlo vašej kancelárie alebo coworking priestoru aj pre iné firmy. Ponúknite svoje priestory na prenájom a zvýšte svoj príjem aj z iného zdroja.",

        clientInfo: {
          title: "Údaje o poskytovateľovi"
        },
        companyName: "Obchodné meno *",
        email: "Email *",
        phone: "Telefón *",
        web: "Web stránka",
        webPlaceholder: "www.",
        seat: {
          title: "Údaje o sídle",
          partOfCity: "Mestská časť *",
          office: "Daňový úrad *",
          register: "Obchodný register *",
          contract: "Min. dĺžka kontraktu",
          contractItems: {
            0: "Bez obmedzenia",
            1: "1 rok",
            2: "2 roky",
            3: "3 mesiace",
            6: "6 mesiacov"
          },
          price: "Cena za prenájom od *",
          pricePlaceholder: "Cena v EUR",
          services: {
            title: "Služba",
            virtual: "Virtuálne sídlo",
            coworking: "Coworkingové priestory",
            office: "Kancelárske priestory",
            shipment: "Prijímanie zásielok",
            photo: "Fotografia zásielky",
            notification: "Email/SMS notifikácia o prijatí zásielky",
            personalCollection: "Osobný odber zásielok",
            online: "Online evidencia zásielok",
            scan: "Skenovanie zásielok",
            send: "Preposielanie zásielok",
            englishSupport: "Anglický jazyk",
            withoutCommitment: "Bez viazanosti",
            yes: "Áno",
            no: "Nie"
          },
          description: "Podrobnosti o sídle *",
          photo: "Logo firmy alebo fotka areálu/kancelárie",
          gallery: "Fotogaléria",
          galleryLabel: "nahrať obrázky"
        },
        infoTitle: "Informácia",
        limitExceededInfo: "Ľutujeme, pre toto mesto je už počet virtuálnych sídiel vyčerpaný.",
      },
      krok2: {
        label: "Profil objednávateľa",
        paymentInfo: {
          title: "Fakturačné údaje",
          ico: "IČO *",
          companyName: "Obchodné meno *",
          dic: "DIČ",
          icDph: "IČ DPH",

          address: {
            street: "Ulica",
            streetNo: "Číslo",
            city: "Obec *",
            cityPlaceholder: "Napr. Bratislava",
            zipCode: "PSČ *",
            country: "Štát *"
          }
        },
        contactInfo: {
          title: "Profil objednávateľa",
          subtitle: "Sledovanie stavu objednávky a jej detailu bude objednávateľ môcť vo Firmárni v sekcii Moja Firma. Zadajte, prosím, email, ktorý bude slúžiť ako prihlasovacie meno objednávateľa a vytvorte si k nemu heslo.",
          email: "E-mail (slúži ako prihlasovacie meno) *",
          password: "Heslo pre profil vo Firmárni *",
          phoneNumber: "Telefónne číslo *"
        },

        additionalInfo: {
          title: "Iné",
          comment: "Poznámka",
          commentPlaceholder: "Sem napíšte poznámku",
          info: "Tento text nebude zverejnený v inzeráte."
        }
      },
      krok3: {
        label: "Dokončenie objednávky",
        info: {
          companyName: "Obchodné meno",
          email: "Email",
          phone: "Telefón",
          web: "Web stránka",
          seat: {
            partOfCity: "Mestská časť",
            office: "Daňový úrad",
            register: "Obchodný register",
            contract: "Min. dĺžka kontraktu",
            price: "Cena za prenájom od",
            services: {
              virtual: "Virtuálne sídlo",
              coworking: "Coworkingové priestory",
              office: "Kancelárske priestory",
              shipment: "Prijímanie zásielok",
              photo: "Fotografia zásielky",
              notification: "Email/SMS notifikácia o prijatí zásielky",
              personalCollection: "Osobný odber zásielok",
              online: "Online evidencia zásielok",
              scan: "Skenovanie zásielok",
              send: "Preposielanie zásielok",
              englishSupport: "Anglický jazyk",
              withoutCommitment: "Bez viazanosti",
            },
            description: "Podrobnosti o sídle"
          }
        }
      },
      detail: {
        office: "Prenájom kancelárií",
        btn: {
          zalozitZivnost: "Založiť živnosť",
          zmenitAdresuZivnost: "Zmena živnosti",
          zalozitSro: "Založiť S.R.O.",
          zmenitAdresuSro: "Zmeniť adresu S.R.O."
        }
      }
    },

    burza: {
      title: "Burza firiem | Firmáreň",

      btn: {
        sell: "Chcem predať firmu",
        buy: "Chcem kúpiť firmu"
      },

      krok1: {
        label: "Údaje pre predaj firmy",

        header: "Chcem predať firmu",
        subheader: "Chcete predať svoju nepotrebnú firmu? Ste tu správne! Získajte pozornosť stoviek ľudí, ktorí denne navštevujú tento portál s cieľom obstarať si firmu pre svoje podnikanie.",

        companyInfo: {
          title: "Údaje o predávanej firme",
          subtitle: "Tieto údaje budú zverejnené v inzeráte na tomto portáli",

          ico: "IČO *",
          companyName: "Obchodné meno *",
          companyNameTooltip: "Zadajte obchodné meno spoločnosti, ktorú chcete predať alebo ktorej časť chcete predať.",
          contact: "Email predavajúceho *",
          contactPlaceholder: "E-mail",
          contactName: "Meno a priezvisko predavajúceho *",
          phone: "Telefón *",
          price: "Kúpna cena *",
          priceDeal: "Dohodou",
          priceCustom: "Cena",
          pricePlaceholder: "kúpna cena v EUR",
          activity: "Aktivita firmy *",
          active: "Aktívna",
          nonActive: "Neaktívna",
          debt: "Dlhy firmy *",
          withoutDebt: "Bez dlhov",
          withDebt: "Zadĺžená",
          register: "Registrácia na DPH *",
          withRegister: "Platiteľ DPH",
          withoutRegister: "Neplatiteľ DPH",
          property: "Majetok firmy *",
          withoutProperty: "Bez majetku",
          withProperty: "S majetkom v EUR",
          withPropertyPlaceholder: "hodnota majetku v EUR",
          sales: "Obrat firmy v eur *",
          sales05: "0 - 5 000",
          sales510: "5 001 - 100 000",
          salesMilion: "100 001 - 1 mil.",
          salesMore: "Viac",
          date: "Deň vzniku *",
          location: "Lokalita *",
          details: "Podrobnosti o firme *",
          detailsPlaceholder: "Napíšte text, ktorý sa zobrazí v inzeráte...",

          importFile: "Nahrať fotku",
          importFilePlaceholder: "Nie je vybratý žiadny súbor",
          importFileTooltip: "Nepovinné pole. Nahrať sem môžete napríklad obrázok, ktorý chcete ukázať záujemcom - logo firmy, fotografiu prevádzky prípadne certifikát, ktorý zvyšuje hodnotu firmy.",
          importFileLabel: "Nahrať fotografiu alebo logo firmy",
          importFileBtn: "Vybrať súbor",
          importFileHelp: "+ Pridať fotku"
        },
        tooltip: "Napíšte IČO vašej firmy aby mohol formulár za vás automaticky vyplniť niektoré polia. IČO sa skladá z ôsmych čísiel",

        contactInfo: {
          title: "Profil objednávateľa",
          subtitle: "Sledovanie stavu objednávky a jej detailu bude objednávateľ môcť vo Firmárni v sekcii Moja Firma. Zadajte, prosím, email, ktorý bude slúžiť ako prihlasovacie meno objednávateľa a vytvorte si k nemu heslo.",
          email: "E-mail (slúži ako prihlasovacie meno) *",
          password: "Heslo pre profil vo Firmárni *",
          phoneNumber: "Telefónne číslo *"
        },

        additionalInfo: {
          title: "Iné",
          comment: "Poznámka",
          commentPlaceholder: "Sem napíšte poznámku",
          info: "Tento text nebude zverejnený v inzeráte."
        }
      },

      krok2: {
        label: "Dokončenie objednávky",

        header: "Záverečné údaje pred dokončením",
        subheader: "V prípade, ak ste zistili nesprávnosť alebo neúplnosť, chybu opravte alebo doplňte chýbajúci údaj a kliknite dole na tlačidlo DOKONČIŤ.",

        info: {
          title: "Základné údaje",
          ico: "IČO",
          companyName: "Obchodné meno",
          contact: "Kontakt",
          phone: "Telefón",
          price: "Kúpna cena",
          activity: {
            title: "Aktivita firmy",
            1: "Aktívna",
            0: "Neaktívna"
          },
          debt: {
            title: "Dlhy firmy",
            0: "Bez dlhov",
            1: "Zadĺžená"
          },
          register: {
            title: "Registrácia na DPH",
            0: "Neplatiteľ DPH",
            1: "Platiteľ DPH"
          },
          property: {
            title: "Majetok firmy",
            without: "Bez majetku"
          },
          sales: {
            title: "Obrat firmy v eur",
            0: "0 - 5 000",
            1: "5 001 - 100 000",
            2: "100 001 - 1 mil.",
            3: "Viac ako 1 mil."
          },
          date: "Deň vzniku",
          details: "Podrobnosti o firme"
        },

        invoiceData: {
          title: "Fakturačné údaje",
          name: "Meno a priezvisko / Obchodné meno",
          ico: "IČO",
          dic: "DIČ",
          icDph: "IČ DPH",
          street: "Ulica / nám. a číslo",
          city: "Obec",
          zipCode: "PSČ",
          country: "Štát"
        },

        additionalInfo: {
          title: "Iné",
          comment: "Poznámka"
        }
      },

      buy: {
        header: "BURZA FIRIEM ONLINE",
        subheader: "Obzeráte sa po firme s históriou alebo so zabehnutým biznisom? Vyberte si z ponuky firiem vytvorenej tými, ktorí práve pre takéto firmy hľadajú kupujúceho",

        companyToSell: "Spoločnosti na predaj",
        date: "Dátum publikovania",
        price: "Cena",
        details: "Výpis",
        moreInfo: "Viac Info"
      }
    },

    cennik: {
      title: "Cenník služieb",

      favourite: {
        header: "Najobľúbenejšie",
        zivnost: {
          header: "Založiť živnosť",
          subheader: "Založenie živnosti online bez nutnosti tráviť dlhé hodiny na úradoch a oveľa lacnejšie."
        },
        eshopSro: {
          header: "ZALOŽIŤ S. R. O",
          subheader: "Spoločnosť s ručením obmedzeným zameraná na činnosť obchodovania s tovarom na internete (E-SHOP), grafický dizajn, obchodnú alebo stavebnú činnosť, kaviareň a podobne.",
          priceInfo: "vrátane súd. poplatku a DPH"
        },
        eshopZivnost: {
          header: "ZRUŠIŤ/PRERUŠIŤ ŽIVNOSŤ",
          subheader: "Zrušenie živnosti online, bez návštevy úradov."
        }
      },
      allServices: {
        header: "Všetky naše služby",
        zalozenieFirmy: {
          title: "Založenie firmy",
          table: {
            tr1: "<strong>Založenie s.r.o.</strong> (vrátane súd. poplatku a DPH)"
          },
          tooltip: "Cena je konečná. Cena však nezahŕňa poplatok za osvedčenie podpisu (cca 2,90 €),  poplatky za prípadné remeselné alebo viazané živnosti (7,50 €) a iné náklady pri zahraničných subjektoch."
        },
        zmenyFirma: {
          title: "Zmeny vo firme",
          table: {
            tr1: "<strong>Zmeny v s.r.o.</strong> (vrátane súd. poplatku a DPH)",
            tr2: "<strong>Zmeny v s.r.o.</strong> (Iba vypracovanie a zaslanie dokumentov emailom)",
            trShareTransfer: "<strong>Zmeny v s.r.o.</strong> (prevod obch. podielu)",
            trEndUser: "<strong>Zápis/zmena konečného užívateľa výhod</strong>",
            uss: "<strong>USS zmena adresy bydliska</strong> (cena bez súdneho poplatku 33 eur)",
            rodCislo: "<strong>Zápis rodného čísla do obchodného registra</strong>"
          },
          tooltip: "Cena je konečná. Cena však nezahŕňa poplatok za osvedčenie podpisu (cca 2,90 €),  poplatky za prípadné remeselné alebo viazané živnosti (7,50 €) a iné náklady pri zahraničných subjektoch."
        },
        zalozenieZivnosti: {
          title: "Založenie živnosti",
          table: {
            tr1: "<strong>Založenie živnosti</strong> (bydlisko v SR)",
            tr2: "<strong>Založenie živnosti</strong> (bydlisko mimo SR)"
          }
        },
        zrusenieZivnosti: {
          title: "Zrušenie živnosti",
          table: {
            tr1: "<strong>Zrušenie živnosti</strong> (bydlisko v SR)",
            tr2: "<strong>Zrušenie živnosti</strong> (bydlisko mimo SR)"
          }
        },
        pozastavenieZivnosti: {
          title: "Pozastavenie živnosti",
          table: {
            tr1: "<strong>Pozastavenie živnosti</strong> (bydlisko v SR)",
            tr2: "<strong>Pozastavenie živnosti</strong> (bydlisko mimo SR)"
          }
        },
        zmenaZivnosti: {
          title: "Zmeny v živnosti",
          table: {
            tr1: "<strong>Zmeny v živnosti - napr. zmena adresy</strong> (bydlisko v SR)",
            tr2: "<strong>Zmeny v živnosti - napr. zmena adresy</strong> (bydlisko mimo SR)"
          }
        },
        obnovenieZivnosti: {
          title: "Obnovenie živnosti",
          table: {
            tr1: "<strong>Obnovenie živnosti</strong>"
          }
        },
        predajFirmy: {
          title: "Predaj firmy",
          table: {
            tr1: "<strong>Predaj Ready-Made (už založenej) s.r.o.</strong> (vrátane súd. poplatku a DPH)",
            tr2: "<strong>Predaj Ready-Made (už založenej) s.r.o. – platca DPH</strong> (Iba vypracovanie a zaslanie dokumentov emailom)",
            tr3: "<strong>Predaj firmy – inzerát (BURZA FIRIEM)</strong>"
          }
        },
        virtualneSidlo: {
          title: "Poskytnutie registračného (virtuálneho) sídla",
          table: {
            tr1: "<strong>Poskytnutie registračného sídla pre firmu </strong>"
          }
        },
        zalozenieOZ: {
          title: "Občianske združenia",
          table: {
            tr1: "<strong>Založenie občianskeho združenia</strong> (vrátane reg. poplatku a DPH)",
            tr2: "<strong>Urob si sám: Založenie občianskeho združenia</strong>",
            tr3: "<strong>Predaj vopred založeného občianskeho združenia</strong> (neregistrovaného pre 2%)",
            tr4: "<strong>Predaj vopred založeného občianskeho združenia</strong> (registrovaného pre 2%)"
          }
        },
        underInfo: "Ceny sú vrátane súdneho poplatku pri elektronickom podaní a neúčtuje sa k nim DPH, pokiaľ nie je uvedené, že ceny sú vrátane DPH.",
        btn: "Mám záujem!"
      }
    },

    address: {
      streetPlaceholder: "Názov ulice / námestia"
    },

    companyName: {
      exists: "Obsadené. Vyberte iné obchodné meno.",
      available: "Obchodné meno je voľné.",
      warn: "Obchodné meno nie je možné overiť. Odporúčame si ho overiť cez www.orsr.sk",
      notAvailable: "Momentálne nie je možné overiť názov spoločnosti prostredníctvom ORSR",
      placeholder: "Napr. Kvetinárstvo na rohu"
    },

    // Enumerations

    yes: "Áno",
    no: "Nie",

    gender: {
      male: "Muž",
      female: "Žena"
    },

    docType: {
      id: "Občiansky preukaz",
      passport: "Pas",
      otherId: "Iný doklad"
    },

    placeOfBusinessKind: {
      area: "Nebytový priestor",
      flat: "Byt v bytovom dome",
      otherBuilding: "Iná budova",
      house: "Rodinný dom",
      garage: "Samostatne stojaca garáž"
    },

    login: {
      label: "MOJA FIRMA",
      title: "Prihlásiť sa do Firmárne",
      subtitle: "Vyplňte prosím údaje uvedené nižšie.",
      titleForgotPw: "Zabudnuté heslo",
      email: "E-mail",
      password: "Heslo",
      forgot: "Zabudli ste heslo?",
      btn: "Prihlásiť sa",
      back: "Späť na prihlásenie",
      btnBack: "Späť",
      register: "Zaregistrujte sa",
      notUser: "Stále nemáte účet?",
      logout: "Odhlásiť sa",
      error: "Zadaný email alebo heslo nie sú správne",
      forgotPassword: "Zadajte e-mail, na ktorý vám zašleme odkaz na obnovu hesla.",
      forgotSent: "Na váš e-mail sme odoslali link na resetovanie hesla",
      forgotSentText: "Pre dokončenie zmeny hesla, kliknite na zaslaný link v priebehu nasledujúcich 30 min.",
      bannerTitle: "Superfaktúra na 90 dní úplne zadarmo",
      bannerText: "Každý klient Firmárne má prístup k exkluzívnym zľavám a akciám. Prihlás sa alebo sa zaregistruj a užívaj si výhody."
    },

    virtualOfficeTooltip: "Virtuálne sídlo podľa vášho výberu poskytuje partner portálu firmáreň.sk.",

    actions: {
      sro: "Založiť S.R.O",
      zivnost: "Založiť živnosť"
    },
    footer: {
      subscribe: "Doprajte si občasné aktualizácie o podnikaní.",
      subTitle: "Žiaden spam, sľubujeme!",
      pay: "Na tomto portáli môžete platiť cez:",
      secure: "Ochrana údajov je zabezpečená:",
      socialMedia: "Sociálne siete",
      contact: "Kontakt"
    },
    register: {
      header: "Registrácia",
      subheader: "* označené polia sú povinné",

      email: "Email * (Slúži ako prihlasovacie meno)",
      emailPlaceholder: "@",
      password: "Heslo pre profil vo Firmárni *",
      name: "Meno",
      surname: "Priezvisko",
      ico: "IČO *",
      companyName: "Obchodné meno *",
      dic: "DIČ",
      icDph: "IČ DPH",
      addressCompany: "Adresa sídla firmy",
      address: {
        street: "Adresa",
        streetNo: "Súpisné číslo / orientačné číslo",
        city: "Mesto",
        cityPlaceholder: "Napr. Bratislava",
        zipCode: "PSČ"
      },
      phoneNumber: "Telefónne číslo *",
      phoneNumberPlaceholder: "9xx xxx xxx",
      phoneNumberTooltip: "Pre účel kontaktovania klienta v prípade nejasností alebo opravy prípadných chýb.",
      newsletter: "Áno, chcem e-mailom dostávať bezplatný Newsletter, ktorý ma bude informovať o aktuálnych akciách.",
      btn: "Registrovať",
      success: "Používateľ bol vytvorený",
      error: "Nastala chyba pri vytváraní používateľa hesla"
    },

    forgotPassword: {
      header: "Nové heslo",
      subheader: "Zadajte prosím Vaše nové heslo",

      password: "Heslo pre profil vo Firmárni *",
      btn: "Potvrdiť nové heslo",
      successTitle: "Vaše heslo bolo úspešne zmenené",
      successText: "Použite vaše nové heslo na prihlásenie do Firmárne.",
      btnBack: "Späť na prihlásenie",
      error: "Nastala chyba pri obnove hesla"
    },

    contact: {
      logged: "Prihlásený ako:",
      email: "E-mail (slúži ako prihlasovacie meno) *",
      emailPlaceholder: "@",
      password: "Heslo pre profil vo Firmárni *",
      passwordExists: "Heslo na prihlásenie *",
      phoneNumber: "Telefónne číslo *",
      userExist: "Tento email ste už vo Firmárni niekedy použili. Zadajte heslo, ktoré ste si vtedy na portáli zvolili."
    },

    finishLater: {
      title: "Dokončiť neskôr",
      cancel: "Zrušiť",
      submit: "Potvrdiť",
      email: "E-mail",
      info: "Zadajte prosím email, na ktorý vám zašleme odkaz, pomocou ktorého sa budete vedieť vrátiť k tejto objednávke.",
      sendInfo: "Na zadanú emailovú adresu sme vám zaslali link, cez ktorý sa k rozpracovanej objednávke môžete kedykoľvek vrátiť."
    },

    modalForgotPassword: {
      title: "Zabudli ste heslo?",
      info: "<b>Odkaz pre zmenu hesla</b> Vám bude zaslaný na vašu registrovanú e-mailovú adresu.",
      email: "Vaša e-mailová adresa *",
      btn: "Odoslať"
    },

    modalInfo: {
      titleNoChange: "Nemáte vyplnené žiadne zmeny.",
      infoNoChange: "Pre pokračovanie vykonajte aspoň jednu zmenu.",
    },

    confirmDelete: {
      title: "Potvrdenie vymazania",
      cancel: "Zrušiť",
      submit: "Potvrdiť",
      infoFounder: "Naozaj si prajete odstrániť tohto zakladateľa?",
      infoExecutiveManager: "Naozaj si prajete odstrániť tohto konateľa?",
      info: "Naozaj si prajete odstrániť tento predmet podnikania?",
      infoall: "Naozaj si prajete odstrániť <b>všetky</b> predmety podnikania?",
      infoGoal: "Naozaj si prajete odstrániť tento cieľ?"
    },

    confirmClearData: {
      title: "Naozaj chcete zrušiť zmeny?"
    },

    responsiblePerson: {
      title: "Zodpovedný zástupca *",
      chooseType: "Vyberte osobu, ktorá bude plniť funkciu zodpovedného zástupcu a bude spĺňať požiadavky na prevádzkovanie živnosti (napr. vzdelanie, prax a pod.).",
      trader: "Živnostník",
      other: "Iná osoba",
      establishment: {
        title: "Prevádzkareň",
        no: "Nie",
        yes: "Áno (uvádza sa, ak chcete vykonávať živnosti na inom mieste ako je sídlo)"
      },
      type: {
        own: "Živnostník",
        other: "Iná osoba"
      }
    },

    viral: {
      title: "Ďakujeme za prejavenú dôveru!",
      0: "Za to, že ste sa pochválili založením s.r.o. kamarátom na Facebooku, získavate od nás tento zľavový kód:",
      discount: {
        0: "Zľava 10% na všetky služby od Firmárne - napríklad na zmeny v s.r.o. (rozšírenie predmetu podnikania, zmena sídla prípadne iné zmeny v obchodnom registri). Kupón je prenosný, môžete ho aj podarovať a platí 12 mesiacov.",
        2: "Zľava 10% na všetky služby od Firmárne - napríklad na rozšírenie živnosti, jej pozastavenie alebo zrušenie a podobne. Kupón je prenosný, môžete ho aj podarovať a platí 12 mesiacov."
      },
      2: "Za to, že ste sa pochválili založením živnosti kamarátom na Facebooku, získavate od nás tento zľavový kód:"
    },

    slspBanner: {
      change: "<strong><span style='color: #5994cc'>objavte</span> <span style='color: #006da8'>možnosti<br />financovania</span></strong> pre vaše podnikanie<br />a rozbehnite váš biznis",
      create: "<strong><span style='color: #5994cc'>objavte</span> <span style='color: #006da8'>možnosti<br />ako financovať</span></strong> nové<br />podnikanie"
    },

    personalInfo: {
      label: "Objednávateľ (Vyberte zo zoznamu)"
    },

    modalTatraBanka: {
      sro: {
        title: 'Založenie SRO iba za 1 euro',
        text: "Jedinečnú cenu máte vďaka spolupráci s Tatra bankou.<br /><br /><div class='upsell-description' style='line-height: 15px;'>V cene je ľubovoľný počet voľných živností.<br />Cena nezahŕňa notársky poplatok 204,06 eur.</div><br />Súhlasím, aby ma Tatra banka, a. s. nezáväzne kontaktovala s informáciami o podnikateľskom účte bez poplatku za vedenie na 1 rok."
      },
      zivnost: {
        title: 'Založenie živnosti iba za 1 euro',
        text: "Jedinečnú cenu za ľubovoľný počet voľných živností máte vďaka spolupráci s Tatra bankou.<br /><br /><b>Živnostníci, pozor,<br />nezmeškajte limitovanú ponuku od Tatra banky!</b><br /><br />Tatra banka vám tento rok <b>preplatí transakčnú daň</b><br /> z bezhotovostných platieb. K tomu získate vedenie <b>Živnostenského účtu<sup>TB</sup></b> na <b>3 roky za 0 EUR.</b> Ponuka platí len do <b>30.04. 2025.</b><br /><br />Želáte si, aby vás Tatra banka <strong>nezáväzne</strong> kontaktovala s informáciami o <b>Živnostenskom účte<sup>TB</sup></b>?"
      },
      lastStep: {
        sro: {
          title: 'Založenie SRO iba za 1 euro',
          text: "Jedinečnú cenu máte vďaka spolupráci s Tatra bankou.<br /><br /><div class='upsell-description' style='line-height: 15px;'>V cene je ľubovoľný počet voľných živností.<br />Cena nezahŕňa notársky poplatok 204,06 eur.</div><br />Súhlasím, aby ma Tatra banka, a. s. nezáväzne kontaktovala s informáciami o podnikateľskom účte bez poplatku za vedenie na 1 rok."
        },
        zivnost: {
          title: 'Založenie živnosti iba za 1 euro',
          text: "Jedinečnú cenu za ľubovoľný počet voľných živností máte vďaka spolupráci s Tatra bankou.<br /><br /><b>Živnostníci, pozor,<br />nezmeškajte limitovanú ponuku od Tatra banky!</b><br /><br />Tatra banka vám tento rok <b>preplatí transakčnú daň</b><br /> z bezhotovostných platieb. K tomu získate vedenie <b>Živnostenského účtu<sup>TB</sup></b> na <b>3 roky za 0 EUR.</b> Ponuka platí len do <b>30.04. 2025.</b><br /><br />Želáte si, aby vás Tatra banka <strong>nezáväzne</strong> kontaktovala s informáciami o <b>Živnostenskom účte<sup>TB</sup></b>?"
        }
      },
      discount: "Ušetríte min. {{discount}} €",
      submitBtn: "Mám záujem",
      cancelBtn: "Nemám záujem"
    },

    buttons: {
      ok: "Ok",
      understand: "Rozumiem"
    },
    modalIdle: {
      title: "Relácia vypršala",
      description: "Je potrebné znova načítať stránku."
    },

    metaTags: {
      title1: "test 1",
      title2: "test 2",
      description1: "popis 1 1 1 1 1 1 1 1",
      description2: "popis 2 2 2 2 2 2 2 2",
    },

    upsells: {
      // title: "Možno sa vám budú hodiť aj tieto akciové ponuky",
      title: "Doplnkové služby",
      tb: {
        agreeMemorandum: "Odoslaním tohto formulára dôjde k spracúvaniu vašich osobných údajov. Bližšie informácie nájdete v dokumente <a href='https://www.tatrabanka.sk/files/archiv/sk/o-banke/pravne-informacie/informacne-memorandum-ochrany-osobnych-udajov-tatra-banka.pdf' target='_blank'>Informačné memorandum ochrany osobných údajov.</a> *",
        agreeExternal: "<a href='https://www.tatrabanka.sk/sk/o-banke/pravne-informacie/suhlas-podmienkami/' target='_blank'>Súhlas so spracúvaním osobných údajov pre účely informovania o produktoch, službách a inováciách. Zároveň vyhlasujem, že mám 16 a viac rokov.</a>",
        agreeFirmaren: "Súhlasím s poskytnutím údajov v rozsahu meno, priezvisko a tel. číslo pre spoločnosť Tatra banka, a.s., Hodžovo námestie 3, 811 06 Bratislava 1, pre marketingové účely týkajúce sa jej produktov a služieb. Bližšie informácie nájdete v dokumente <a href='/o-nas/podmienky-ochrany-osobnych-udajov' target='_blank'>Pravidlá ochrany osobných údajov</a>.",
        zivnost: {
          header: "Vedenie Živnostenského účtu<sup>TB</sup> na 3 roky za 0 EUR",
          description: "Tatra banka vám <b>preplatí transakčnú daň</b> z bezhotovostných platieb za rok 2025. K tomu získate vedenie <b>Živnostenského účtu<sup>TB</sup></b> na <b>3 roky za 0 EUR</b>.",
          moreInfo: "<br><b>Živnostníci, pozor, nezmeškajte limitovanú ponuku od Tatra banky. Ponuka platí len do 30. 04. 2025.</b><br /><br /><ul><li><b>Preplatenie transakčnej dane</b> z bezhotovostných platieb za rok 2025.</li><li>Vedenie <b>Živnostenského účtu<sup>TB</sup></b> na <b>3 roky za 0 EUR</b>.</li><li>Osobný <b>Účet pre modrú planétu<sup>TB</sup></b> na <b>3 roky za 0 EUR</b>, ak si ho otvoríte online. Navyše vás banka odmení benefitom až <b>100 EUR späť</b> z platieb kartou.</li></ul> Zaujala vás limitovaná ponuka?<br /><br />Urobte prvý krok a <b>udeľte Tatra banke súhlasy</b>, aby vás mohla <b>nezáväzne</b> kontaktovať s informáciami o vedení <b>Živnostenského účtu<sup>TB</sup></b><br /><br />",
          row: {
            header: "Vedenie Živnostenského účtu<sup>TB</sup> na 3 roky za 0 EUR",
            moreInfo: "Tatra banka vám <b>preplatí transakčnú daň</b> z bezhotovostných platieb za rok 2025. K tomu získate vedenie <b>Živnostenského účtu<sup>TB</sup></b> na <b>3 roky za 0 EUR</b>."
          }
        },
        sro: {
          header: "Vedenie podnikateľského účtu pre SRO na rok za 0 EUR",
          description: "Otvorte si účet v Tatra banke. Ako začínajúca firma získate podnikateľský účet na <b>12 mesiacov bez poplatku za vedenie</b> a ďalšie atraktívne <b>benefity od partnerov.</b>",
          moreInfo: "<br>Tatra banka vám uľahčí podnikanie:<br /><br /><ul><li><b>POS terminál</b> na 6 mesiacov bez poplatku,</li><li>Podnikateľský úver <b>BusinessÚverTB Expres</b> až do výšky <b>2 000 EUR,</b></li><li>Výhodný <b>lízing až do výšky 35 000 EUR</b> na nové alebo jazdené vozidlo, bez poplatku za poskytnutie, <b>už od 10 % akontácie od Tatra leasingu,</b></li><li><b>fakturačný softvér</b> až na 6 mesiacov bez poplatku od vybraných partnerov,</li><li><b>optický internet</b> od Orangeu so 100 % zľavou zo základnej ceny na 6 mesiacov,</li><li>a ďalšie <b>benefity od partnerov</b>.</li></ul>Zaujali vás benefity?<br /><br />Urobte prvý krok a <b>udeľte Tatra banke súhlasy</b>, aby vás mohla <b>nezáväzne</b> kontaktovať s informáciami o podnikateľskom účte.<br /><br />",
          row: {
            header: "Vedenie podnikateľského účtu pre SRO na rok za 0 EUR",
            moreInfo: "Otvorte si účet v Tatra banke. Ako začínajúca firma získate podnikateľský účet na <b>12 mesiacov bez poplatku za vedenie</b> a ďalšie atraktívne <b>benefity od partnerov.</b>"
          }
        },
      },
      withoutTax: " bez DPH",
      modal: {
        title: "V júli vám založíme živnosť za <span class=\"modal-title-red\">1€</span> <span class=\"modal-title-through\">19€</span>",
        subtitle: "A k tomu dostanete Živnostenský účet od Tatra banky zadarmo až na jeden rok, prípadne navždy.",
        desc: "Ak si Živnostenský účet v Tatra banke neprajete, nič sa nedeje, máme pre vás klasické otvorenie živnosti bez účtu za 19 eur.",
        otherDesc: "Ak si vyberiete živnosť za 1 euro, po jej založení vás budú telefonicky kontaktovať z Tatra banky, aby si s vami dohodli všetko potrebné.",
        lastInfo: "Svoju voľbu môžete na konci objednávky zmeniť.",
        buttons: {
          lp: {
            submit: "Prejsť k objednávke",
            cancel: "Zavrieť"
          },
          form: {
            submit: "Chcem živnosť za 1€",
            cancel: "Pokračovať bez zľavy"
          }
        }
      },
      konverzia: {
        headerZivnost: "Osvedčenie v papierovej podobe",
        headerZmenyZivnosti: "Potvrdenie v papierovej podobe",
        header: "Výpis z obchodného registra v papierovej podobe",
        description: "Potrebujete to v papierovej podobe? Živnostenský úrad zasiela svoje rozhodnutia elektronicky.",
        moreInfo: "Papierovú verziu by ste už nemali pre úradný styk potrebovať. Ak ju však od vás vyžaduje obchodný partner alebo banka, môžeme vám vyhotoviť tzv. konverziu dokumentu z elektronickej do listinnej podoby."
      },
      konverziaSpolocnost: {
        header: "Výpis z obchodného registra",
        description: "Potrebujete výpis v papierovej podobe? Obchodný register zasiela svoje rozhodnutia elektronicky.",
        moreInfo: "Papierovú verziu by ste už nemali pre úradný styk potrebovať. Ak ju však od vás vyžaduje obchodný partner alebo banka, môžeme vám vyhotoviť tzv. konverziu dokumentu z elektronickej do listinnej podoby - slúži ako riadny 'papierový' výpis z obchodného registra, použiteľný pre právne úkony."
      },
      superfaktura: {
        header: "Vyskúšajte overený fakturačný program úplne zadarmo až na 90 dní",
        description: "Ľahko a rýchlo vystavíte faktúry a cenové ponuky.",
        moreInfo: "Pre svojich klientov sme vybavili <b>extra predĺžené skúšobné obdobie z 30 až na 90 dní</b>. Počas tejto doby si môžete vyskúšať, či vám SuperFaktúra vyhovuje. Po skončení skúšobného obdobia sa môžete sami rozhodnúť, či si tento nástroj ponecháte za 4,99 eur mesačne.<br><br><strong>Zakliknutím tejto možnosti si automaticky vytvoríte konto v SuperFaktúre.</strong> Potvrdzujúci email s potrebnými informáciami očakávajte po zaregistrovaní vašej živnosti alebo firmy.<br /><br />",
        agreeMemorandum: "Odoslaním tohto formulára dôjde k spracúvaniu vašich osobných údajov a k súhlasu so všeobecnými obchodnými podmienkami. Bližšie informácie nájdete v dokumente <a href='https://www.superfaktura.sk/ochrana-sukromia/' target='_blank'>Pravidlá ochrany osobných údajov.</a> *",
        agreeExternal: "<a href='https://www.superfaktura.sk/podmienky-pouzivania/' target='_blank'>Súhlas so všeobecnými obchodnými podmienkami a so spracovaním osobných údajov pre účely informovania o produktoch, službách a inováciách. Zároveň vyhlasujem, že mám 16 a viac rokov.</a>",
      },
      znamka: {
        header: "Ochranná známka",
        description: "Chcete získať informácie o registrácii ochrannej známky na váš názov alebo produkt? Slovenskú ochrannú známku môžete mať už od 196 eur.",
        moreInfo: "Po zakliknutí tejto možnosti vás budeme kontaktovať telefonicky alebo emailom a poskytneme vám všetky informácie, ktoré budete potrebovať pre registráciu ochrannej známky.<br /><br />",
        agreeExternal: "Súhlasím s poskytnutím emailovej adresy a tel. čísla spoločnosti LEGALIA, advokátska kancelária, s. r. o. pre účel <strong>informovania o možnostiach registrácie ochrannej známky.</strong> Bližšie informácie nájdete v dokumente <a href='/o-nas/podmienky-ochrany-osobnych-udajov' target='_blank'>Pravidlá ochrany osobných údajov.</a>"
      },
      resersZnamka: {
        header: "Preverte si svoj názov",
        description: "Ste si istý/á, že vaše obchodné meno nezasahuje do existujúcej ochrannej známky niekoho iného?",
        moreInfo: "Skôr ako začnete budovať svoju značku, presvedčte sa, že už ju nikto iný nemá chránenú. Neskôr by mohli právnou cestou požadovať zastavenie používania názvu firmy. Vyhnite sa možným problémom v budúcnosti a za 9 eur bez DPH nechajte odborníkov z advokátskej kancelárie overiť, že vaše budúce investície do značky nevyjdú nazmar. Po zakliknutí tejto možnosti vás budeme kontaktovať, aby sme pre vás cez zabezpečili kompletný rešerš označenia na úrovni celej Európskej únii, vrátane územia Slovenska.",
      },
      citacka: {
        header: "Čítačka pre váš občiansky preukaz (eID)",
        description: "Podnikatelia sú povinní komunikovať so štátom elektronicky. Na to potrebujete nový občiansky preukaz a čítačku k nemu.",
        moreInfo: "Ak ju ešte nemáte, môžete si ju zabezpečiť aj cez Firmáreň. Cena je 38 eur bez DPH a zahŕňa poštovné v rámci SR. (Gemalto IDBridge CT 30 (USB007))",
      },
      saltpay: {
        header: "Platobný terminál, kasa a tlačiareň (3 v jednom) pre každú prevádzku",
        description: "Moderné, rýchle a multifunkčné zariadenie, s ktorým budete mať peniaze na účte už nasledujúci pracovný deň.",
        moreInfo: "<strong>Spracovávajte objednávky a prijímajte platby kartou pomocou jediného zariadenia Storyous All-in-One:</strong><ul><li>Tri v jednom - terminál, tlačiareň aj e-kasa</li><li>Peniaze vám pristanú na účet už nasledujúci pracovný deň</li><li>Budete dostávať denné výpisy zúčtovania, aby ste netrávili hodiny času počítaním tržieb</li><li>Na jedno nabitie vydrží celú zmenu (až 10 hodín)</li><li>Umožňuje bezkontaktné aj klasické platby kartou</li><li>K internetu sa pripojí cez SIM alebo Wi-Fi</li><li>Elegantný a odolný hardware - PAX A920</li><li>Prehľad objednávok a platieb na jednom portáli</li><li>Výstupy do účtovných programov Pohoda a Flexibee</li></ul>Zariadenie Storyous All-in-One plne nahradí<strong>pokladničný systém, platobný terminál aj tlačiareň</strong>. Multifunkčné zariadenie, s ktorým budete mať objednávky a platby pod kontrolou. Terminál je možné nastaviť do 10 minút bez akýchkoľvek zložitých zmlúv.<br /><br /><strong>Bonus pre zákazníkov Firmárne</strong>: K platobnému terminálu nabíjacia stanica, dátová SIM, doručenie a obchodný portál zdarma.<br /><br /><strong>Ak sa chcete dozvedieť viac</strong>, pridajte si aj túto možnosť. Pracovníci spoločnosti Teya vám potom telefonicky alebo emailom poskytnú všetky potrebné informácie. Bezplatne a nezáväzne.<br /><br />",
        agreeExternal: "<a href='https://legal.teya.com/slovakia/dpa' target='_blank'>Podmienky spracovania údajov</a>",
        agreeFirmaren: "Súhlasím s poskytnutím údajov v rozsahu meno a priezvisko, tel. číslo a email pre spoločnosť Teya Czech Republic s.r.o., Evropská 11, 160 00, 6 Praha 6, ČR, pre marketingové účely týkajúce sa jej produktov a služieb. Bližšie informácie nájdete v dokumente <a href='/o-nas/podmienky-ochrany-osobnych-udajov' target='_blank'>Pravidlá ochrany osobných údajov</a>."
      },
      expresnaSro: {
        header: "Expresné založenie SRO do 5 pracovných dní",
        description: "Ak vašu firmu nezaregistrujeme do piatich (5) pracovných dní od prijatia podpísaných listín, tento príplatok vám vrátime. A vy tak o nič neprídete.",
        moreInfo: "Aby sme vám mohli poskytovať to najlepšie, čo na Slovensku máme, kladieme na seba stále väčšie nároky. <strong>Rýchlo a kvalitne</strong> - túto výzvu s radosťou prijímame.<br /><br />Expresné založenie SRO si vyžaduje odlišný spôsob a postup registrácie spoločnosti, spojený s väčšou administratívou na našej strane. Z toho dôvodu túto extra službu momentálne poskytujeme za príplatok.<br /><br /><strong>Lehotu 5 pracovných dní počítame odo dňa doručenia kompletných a správne podpísaných dokumentov</strong> na založenie s.r.o. do našej kancelárie či už poštou, osobne alebo elektronicky - v prípade, ak sú listiny podpísané kvalifikovaným elektronickým podpisom. Dokumenty na podpis vám štandardne zašleme emailom po vytvorení objednávky.<br /><br />Poznámka: Lehotu 5 pracovných dní teda nepočítame od dátumu vytvorenia objednávky, ale až odo dňa doručenia správnych listín do našej kancelárie. Iba so správnymi listinami dokážeme vybaviť za 5 pracovných dní živnostenské oprávnenie (3 pracovné dni) a aj zápis v obchodnom registri (2 pracovné dni)."
      },
      expresnaZmenaSro: {
        header: "Expresné vybavenie",
        description: "Vypracované dokumenty pre zmeny v s. r. o. vám zašleme obratom, najneskôr do nasledovného pracovného dňa od prijatia objednávky. Objednávku vybavíme do 5 pracovných dní od prijatia riadne podpísaných všetkých listín.",
        moreInfo: "Ak to v tejto lehote nestihneme, príplatok vám radi vrátime."
      }
    },

    name: {
      titleBefore: "Titul pred menom",
      name: "Meno",
      surname: "Priezvisko",
      titleAfter: "Titul za menom",
      gender: "Pohlavie"
    },

    addressAutocomplete: {
      newStreet: "NOVÁ ADRESA SÍDLA",
      companyStreet: "Ulica",
      streetSquare: "Ulica/námestie",
      otherStreet: "Adresa",
      street: "Adresa trvalého bydliska",
      streetSR: "Adresa trvalého bydliska v SR",
      streetTooltip: "Zadajte názov ulice alebo verejného priestranstva (námestie) presne podľa občianskeho preukazu. Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce.",
      streetNo: "Súpisné číslo / orientačné číslo",
      streetNoPrefixTooltip: "Ide o číslo, ktoré sa na budovách uvádza zvyčajne čiernou farbou. V adrese uvedenej v doklade totožnosti sa uvádza <strong>pred lomítkom</strong>.",
      streetNoVideoTooltip: "<strong>Pozrite si video,</strong> aký je rozdiel medzi súpisným a orientačným číslom.",
      streetNoTooltip: "Ide o číslo, ktoré sa na budovách uvádza zvyčajne červenou farbou. V adrese uvedenej v doklade totožnosti sa uvádza <strong>za lomítkom</strong>. Ak sa obec nedelí na ulice, orientačné číslo neexistuje - V takomto prípade uveďte aj do tohto poľa súpisné číslo.",
      city: "Obec",
      cityPlaceholder: "Napr. Bratislava",
      zipCode: "PSČ",
      country: "Štát",
      streetNoPrefixPopover: "Ak máte v občianskom preukaze<br />aj <strong>súpisné číslo</strong>, napíšte ho sem."
    },

    instruction: {
      text: "<strong>Potrebujete pomôcť s vyplnením?</strong><br />Pozrite si video-návod."
    },

    conversionDocs: {
      title: "Žiadam o zabezpečenie platenej konverzie dokumentu z elektronickej do listinnej podoby",
      subtitle: "(Slúži na premenu elektronického dokumentu na originál v klasickej, papierovej forme - napríklad osvedčenie o živnostenskom oprávnení, potvrdenie o skončení podnikania a podobne)",
      receiveDocsLabel: "Prevzatie listiny",
      receiveDocsPersonal: "osobne vo Firmárni (Pribinova 4195/25, 811 09 Bratislava)",
      receiveDocsAddress: "poštou na adresu",
      receiveDocsAddressPlaceholder: "Napíšte meno, priezvisko  a adresu doručenia",
      receiveDocsAddressPriceInfo: "(Cena poštovného v rámci SR je 0,65 eur)",
      comment: "Poznámka",
      agreements: "Súhlasím so zabezpečením konverzie dokumentu cez advokátsku kanceláriu (LEGALIA, advokátska kancelária, s.r.o.)",
    },
    tradeMark: {
      title: "Ochranná známka",
      subtitle: "Registráciu ochrannej známky vám zabezpečíme pre územie SR už za cenu <strong>200 eur</strong> bez DPH plus správny poplatok 96 eur a pre územie všetkých štátov Európskej únie za cenu <strong>220 eur</strong> bez DPH plus správny poplatok 850 eur.<br /><br />\n" +
          "Prosíme vás o zaslanie <strong>kontaktných údajov</strong> využitím tohto formulára. Spojíme sa s Vami, poskytneme Vám všetky potrebné informácie a dohodneme sa na ďalšom postupe."
    },
    registerDate: {
      title: "Dátum",
      description: "Živnosť chcem zmeniť od:",
      descriptionPause: "Živnosť chcem pozastaviť od:",
      descriptionDestroy: "Od tohto dňa už nechcem byť podnikateľom:",
      descriptionRenew: "Živnosť chcem obnoviť od:",
      since: "Od",
      until: "Do",
      asap: "Čo najskôr",
      specifically: "Ku konkrétnemu dátumu",
      sinceUntil: "Od Do"
    },
    wait: "Čakajte, prosím. Môže to trvať niekoľko sekúnd.",
    modalStreetNo: {
      title: "Súpisné číslo",
      text: "Nezabudli ste vyplniť <span style='color: red'>súpisné číslo</span>? Nájdete ho napríklad na zadnej strane OP <span style='color: red'>pred</span> lomítkom."
    },
    references: "Napísali o nás",
    satisfiedCustomer: {
      satisfied: "SPOKOJNÝ",
      customer: "ZÁKAZNÍK",
    },
    base: {
      from: "od",
      month: "mesačne",
      monthShort: "mes",
      deleteAll: "Vymazať všetky"
    },
    cashlessTransfer: {
      title: "Platobné údaje",
      bankAcc: "Číslo účtu:",
      reference: "Variabilný symbol:",
      amount: "Suma:",
      paymentInfo: "(Ak máte účet v bankách Slovenská sporiteľňa, a.s. Tatra banka, a.s. alebo VÚB, uhrádzajte prevodom na náš účet v rovnakej banke. Urýchlite tým proces.)"
    },
    services: {
      title: "Tieto služby a produkty vám pomôžu s podnikaním",
      subtitle: "Ako klient Firmárne máte nárok na skvelé zľavy a zľavové kupóny."
    },
    idNumber: {
      tooltip: "Ak nemáte pridelené slovenské rodné číslo, zadajte štyri nuly (0000)"
    },
    documents: {
      splnomocnenie: "Splnomocnenie",
      rozhodnutie: "Rozhodnutie",
      zapisnicavz: "Zápisnica",
      vyhlasenie_konatela: "Vyhlásenie konateľa",
      suhlas_vlastnika: "Súhlas vlastníka",
      'suhlas-spracovanie-udajov': "Súhlas spracovania údajov",
      zmluva_o_prevode_op: "Zmluva o prevode",
      vyhlasenie_o_doruceni_zmluvy: "Vyhlásenie o doručení zmluvy",
      vyhlasenie_spolocnika: "Vyhlásenie spoločníka",
      'suhlas-vlastnika-nehnutelnosti': "Súhlas vlastníka nehnuteľnosti",
      'zakladatelska-listina': "Zakladateľská listina",
      'spolocenska-zmluva': "Spoločenská zmluva",
      'vyhlasenie-spravcu-vkladu': "Vyhlásenie správcu vkladu",
      'vyhlasenie-zakladatela-spolocnosti': "Vyhlásenie zakladateľa spoločnosti",
      'ziadost_zalozenie_zivnosti': "Žiadosť o založenie živnosti",
      'ziadost_rozsirenie_zivnosti': "Žiadosť o rozšírenie živnosti",
      'cestne_prehlasenie_declaration': "Čestné prehlásenie",
      'prihlaska_na_zdravotne_poistenie': "Prihláška na zdravotné poistenie",
      'ziadost_zrusenie_zivnosti': "Žiadosť o zrušenie živnosti",
      'ziadost_zmeny_zivnosti': "Žiadosť o zmeny v živnosti",
      'stanovy': "Stanovy",
      'navrh': "Návrh",

      'podpisovy_vzor': "Podpisový vzor",
      'suhlas-zodp-zastupcu': "Súhlas zodpovedného zástupcu",
      'cestne_vyhlasenie_spolocnika_fo': "Čestné vyhlásenie spoločníka",
      'cestne_vyhlasenie_spolocnika_po': "Čestné vyhlásenie spoločníka",
      'ziadost_prevodca_fo': "Žiadosť prevodcu",
      'ziadost_prevodca_po': "Žiadosť prevodcu",
      'ziadost_nadobudatel_fo': "Žiadosť nadobúdateľa",
      'ziadost_nadobudatel_po': "Žiadosť nadobúdateľa",
      'podpisovy-vzor-konatel': "Podpisový vzor konateľa",
      'ziadost-du': "Žiadosť o písomný súhlas so zápisom v obchodnom registri"
    },
    uctovnik: {
      renew: {
        title: "Predĺženie platnosti profilu účtovníka",
        descYearly: "Vytvoriť objednávku na zverejnenie profilu účtovníka na ďalší jeden rok?",
        descHalfYearly: "Vytvoriť objednávku na zverejnenie profilu účtovníka na ďalších 6 mesiacov?",
        priceYearly: "Cena za zverejnenie inzerátu <strong>na 12 mesiacov - 348,- eur</strong> bez DPH, čo je v prepočte 29,- eur bez DPH za mesiac.",
        priceHalfYearly: "Cena za zverejnenie inzerátu <strong>na 6 mesiacov - 234,- eur</strong> bez DPH, čo je v prepočte 39,- eur bez DPH za mesiac",
        button: "Súhlasím"
      }
    },
    domain: {
      successTitle: "Doména {{domain}}.sk je voľná",
      errorTitle: "Doména {{domain}}.sk nie je voľná",
      successDesc: "Chcem si zaregistrovať doménu {{domain}}.sk alebo inú voľnú doménu od 9,99 €.",
      errorDesc: "Chcete si zaregistrovať doménu s iným názvom od 9,99 €?",
      successInfo: "Po dokončení objednávky dostanete email na dokončenie registrácie domény.",
      buttonText: "Chcem doménu",
      buttonTextOther: "Chcem inú doménu"
    },
    notLoggedUserModal: {
      buttons: {
        create: "VYTVORIŤ ÚČET ZADARMO",
        login: "Prihlásiť sa"
      }
    },
    generator: {
      title: "Generátor firemných názvov",
      desc: "Objavte dostupné názvy pre vašu firmu s naším bezplatným generátorom.",
      form: {
        keywords: {
          label: "KĽÚČOVÉ SLOVÁ VÁŠHO PODNIKANIA",
          placeholder: "Napr. Reality, Premium, Peter",
          hint: "Kľúčové slová oddeľte čiarkou. Napríklad doprava, rýchlosť, …"
        },
        description: {
          label: "Popis firmy",
          hint: "Rozpíšte celými vetami a čo najširšie, čomu sa bude vaša firma venovať. Napríklad aké produkty budete predávať, akým spôsobom, na akom mieste, na čo kladiete dôraz, v čom budú výnimočné a podobne."
        },
        languages: {
          label: "JAZYKY",
          placeholder: "Všetky"
        },
        business: {
          label: "TYP PODNIKANIA",
          placeholder: "Všetky"
        },
        submitButton: "VYGENEROVAŤ NÁZVY"
      },
      modal: {
        title: "Generujte názvy bez obmedzení",
        desc: "Vytvorte si svoj vlastný účet vo Firmárni zadarmo a môžete pokračovať v hľadaní toho správneho názvu pre vaše podnikanie."
      },
      checkPage: {
        title: "Overenie názvu",
        SpolocnostSRucenimObmedzenymAvailable: "Názov <strong>{{name}}</strong> je voľný.",
        SpolocnostSRucenimObmedzenymNotAvailable: "Názov <strong>{{name}}</strong> je obsadený.",
        ObcianskeZdruzenieAvailable: "Názov <strong>{{name}}</strong> je voľný.",
        ObcianskeZdruzenieNotAvailable: "Názov <strong>{{name}}</strong> je obsadený.",
        ZivnostMaybeAvailable: "Názov <strong>{{name}}</strong> je pravdepodobne voľný.",
        WebSupportSkAvailable: "Doména <strong>{{name}}.sk</strong> je voľná.",
        WebSupportComAvailable: "Doména <strong>{{name}}.com</strong> je voľná.",
        WebSupportSkNotAvailable: "Doména <strong>{{name}}.sk</strong> je obsadená.",
        WebSupportComNotAvailable: "Doména <strong>{{name}}.com</strong> je obsadená.",
        EuipoTrademarkAvailable: "Ochranná známka pre značku <strong>{{name}}</strong> je pravdepodobne voľná.",
        EuipoTrademarkNotAvailable: "Ochranná známka pre značku <strong>{{name}}</strong> je pre EÚ obsadená.",
        FacebookAvailable: "Názov <strong>{{name}}</strong> je voľný.",
        FacebookNotAvailable: "Názov <strong>{{name}}</strong> je obsadený.",
        InstagramAvailable: "Názov <strong>{{name}}</strong> je voľný.",
        InstagramNotAvailable: "Názov <strong>{{name}}</strong> je obsadený.",
        YoutubeAvailable: "Názov <strong>{{name}}</strong> je voľný.",
        YoutubeNotAvailable: "Názov <strong>{{name}}</strong> je obsadený.",
        LinkedinAvailable: "Názov <strong>{{name}}</strong> je voľný.",
        LinkedinNotAvailable: "Názov <strong>{{name}}</strong> je obsadený.",
        TwitterAvailable: "Názov <strong>{{name}}</strong> je voľný.",
        TwitterNotAvailable: "Názov <strong>{{name}}</strong> je obsadený.",
        TiktokAvailable: "Názov <strong>{{name}}</strong> je voľný.",
        TiktokNotAvailable: "Názov <strong>{{name}}</strong> je obsadený.",
        SpolocnostSRucenimObmedzenym: "SRO",
        ObcianskeZdruzenie: "Občianske združenie",
        Zivnost: "Živnosť",
        WebSupportSk: "Doména .SK",
        WebSupportCom: "Doména .COM",
        EuipoTrademark: "Ochranná známka",
        Facebook: "Facebook",
        Instagram: "Instagram",
        Youtube: "YouTube",
        Linkedin: "LinkedIn",
        Twitter: "Twitter",
        Tiktok: "TikTok",
        button: {
          SpolocnostSRucenimObmedzenym: "ZALOŽIŤ NOVÚ SRO ZA 1€",
          ObcianskeZdruzenie: "ZALOŽIŤ NOVÉ OZ",
          Zivnost: "ZALOŽIŤ NOVÚ ŽIVNOSŤ ZA 1€",
          WebSupportSk: "Registrovať doménu",
          WebSupportCom: "Registrovať doménu",
          EuipoTrademark: "CHCEM OCHRANNÚ ZNÁMKU",
          Facebook: "Založiť Facebook profil",
          Instagram: "Založiť Instagram profil",
          Youtube: "Založiť YouTube profil",
          Linkedin: "Založiť LinkedIn profil",
          Twitter: "Založiť Twitter profil",
          Tiktok: "Založiť TikTok profil"
        },
        link: {
          SpolocnostSRucenimObmedzenym: "Zmeniť názov mojej firmy",
          ObcianskeZdruzenie: "Zmeniť názov môjho OZ",
          Zivnost: "Zmeniť názov mojej živnosti",
          WebSupportSk: "Overiť inú doménu",
          WebSupportCom: "Overiť inú doménu",
          EuipoTrademark: "Overiť značku iba pre SR"
        }
      }
    }
  },
  en: {
    domain: {
      successTitle: "Doména {{domain}}.sk je voľná",
      errorTitle: "Doména {{domain}}.sk nie je voľná",
      successDesc: "Chcem si zaregistrovať doménu {{domain}}.sk alebo inú voľnú doménu od 9,99 €.",
      errorDesc: "Chcete si zaregistrovať doménu s iným názvom od 9,99 €?",
      successInfo: "Po dokončení objednávky dostanete email na dokončenie registrácie domény.",
      buttonText: "Chcem doménu",
      buttonTextOther: "Chcem inú doménu"
    },
    'Afganská islamská republika': "The Islamic Republic of Afghanistan",
    'Albánska republika': "The Republic of Albania",
    'Alžírska demokratická ľudová republika': "The People's Democratic Republic of Algeria",
    'Andorrské kniežatstvo': "The Principality of Andorra",
    'Angolská republika': "The Republic of Angola",
    'Antigua a Barbuda': "Antigua and Barbuda",
    'Argentínska republika': "The Argentine Republic",
    'Arménska republika': "The Republic of Armenia",
    'Austrálsky zväz': "The Commonwealth of Australia",
    'Azerbajdžanská republika': "The Republic of Azerbaijan",
    'Bahamské spoločenstvo': "The Commonwealth of The Bahamas",
    'Bahrajnské kráľovstvo': "The Kingdom of Bahrain",
    'Bangladéšska ľudová republika': "The People's Republic of Bangladesh",
    'Barbados': "Barbados",
    'Belgické kráľovstvo': "The Kingdom of Belgium",
    'Belize': "Belize",
    'Beninská republika': "The Republic of Benin",
    'Bhutánske kráľovstvo': "The Kingdom of Bhutan",
    'Bieloruská republika': "The Republic of Belarus",
    'Bolívijský mnohonárodný štát': "The Plurinational State of Bolivia",
    'Republika Bosny a Hercegoviny': "Bosnia and Herzegovina",
    'Botswanská republika': "The Republic of Botswana",
    'Brazílska federatívna republika': "The Federative Republic of Brazil",
    'Brunejsko-darussalamský štát': "The Nation of Brunei, the Abode of Peace",
    'Bulharská republika': "The Republic of Bulgaria",
    'Burkina Faso': "Burkina Faso",
    'Burundská republika': "The Republic of Burundi",
    'Cyperská republika': "The Republic of Cyprus",
    'Čadská republika': "The Republic of Chad",
    'Česká republika': "The Czech Republic",
    'Čiernohorská republika': "Montenegro",
    'Čilská republika': "The Republic of Chile",
    'Čínska ľudová republika': "The People's Republic of China",
    'Čínska republika (Taiwan)': "The Republic of China",
    'Dánske kráľovstvo': "The Kingdom of Denmark",
    'Dominické spoločenstvo': "The Commonwealth of Dominica",
    'Dominikánska republika': "The Dominican Republic",
    'Džibutská republika': "The Republic of Djibouti",
    'Egyptská arabská republika': "The Arab Republic of Egypt",
    'Ekvádorská republika': "The Republic of Ecuador",
    'Eritrejský štát': "The State of Eritrea",
    'Estónska republika': "The Republic of Estonia",
    'Etiópska federatívna demokratická republika': "The Federal Democratic Republic of Ethiopia",
    'Republika Fidžijských ostrovov': "The Republic of Fiji",
    'Filipínska republika': "The Republic of the Philippines",
    'Fínska republika': "The Republic of Finland",
    'Francúzska republika': "The French Republic",
    'Gabonská republika': "The Gabonese Republic",
    'Gambijská republika': "The Republic of The Gambia",
    'Ghanská republika': "The Republic of Ghana",
    'Grécka republika': "The Hellenic Republic",
    'Grenada': "Grenada",
    'Gruzínsko': "Georgia",
    'Guatemalská republika': "The Republic of Guatemala",
    'Guinejská republika': "The Republic of Guinea",
    'Guinejsko-bissauská republika': "The Republic of Guinea-Bissau",
    'Guyanská kooperatívna republika': "The Co-operative Republic of Guyana",
    'Haitská republika': "The Republic of Haiti",
    'Holandské kráľovstvo': "The Kingdom of the Netherlands",
    'Honduraská republika': "The Republic of Honduras",
    'Chorvátska republika': "The Republic of Croatia",
    'Indická republika': "The Republic of India",
    'Indonézska republika': "The Republic of Indonesia",
    'Iracká republika': "The Republic of Iraq",
    'Iránska islamská republika': "The Islamic Republic of Iran",
    'Írsko': "Ireland",
    'Islandská republika': "Iceland",
    'Izraelský štát': "The State of Israel",
    'Jamajka': "Jamaica",
    'Japonsko': "Japan",
    'Jemenská republika': "The Republic of Yemen",
    'Jordánske hášimovské kráľovstvo': "The Hashemite Kingdom of Jordan",
    'Juhoafrická republika': "The Republic of South Africa",
    'Republika Južný Sudán': "The Republic of South Sudan",
    'Kambodžské kráľovstvo': "The Kingdom of Cambodia",
    'Kamerunská republika': "The Republic of Cameroon",
    'Kanada': "Canada",
    'Kapverdská republika': "The Republic of Cabo Verde",
    'Katarský štát': "The State of Qatar",
    'Kazašská republika': "The Republic of Kazakhstan",
    'Kenská republika': "The Republic of Kenya",
    'Kirgizská republika': "The Kyrgyz Republic",
    'Kiribatská republika': "The Republic of Kiribati",
    'Kolumbijská republika': "The Republic of Colombia",
    'Komorský zväz': "The Union of the Comoros",
    'Konžská republika': "The Republic of the Congo",
    'Konžská demokratická republika': "The Democratic Republic of the Congo",
    'Kórejská ľudovodemokratická republika': "The Democratic People's Republic of Korea",
    'Kórejská republika': "The Republic of Korea",
    'Kostarická republika': "The Republic of Costa Rica",
    'Kubánska republika': "The Republic of Cuba",
    'Kuvajtský štát': "The State of Kuwait",
    'Laoská ľudovodemokratická republika': "The Lao People's Democratic Republic",
    'Lesothské kráľovstvo': "The Kingdom of Lesotho",
    'Libanonská republika': "The Lebanese Republic",
    'Libérijská republika': "The Republic of Liberia",
    'Líbya': "The State of Libya",
    'Lichtenštajnské kniežatstvo': "The Principality of Liechtenstein",
    'Litovská republika': "The Republic of Lithuania",
    'Lotyšská republika': "The Republic of Latvia",
    'Luxemburské veľkovojvodstvo': "The Grand Duchy of Luxembourg",
    'Macedónska republika': "Republic of North Macedonia",
    'Madagaskarská republika': "The Republic of Madagascar",
    'Maďarsko': "Hungary",
    'Malajzia': "Malaysia",
    'Malawijská republika': "The Republic of Malawi",
    'Maldivská republika': "The Republic of Maldives",
    'Malijská republika': "The Republic of Mali",
    'Maltská republika': "The Republic of Malta",
    'Marocké kráľovstvo': "The Kingdom of Morocco",
    'Republika Marshallových ostrovov': "The Republic of the Marshall Islands",
    'Maurícijská republika': "The Republic of Mauritius",
    'Mauritánska islamská republika': "The Islamic Republic of Mauritania",
    'Spojené štáty mexické': "The United Mexican States",
    'Mikronézske federatívne štáty': "The Federated States of Micronesia",
    'Mjanmarský zväz': "The Republic of the Union of Myanmar",
    'Moldavská republika': "The Republic of Moldova",
    'Monacké kniežatstvo': "The Principality of Monaco",
    'Mongolsko': "The State of Mongolia",
    'Mozambická republika': "The Republic of Mozambique",
    'Namíbijská republika': "The Republic of Namibia",
    'Nauruská republika': "The Republic of Nauru",
    'Nemecká spolková republika': "The Federal Republic of Germany",
    'Nepálska federatívna demokratická republika': "The Federal Democratic Republic of Nepal",
    'Nigerská republika': "The Republic of the Niger",
    'Nigérijská federatívna republika': "The Federal Republic of Nigeria",
    'Nikaragujská republika': "The Republic of Nicaragua",
    'Nórske kráľovstvo': "The Kingdom of Norway",
    'Nový Zéland': "New Zealand",
    'Ománsky sultanát': "The Sultanate of Oman",
    'Pakistanská islamská republika': "The Islamic Republic of Pakistan",
    'Palauská republika': "The Republic of Palau",
    'Palestínska národná samospráva': "The State of Palestine",
    'Panamská republika': "The Republic of Panamá",
    'Papua-Nová Guinea': "The Independent State of Papua New Guinea",
    'Paraguajská republika': "The Republic of Paraguay",
    'Peruánska republika': "The Republic of Perú",
    'Republika Pobrežia Slonoviny': "The Republic of Côte d'Ivoire",
    'Poľská republika': "The Republic of Poland",
    'Portugalská republika': "The Portuguese Republic",
    'Rakúska republika': "The Republic of Austria",
    'Republika Rovníkovej Guiney': "The Republic of Equatorial Guinea",
    'Rumunsko': "Romania",
    'Ruská federácia': "The Russian Federation",
    'Rwandská republika': "The Republic of Rwanda",
    'Salvádorská republika': "The Republic of El Salvador",
    'Samojský nezávislý štát': "The Independent State of Samoa",
    'Sanmarínska republika': "The Republic of San Marino",
    'Saudskoarabské kráľovstvo': "The Kingdom of Saudi Arabia",
    'Senegalská republika': "The Republic of Senegal",
    'Seychelská republika': "The Republic of Seychelles",
    'Sierraleonská republika': "The Republic of Sierra Leone",
    'Singapurská republika': "The Republic of Singapore",
    'Slovenská republika': "The Slovak Republic",
    'Slovinská republika': "The Republic of Slovenia",
    'Somálska republika': "The Federal Republic of Somalia",
    'Spojené arabské emiráty': "The United Arab Emirates",
    'Spojené kráľovstvo Veľkej Británie a Severného Írska': "The United Kingdom of Great Britain and Northern Ireland",
    'Spojené štáty americké': "The United States of America",
    'Srbská republika': "The Republic of Serbia",
    'Srílanská demokratická socialistická republika': "The Democratic Socialist Republic of Sri Lanka",
    'Stredoafrická republika': "The Central African Republic",
    'Sudánska republika': "The Republic of the Sudan",
    'Surinamská republika': "The Republic of Suriname",
    'Svazijské kráľovstvo': "The Kingdom of Eswatini",
    'Svätá Lucia': "Saint Lucia",
    'Federácia Svätého Krištofa a Nevisu': "Saint Kitts and Nevis",
    'Demokratická republika Svätého Tomáša a Princovho ostrova': "The Democratic Republic of São Tomé and Príncipe",
    'Svätý Vincent a Grenadíny': "Saint Vincent and the Grenadines",
    'Sýrska arabská republika': "The Syrian Arab Republic",
    'Šalamúnove ostrovy': "The Solomon Islands",
    'Španielske kráľovstvo': "The Kingdom of Spain",
    'Švajčiarska konfederácia': "The Swiss Confederation",
    'Švédske kráľovstvo': "The Kingdom of Sweden",
    'Tadžická republika': "The Republic of Tajikistan",
    'Talianska republika': "The Italian Republic",
    'Tanzánijská zjednotená republika': "The United Republic of Tanzania",
    'Thajské kráľovstvo': "The Kingdom of Thailand",
    'Togská republika': "The Togolese Republic",
    'Tongské kráľovstvo': "The Kingdom of Tonga",
    'Republika Trinidadu a Tobaga': "The Republic of Trinidad and Tobago",
    'Tuniská republika': "The Republic of Tunisia",
    'Turecká republika': "The Republic of Turkey",
    'Turkménsko': "Turkmenistan",
    'Tuvalu': "Tuvalu",
    'Ugandská republika': "The Republic of Uganda",
    'Ukrajina': "Ukraine",
    'Uruguajská východná republika': "The Oriental Republic of Uruguay",
    'Uzbecká republika': "The Republic of Uzbekistan",
    'Vanuatská republika': "The Republic of Vanuatu",
    'Svätá stolica (Vatikánsky mestský štát)': "The Holy See",
    'Venezuelská bolívarovská republika': "The Bolivarian Republic of Venezuela",
    'Vietnamská socialistická republika': "The Socialist Republic of Viet Nam",
    'Východotimorská demokratická republika': "The Democratic Republic of Timor-Leste",
    'Zambijská republika': "The Republic of Zambia",
    'Západná Sahara': "The Sahrawi Arab Democratic Republic",
    'Zimbabwianska republika': "The Republic of Zimbabwe",
    nav: {
      sro: {
        dropdown: "Limited liability company",
        zalozenie: "Establishment of a LIMITED LIABILITY COMPANY",
        jednoosobova: "One-Person Limited Liability Company",
        zmeny: "Changes to a LIMITED LIABILITY COMPANY",
        zmenyRc: "Birth no. registration",
        readymade: "Ready-made companies",
        dokumenty: "Documents for the establishment of a LIMITED LIABILITY COMPANY",
        zrusenie: "Winding up and liquidation of a LIMITED LIABILITY COMPANY",
        burza: "Sell a company"
      },
      zivnosti: {
        dropdown: "Traders",
        zalozenie: "Založenie živnosti",
        zalozenieEN: "Setting up of a trade",
        student: "Student trade",
        rozsirenie: "Extension of a trade",
        zrusenie: "Winding up of a trade",
        pozastavenie: "Suspension of a trade",
        obnovenie: "Renewal of a trade",
        zmeny: "Changes to a trade"
      },
      obcianske: {
        dropdown: "Civic associations",
        obcianske: "Establishment of a civic association",
        ussobcianske: "DIY: Establishment of a civic association",
        readymadeobcianske: "Ready-made civic associations"
      },
      uctovnici: "Accountants",
      sluzby: {
        dropdown: "Other services",
        burza: "Market",
        sidlo: "Registered seat",
        generator: "Name Generator"
      },
      kuv: "Ultimate beneficial owner",
      video: "Tutorials",
      uss: {
        dropdown: "Do it yourself",
        oz: "DIY - Civil Association inc.",
        bydlisko: "DIY - Personal Address change",
        rodcislo: "Birth no. registration"
      },
      cennik: "Price list",
      onas: {
        dropdown: "About us",
        podmienky: "Business terms and conditions",
        referencie: "References",
        napisalionas: "Press centre",
        akofunguje: "How does it work",
        faq: "Frequently asked questions",
        vzory: "Templates",
        ochranaudajov: "Terms and conditions of personal data protection"
      },
      blog: "Blog",
      kontakt: "Contact",
      new: "NEW"
    },
    upsells: {
      title: "Doplnkové služby",
      konverzia: {
        headerZivnost: "Osvedčenie v papierovej podobe",
        headerZmenyZivnosti: "Potvrdenie v papierovej podobe",
        header: "Výpis z obchodného registra v papierovej podobe",
        description: "Potrebujete to v papierovej podobe? Živnostenský úrad zasiela svoje rozhodnutia elektronicky.",
        moreInfo: "Papierovú verziu by ste už nemali pre úradný styk potrebovať. Ak ju však od vás vyžaduje obchodný partner alebo banka, môžeme vám vyhotoviť tzv. konverziu dokumentu z elektronickej do listinnej podoby."
      },
      superfaktura: {
        header: "Vyskúšajte overený fakturačný program úplne zadarmo až na 90 dní",
        description: "Ľahko a rýchlo vystavíte faktúry a cenové ponuky.",
        moreInfo: "Pre svojich klientov sme vybavili <b>extra predĺžené skúšobné obdobie z 30 až na 90 dní</b>. Počas tejto doby si môžete vyskúšať, či vám SuperFaktúra vyhovuje. Po skončení skúšobného obdobia sa môžete sami rozhodnúť, či si tento nástroj ponecháte za 4,99 eur mesačne.<br><br><strong>Zakliknutím tejto možnosti si automaticky vytvoríte konto v SuperFaktúre.</strong> Potvrdzujúci email s potrebnými informáciami očakávajte po zaregistrovaní vašej živnosti alebo firmy.",
        agreeMemorandum: "Odoslaním tohto formulára dôjde k spracúvaniu vašich osobných údajov a k súhlasu so všeobecnými obchodnými podmienkami. Bližšie informácie nájdete v dokumente <a href='https://www.superfaktura.sk/ochrana-sukromia/' target='_blank'>Pravidlá ochrany osobných údajov.</a> *",
        agreeExternal: "<a href='https://www.superfaktura.sk/podmienky-pouzivania/' target='_blank'>Súhlas so všeobecnými obchodnými podmienkami a so spracovaním osobných údajov pre účely informovania o produktoch, službách a inováciách. Zároveň vyhlasujem, že mám 16 a viac rokov.</a>",
      },
      znamka: {
        header: "Ochranná známka",
        description: "Chcete získať informácie o registrácii ochrannej známky na váš názov alebo produkt? Slovenskú ochrannú známku môžete mať už od 196 eur.",
        moreInfo: "Po zakliknutí tejto možnosti vás budeme kontaktovať telefonicky alebo emailom a poskytneme vám všetky informácie, ktoré budete potrebovať pre registráciu ochrannej známky.",
        agreeExternal: "Súhlasím s poskytnutím emailovej adresy a tel. čísla spoločnosti LEGALIA, advokátska kancelária, s. r. o. pre účel <strong>informovania o možnostiach registrácie ochrannej známky.</strong> Bližšie informácie nájdete v dokumente <a href='/o-nas/vop-firmaren' target='_blank'>Pravidlá ochrany osobných údajov.</a>"
      },
      citacka: {
        header: "Čítačka pre váš občiansky preukaz (eID)",
        description: "Podnikatelia sú povinní komunikovať so štátom elektronicky. Na to potrebujete nový občiansky preukaz a čítačku k nemu.",
        moreInfo: "Ak ju ešte nemáte, môžete si ju zabezpečiť aj cez Firmáreň. Cena je 38 eur bez DPH a zahŕňa poštovné v rámci SR. (Gemalto IDBridge CT 30 (USB007))",
      },
      saltpay: {
        header: "Platobný terminál, kasa a tlačiareň (3 v jednom) pre každú prevádzku",
        description: "Moderné, rýchle a multifunkčné zariadenie, s ktorým budete mať peniaze na účte už nasledujúci pracovný deň.",
        moreInfo: "<strong>Spracovávajte objednávky a prijímajte platby kartou pomocou jediného zariadenia Storyous All-in-One:</strong><ul><li>Tri v jednom - terminál, tlačiareň aj e-kasa</li><li>Peniaze vám pristanú na účet už nasledujúci pracovný deň</li><li>Budete dostávať denné výpisy zúčtovania, aby ste netrávili hodiny času počítaním tržieb</li><li>Na jedno nabitie vydrží celú zmenu (až 10 hodín)</li><li>Umožňuje bezkontaktné aj klasické platby kartou</li><li>K internetu sa pripojí cez SIM alebo Wi-Fi</li><li>Elegantný a odolný hardware - PAX A920</li><li>Prehľad objednávok a platieb na jednom portáli</li><li>Výstupy do účtovných programov Pohoda a Flexibee</li></ul>Zariadenie Storyous All-in-One plne nahradí<strong>pokladničný systém, platobný terminál aj tlačiareň</strong>. Multifunkčné zariadenie, s ktorým budete mať objednávky a platby pod kontrolou. Terminál je možné nastaviť do 10 minút bez akýchkoľvek zložitých zmlúv.<br /><br /><strong>Bonus pre zákazníkov Firmárne</strong>: K platobnému terminálu nabíjacia stanica, dátová SIM, doručenie a obchodný portál zdarma.<br /><br /><strong>Ak sa chcete dozvedieť viac</strong>, pridajte si aj túto možnosť. Pracovníci spoločnosti Teya vám potom telefonicky alebo emailom poskytnú všetky potrebné informácie. Bezplatne a nezáväzne.<br /><br />",
        agreeExternal: "<a href='https://legal.teya.com/slovakia/dpa' target='_blank'>Podmienky spracovania údajov</a>"
      }
    },
    order: {
      name: "Name and surname *",
      email: "E-mail *",
      phoneNumber: "Telephone number *",
      phoneNumberTooltip: "For contacting the client in case of any questions or corrections of potential mistakes.",
      phoneNumberPlaceholder: "9xx xxx xxx",
      comment: "Note",
      commentPlaceholder: "",
      button: "Send",
      titleDestroy: "Unbinding order",
      success: "Your message has been sent successfully"
    },
    cashlessTransfer: {
      title: "Payment method",
      bankAcc: "Bank account no.:",
      reference: "Payment reference:",
      amount: "Amount:",
      paymentInfo: "(Use the Internet Banking or Credit/Debit Card payment for speeding up the process)"
    },
    services: {
      title: "These services and products can help you in business",
      subtitle: "As the client of Firmaren, you are entitled for discounts and coupons."
    },
    login: {
      label: "MY COMPANY",
      title: "Log into Firmáreň",
      subtitle: "Please fill in the data below.",
      titleForgotPw: "Forgotten password",
      email: "E-mail",
      password: "Password",
      forgot: "Forgotten your password?",
      btn: "Log in",
      back: "Back to log in",
      btnBack: "Back",
      register: "Register yourself",
      notUser: "Still no account?",
      logout: "Log out",
      error: "The e-mail or password you entered was incorrect"
    },
    actions: {
      sro: "Establish a LIMITED LIABILITY COMPANY",
      zivnost: "Set up a trade"
    },
    footer: {
      subscribe: "Enjoy occasional business-related updates.",
      subTitle: "No spam, we promise!",
      pay: "On this portal, you can pay via:",
      secure: "Data protection is ensured by:",
      socialMedia: "Social media",
      contact: "Contact"
    },
    businessSubjects: {
      title: "List of scopes of trades",
      subtitle: "Official list of the Ministry of Interior",

      required: "You must choose at least one trade",
      mainSubject: "Core business activity",

      categories: {
        graphic: "Graphics and design",
        blog: "Blogging, Vlogging and copywriting",
        handmade: "Hand-made and decorations",
        bar: "Café and bar",
        restaurant: "Restaurant",
        bakery: "Bakery and confectionery production",
        traffic: "Transport",
        administration: "Administrative services",
        architecture: "Construction",
        science: "Science, research and projects",
        agriculture: "Agriculture",
        clothing: "Clothing",
        accounting: "Bookkeeping",
        health: "Healthy lifestyle",
        language: "Languages – translation and teaching",
        finance: "Financial sector",
        advertisement: "Advertising, marketing and surveys",
        software: "Software and hardware",
        course: "Trainings and courses",
        courier: "Courier and delivery service",
        eshop: "E-shop",
        business: "Business activity"
      },

      tableHeader: {
        name: "Business scope name",
        type: "Type",
        price: "Price",
        info: "More information",
        add: "Add",
        remove: "Delete"
      },

      type: {
        0: "Free",
        1: "Craft",
        2: "Regulated",
        3: "Other"
      },

      summary: {
        header: "Selected scopes of business",
        totalPrice: "Total price"
      },

      groups: {
        mostUsed: {
          title: "Most frequently entered",
          subtitle: "business activity"
        },
        eshop: {
          title: "E-shop",
          subtitle: "trades suitable for e-shops"
        },
        free: "Free trades",
        craft: "Craft trades",
        bound: "Regulated trades"
      }
    },
    help: {
      email: "help@firmaren.sk",
      phone: "02 55 56 77 73"
    },
    base: {
      from: "from",
      month: "month",
      monthShort: "month",
      deleteAll: "Delete all"
    },
    btn: {
      back: "Back",
      cancel: "Cancel",
      continue: "Continue to",
      saveForLater: "Save and finish later",
      finish: "Finish",
      downloadProforma: "Download pro forma invoice",
      download: "Download",
      downloadInvoice: "Download invoice",
      downloadDocs: "Download documents",
      downloadDocsZip: "Download all (ZIP)",
      pay: "Pay",
      saveScope: "Save the scope of business"
    },
    cart: {
      price: "FINAL PRICE",
      finalPrice: "Final price",
      placeOfWork: "Place of business",
      perMonth: "/month",
      perMonthWhole: "/month",
      selected: "Selected trades"
    },
    companyName: {
      placeholder: "E.g. Flower shop on the corner"
    },
    personalInfo: {
      label: "Customer (Select from the list)"
    },
    wait: "Please wait. It may take several seconds.",
    modalStreetNo: {
      title: "Registration number",
      text: "Have you filled in the real estate registration number? You may find it e.g. on the back of your ID card before the slash/oblique."
    },
    satisfiedCustomer: {
      satisfied: "Satisfied",
      customer: "customer",
    },
    responsiblePerson: {
      title: "Representative *",
      chooseType: "Choose a person who will act as a representative and who meets the conditions for operating a trade (e.g. education, work experience, etc.).",
      trader: "Sole trader",
      other: "Other person",
      establishment: {
        title: "Establishment",
        no: "No",
        yes: "Yes (used when you want to perform your business activities at any other place than your registered office)"
      },
      type: {
        own: "Sole trader",
        other: "Other person"
      }
    },
    address: {
      streetPlaceholder: "Street name"
    },
    references: "References",
    finishLater: {
      title: "Finish later",
      cancel: "Cancel",
      submit: "Submit",
      email: "E-mail",
      info: "Please enter an e-mail address to where we can send you a link to be able to return to your order.",
      sendInfo: "We have sent you a link to the e-mail address you provided. You may return to your unfinished order at anytime using the link."
    },
    editLink: "Edit",
    validation: {
      required: "Required data",
      pattern: "Wrong format",
      email: "Wrong format",
      passwordPattern: "Password must contain at least 8 characters",
      mask: "Wrong format",
      emailUnique: "E-mail already in use",
      emailInvalid: "Incorrect name or password",
      couponUsed: "The coupon has already been used",
      couponExpired: "The coupon has expired",
      couponInvalid: "The coupon is invalid",
      requiredMessage: "Some of the required fields have not been filled in",
      requiredCheckbox: "This field is required",
      typeaheadEditable: "Choose an option"
    },
    sendInvoice: "I prefer the invoice to be sent by e-mail.",
    registerDate: {
      title: "Date",
      asap: "ASAP",
      specifically: "On a specific date"
    },
    contact: {
      logged: "Logged-in as:",
      email: "E-mail (serves as username) *",
      password: "Password for your profile in Firmáreň*",
      passwordExists: "Login password*",
      userExist: "You have used this e-mail address in Firmáreň before. Enter the password you used on the portal before."
    },
    confirmDelete: {
      title: "Confirm deletion",
      cancel: "Cancel",
      submit: "Submit",
      info: "Do you really wish to delete this business activity?",
      infoall: "Do you really wish to delete all business activities?"
    },
    representation: "I want to be represented free of charge by LEGALIA, adv. kanc., s. r. o. before the competent registration authority.",
    representationTooltip: "What does this mean? If you check this option, Firmáreň will do all necessary actions for you, including filing your application/proposal free of charge at a competent authority, which needs to be done by a law firm. If you do not check this option, it means that you have decided to file your application/proposal at a competent authority on your own or through another authorized person, so you will finish the process alone.",
    conditions: {
      start: "I agree with",
      link: "the general service provision terms and conditions"
    },
    contactme: {
      label: "Žiadam o telefonické kontaktovanie po odoslaní objednávky.",
      tooltip: "Ak si neželáte, aby sme vás po zadaní objednávky telefonicky kontaktovali, zrušte predvolené zakliknutie tejto možnosti. V záujme správneho a rýchleho vybavenia vašej objednávky vám však aj napriek tomu môžeme telefonovať v tých prípadoch, kedy si to bude vyžadovať neštandardná situácia (napr. potreba dodatočného získania alebo overenia niektorých informácií v objednávke a podobne)."
    },
    orderFinishInfo: "Your order becomes active after it has been paid.",
    payments: {
      header: "Thank you",
      orderReceivedStart: "Your order No.",
      orderReceivedEnd: "has been received.",
      thanks: "We value your trust.",
      movement: "Cashless transfer or deposit",
      help: "Money-back guarantee",
      card: "Payment with a card ",
      guide: {
        payment: {
          header: "Payment",
          text: "Please make your payment using one of the forms indicated on the right.",
          item1: "After the payment has been made, the system will generate a proper invoice which may be used as a legal tax and accounting document.",
          item2: "When establishing a new company (trade or limited liability company), you may also use a private bank account of the founder to make the payment – the expense is recognised as a proper expense of the new company."
        },
        signing: {
          header: "Sign and send",
          text: "The last thing to do to complete your order: Sign, scan and e-mail us the document which we have sent to your e-mail address (you may also download it from this page by clicking on the button below). We have just started to work on your order.",
        },
        thanks: {
          header: "Firmáreň",
          text: "firmáreň.sk will do the rest. Thank you for trusting us."
        }
      },
      documents: {
        zivnost: {
          zaloz: "Download your filled-in application (or other documents) for setting up your trade"
        },
        general: "Download the documents here"
      }
    },
    founder: {
      titleBefore: "Degree before name",
      name: "Name *",
      surname: "Surname *",
      titleAfter: "Degree after name",

      address: {
        country: "Country *"
      },
      dateOfBirth: "Date of birth *",
      dayPlaceholder: "day",
      monthPlaceholder: "month",
      yearPlaceholder: "year",
      idNumber: "Personal identification number *",
      otherInfo: {
        header: "Data for the criminal record extract",
        help: "Based on the following data, the Trade Licensing Office will itself request an extract from the criminal record of a citizen of the Slovak Republic directly from the General Prosecutor's Office of the Slovak Republic. This extract shall prove the integrity of the sole trader under the Trade Licensing Act.",
        placeOfBirth: "Place of birth *",
        nationality: "Nationality *",
        birthSurname: "Maiden name *",
        formerSurname: "Former surname",
        father: {
          name: "Father's name *",
          surname: "Father's surname *"
        },
        mother: {
          name: "Mother's name *",
          surname: "Mother's surname *",
          birthSurname: "Mother's maiden name *"
        },
        cityPlaceholder: "E.g. Bratislava",
        nationalityPlaceholder: "E.g. Slovak",
      }
    },

    name: {
      titleBefore: "Degree before name",
      name: "Name",
      surname: "Surname",
      titleAfter: "Degree after name",
      gender: "Gender"
    },

    addressAutocomplete: {
      street: "Permanent residential address",
      streetSquare: "Street",
      companyStreet: "Street",
      streetPlaceholder: "Street name",
      otherStreet: "Address",
      streetSR: "Permanent address in SR",
      streetTooltip: "Meaning a <b>permanent</b> residence address in your <b>home country</b>. Enter the name of the street or public area (square) exactly as stated on your ID card. If the town is not divided into streets, enter the name of the town in this field.",
      streetNo: "Registration number/street number",
      streetNoPrefixTooltip: "It is a number written on buildings usually in black colour. It is written before a slash/oblique in addresses on ID cards.",
      streetNoTooltip: "It is a number written on buildings usually in red colour. It is written after a slash/oblique in addresses on ID cards. If a municipality is not divided into streets, there are no street numbers - In such case, enter the real estate registration number in this field as well.",
      city: "Town",
      cityPlaceholder: "E.g. Kiev",
      zipCode: "Postal Code",
      country: "Country",
      streetNoPrefixPopover: "If you have a real estate registration number on your ID card, enter it here.",
    },
    gender: {
      male: "Male",
      female: "Female"
    },
    paymentInfo: {
      title: "Invoicing details",
      label: "Services of the portal shall be invoiced to",
      ico: "COMPANY ID NO (IČO)",
      companyName: "BUSINESS NAME",
      name: "Name and Surname / BUSINESS NAME",
      dic: "TAX ID NO. (DIČ)",
      icDph: "VAT ID NO. (IČ DPH)",
      country: "Country",
      same: "An invoice will be issued to the establishing sole trader",
      other: "Other person",
      address: {
        street: "Street/square",
        streetAndNumber: "Street/square and number",
        streetNo: "Registration/street number",
        city: "Town",
        zipCode: "Postal code",
        country: "Country"
      }
    },

    zalozenieZivnosti: {
      medzikrok: {
        header: "Možno budete potrebovať",
        description: "Vybrali sme pre vás najvýhodnejšie ponuky služieb na trhu,<br> ktoré ako podnikateľ budete potrebovať."
      },
      landing: {
        title: "SET UP A TRADE (ŽIVNOSŤ) COMFORTABLY",
        subtitle: "Do not waste your time or money, set up a trade online and become a sole trader! Without the need to spend long hours at offices, and at least at half of the price of the traditional way. No eID is required.",
        btnCreate: "I want to open a trade",
        btnCreateInfo: "It only takes 10 minutes",
        btnMoreInfo: "More information",
        howWork: {
          title: "How does it work?",
          createTitle: "Order",
          createInfo: "Click on the green button below “I want to open a trade.” Fill out the form, in which you choose your business activities and fill in all the necessary information. It only takes a few minutes to create an order for a trade set up.",
          sendTitle: "Order submission",
          sendInfo: "After creating the order for a trade set up, we will send you an e-mail with a completed application. You just need to sign, scan and send it back to us. If you have an ID card with a chip, you don’t even need to print it.",
          payTitle: "Payment",
          payInfo: "Stamp duties are no longer needed – choose an online method of payment. After paying the fee, we will process your application for starting up a trade, and you will be able to do business as early as on the second working day. You will obtain your trade licence by e-mail in electronic form within approximately three working days.",
          registerTitle: "Registration of a trade",
          registerInfo: "Together with registration at the Trade Licence Office we will automatically send an entity establishment notification to the Tax Authority (Tax ID No. card) and to your health insurance company, if in Slovakia. No extra charge.",
        },
        howMuch: {
          title: "How much will I pay for setting up a trade via Firmáreň?",
          price: "Slovak resident: only €19<br />Non resident in SR: only €69",
          price6free: "Price in case of 6 free trades",
          price10free: "Price in case of 10 free trades",
          moreFree: "(You can choose even more)",
          specialTrade: "Regulated/craft trade",
          office: "AT THE OFFICE",
          firmaren: "VIA FIRMÁREŇ",
          save: "HOW MUCH WILL YOU SAVE",
          unlimited: "Price includes an unlimited number",
          underInfo: "<b>The price of €19/€60 is final</b> and includes <b>an unlimited number of free business areas</b> registration of the trade with the Tax Office (Tax ID No.) and notification the health insurance<br />company (if in Slovakia)."
        },
        advantages: {
          title: "Advantages of opening a trade online",
          timeTitle: "Saving your time",
          timeInfo: "It only takes a few minutes to open a trade online, you don't need to take a day off and spend hours going around different offices. Neither you need a special slovak eID. You will obtain your trade licence electronically in about three to five days.",
          moneyTitle: "Saving your money",
          moneyInfo: "By setting up a trade online, you will save at least 50% of the costs of registration with the Trade Licensing Office. The price also includes an unlimited number of free trades, so there are no extra fees.",
          nervesTitle: "Saving your nerves",
          nervesInfo: "Before, to  become a freelancer, you had to visit the Trade Licensing Office in person and only during its office hours. Now  you can open a sole trade online anywhere and at anytime.",
          homeTitle: "Comfortably from your home",
          homeInfo: "Thanks to the online trade registration, you do not need to visit any offices. All necessary documents will be sent to you electronically.",
          payTitle: "Easy payment",
          payInfo: "You no longer need to pay by stamp duties. You can pay by credit card or via internet banking easily and comfortably from your own home.",
          moreTitle: "Further advantages",
          moreInfo: "Our services do not end after establishment of a trade: Firmáreň offers you a unique opportunity to modify your trade electronically, without any paperwork, even without an electronic signature. Expansion, suspension of or other changes to the trade with just a few clicks and at preferential prices. There are also other bonuses which you will like.",
        },
        satisCustomers: "Satisfied customers",
        btnMoreCustomers: "More",
        statsBefore: "",
        statsAfter: "entrepreneurs have already started their own businesses with Firmáreň",
        waste: "DO NOT WASTE YOUR TIME OR MONEY AND SET UP A TRADE ONLINE!",
        unbinding: "Unbinding order"
      },
      title: "Online setting up of a trade | Firmáreň",

      krok1: {
        label: "Scope of business",

        header: "What do you want to do business in?",
        subheader: "First, enter your core business activity.",

        placeholder: "Enter the scope of business, activity, keyword here (e.g. Trading activity)",
        btnHelp: "How to choose a trade?",
        or: "Or",
        btnList: "Select from the list",
        continue: "Continue",
        save: "Save and continue later",

        suggestedGroups: {
          title: "Groups of scopes of business for some professions"
        },

        guide: {
          title: "Instructions for setting up a trade",
          order: {
            title: "Order",
            text: "Choose trades in which you want to do business and place this order."
          },
          sign: {
            title: "Sign",
            text: "By pressing return, the system will generate a completed application, which you just need to sign, scan and send to us by e-mail."
          },
          send: {
            title: "Send",
            text: "You may start to perform your business activities on the next business day after your order has been activated." // todo: missing rest of the text
          }
        },
        popup: {
          firstSubject: "Added as the core scope of business",
          anotherSubjects: "Added",
          removeSubject: "Deleted from the selected scopes of business"
        },
        title: {
          add: "Click to add to the selected scopes of business",
          remove: "Click to delete the scope of business"
        }
      },

      krok2: {
        label: "Business data",

        header: "Fill in the entrepreneur's data",
        subheader: "* marked fields are mandatory",

        changeLanguage: {
          title: "Osoba s trvalým bydliskom na území SR",
          text: "<strong>Osoba s trvalým bydliskom na území SR. </strong> Budete presmerovaný na formulár pre Slovenskú republiku, ktorý sa týka osôb s trvalým bydliskom na území SR.<br /><br /><strong>A citizen with permanent residence in the Slovak Republic.</strong> You will be redirected to a Slovak form, which applies to persons who have been granted permanent residence in the territory of the Slovak Republic."
        },

        personalInfo: {
          title: "Personal data",
          gender: "Gender *",
          titleBefore: "Degree before name",
          name: "Name *",
          surname: "Surname *",
          titleAfter: "Degree after name",
          address: {
            street: "Permanent residential address *",
            streetTooltip: "Meaning a <b>permanent</b> residence address in your <b>home country</b>. Enter the name of the street or public area (square) exactly as stated on your ID card. If the town is not divided into streets, enter the name of the town in this field.",
            streetNo: "Registration number/street number *",
            streetNoPrefixTooltip: "The registration number field is not mandatory",
            streetNoTooltip: "Enter the registration number or also the street number according to the residential address stated on your identity card (ID card).",
            city: "Town *",
            cityPlaceholder: "E.g. Kiev",
            zipCode: "Postal Code *",
            country: "Country *"
          },
          phoneNumber: "Telephone number *",
          phoneNumberPlaceholder: "9xx xxx xxx",
          phoneNumberTooltip: "For contacting the client in case of any questions or corrections of eventual errors.",
          dateOfBirth: "Date of birth *",
          dayPlaceholder: "day",
          monthPlaceholder: "month",
          yearPlaceholder: "year",
          idNumber: "Personal identification number",
          foreigner: {
            passportNumber: "Passport or ID number *",
            nationality: "Nationality *",
            street: "Address of temporary residence in the Slovak Republic",
            streetNo: "Registration number/street number",
            zipCode: "Postal code",
            city: "Town",
            mandate: "Authorised representative for mail (must be a person with permanent residence in Slovakia)",
            fo: "Natural person with permanent residence in the Slovak Republic",
            foTooltip: "Foreign persons must notify the Trade Licence Office of a person with permanent address in SR who will take over official consignments on behalf of the foreign person if they cannot be delivered to the foreign person directly. – State a person you trust.",
            po: "Legal person with its registered seat in the Slovak Republic",
            poTooltip: "Foreign persons must notify the Trade Licence Office of a Slovak person who will take over official consignments on behalf of the foreign person if they cannot be delivered to the foreign person directly. A legal entity with registered office in SR (e.g. limited liability company, civic organisation, etc.) may also be such proxy. – State a person you trust.",
            firmaren: "Firmáreň (€40)",
            firmarenTooltip: "Foreign persons must notify the Trade Licence Office of a Slovak person who will take over official consignments on behalf of the foreign person if they cannot be delivered to the foreign person directly. Firmáreň may also be such proxy. The price of the service is EUR 40.",
            ico: "COMPANY ID NO. (IČO) *",
            companyName: "BUSINESS NAME *"
          }
        },

        registerDate: {
          description: "I want to set up a trade from:"
        },

        contractorInfo: {
          title: "Entrepreneur's data",
          name: "Business name",
          ico: "Company ID (IČO) - if already issued",
          insuranceCompany: "Health insurance company *",
          insuranceCompanyPlaceholder: "Select an insurance company",
          insuranceCompanyTooltip: "Select the health insurance company in the Slovak Republic with which you are registered. We will ensure the obligation to announce the establishment of the business for you. A foreign natural person (outside the EU and the OECD) is also obliged to be registered with a health insurance company in the Slovak Republic.",
          place: "Place of business in Slovakia",
          placeSame: "Same as the permanent residence",
          placeFirmaren: "Virtual Office",
          placeFirmarenBold: "(from €7/month)",
          placeOther: "At another address",
          placeOtherAddress: "ADDRESS *",
          placeOtherInfo: "It is necessary to prove the right to use the property either by a lease contract or the consent of the property owner (we can prepare this document for you and send it to you by e-mail).",
          originalSendTo: "Information: From 1 January 2020, the certificates/confirmations issued are not sent by post, but only electronically.",
          originalHome: "Permanent residence of the entrepreneur",
          originalWorkPlace: "Place of business",
          originalOther: "Other",
          originalOtherPlaceholder: "For example a Trade Licensing Office or an office of Firmáreň",
          originalOtherTooltip: "Enter here if you want to take over the document e.g. at the Trade Licensing Office or at an office of Firmáreň.",
          nameTooltip: "Business name of a sole trader may also contain a supplement in addition to the name and surname. For example: Jozef Mrkvička – Greengrocer’s.",
          icoTooltip: "Fill in if you are extending your trade, or if you have already had a trade licence with an assigned Company ID No.",
          tooltip: "Virtual Office based on your choice will be provided by business partner of firmaren.sk.",
          trvalyPobytTooltip: "If the place of business is the same as the entrepreneur's permanent residence, no other confirmation is required. This option does not apply to a foreign person (outside the Slovak Republic), because the place of business must only be within the territory of the Slovak Republic.",
          inaAdresaTooltip: "The place of business may be at any address in the Slovak Republic but, to prove this, the written consent of the property owner with their sworn signature (Firmáreň will provide the document to the client) or a lease contract for business purposes must be submitted.",
          businessAddress: {
            streetTooltip: "Enter the name of the street or public area (square). If the town is not divided into streets, enter the name of the town in this field."
          }
        },

        otherInfo: {
          title: "Data for the criminal record extract",
          subtitle: "Based on the following data, the Trade Licensing Office will itself request an extract from the criminal record of a citizen of the Slovak Republic directly from the General Prosecutor's Office of the Slovak Republic. This extract shall prove the integrity of the sole trader under the Trade Licensing Act.",
          placeOfBirth: "Place of birth *",
          nationality: "Nationality *",
          birthSurname: "Maiden name *",
          formerSurname: "Former surname",
          father: {
            name: "Father's name *",
            surname: "Father's surname *"
          },
          mother: {
            name: "Mother's name *",
            surname: "Mother's surname *",
            birthSurname: "Mother's maiden name *"
          },
          nationalityPlaceholder: "E.g. Slovak",
          addressStreet: "Address"
        },

        contactInfo: {
          title: "Customer’s profile",
          subtitle: "The customer will be able to monitor the status of the order and its details via Firmáreň in the My Company section. Please enter an e-mail address that will serve as the customer's login name and create a password for it.",
          email: "E-mail (serving as a login name) *",
          password: "Password for the profile in Firmáreň *",
          phoneNumber: "Telephone number *"
        },

        additionalInfo: {
          title: "Other (discount)",
          reference: "How have you learned about us/Who recommended us?",
          voucher: "Discount coupon",
          comment: "Note",
          commentPlaceholder: "E.g. a particular date of commencement of the trade licence."
        }

      },

      krok3: {
        label: "Order completion",

        header: "Check the correctness of the entered data",
        subheader: "If you find any incorrectness or incompleteness, correct the error or fill in the missing data and click on the COMPLETE button below",

        personalInfo: {
          title: "Personal data",

          email: "E-mail",
          name: "Name and surname",
          dateOfBirth: "Date of birth",
          idNumber: "Personal identification number",
          address: "Permanent residential address",
          city: "Town and postal code",
          country: "Country",
          phoneNumber: "Telephone number"
        },

        contractorInfo: {
          title: "Trade",

          name: "Business name",
          ico: "COMPANY ID NO. (IČO)",
          place: "Place of business",
          insuranceCompany: "Health insurance company",
          originalSendTo: "The original certificate of the trade licence shall be sent to the address stated"
        },

        otherInfo: {
          title: "Data for the criminal record extract",

          gender: "Gender",
          placeOfBirth: "Place of birth",
          nationality: "Nationality",
          formerSurname: "Former surname",
          birthSurname: "Maiden name",
          fatherName: "Father's name and surname",
          motherName: "Mother's name and surname",
          motherBirthSurname: "Mother's maiden name"
        },

        businessSubjects: {
          title: "Scopes of business",
          totalPrice: "Total price"
        },

        additionalInfo: {
          title: "Other",
          voucher: "Discount coupon",
          comment: "Note"
        }
      }
    },

    buttons: {
      ok: "Ok"
    },
    modalIdle: {
      title: "The session has expired",
      description: "The page needs to be reloaded."
    }
  }
};

/* @ngInject */
function Localization($translateProvider) {
  for (let lang in translations) {
    if (translations.hasOwnProperty(lang)) {
      $translateProvider.translations(lang, translations[lang]);
    }
  }

  // $translateProvider.useSanitizeValueStrategy("escape"); // fixme: tak aby fungovalo html cez trust
  $translateProvider.preferredLanguage("sk");
}

angular.module("firmaren")
  .config(Localization);
}());
